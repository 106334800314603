import { useState } from 'react';
import { createContainer } from 'unstated-next';

export const SelectedOfferContext = createContainer(() => {
  const [selectedOfferId, setSelectedOfferId] = useState<null | string>(null);
  const [selectedOfferVersion, setSelectedOfferVersion] = useState<
    null | number
  >(null);

  return {
    selectedOfferId,
    setSelectedOfferId,
    selectedOfferVersion,
    setSelectedOfferVersion,
  };
});
