import { Button, ButtonLoadingMode } from '@animatrix/components/Button';
import { MoneyInput } from '@animatrix/components/MoneyInput';
import { PercentageInput } from '@animatrix/components/PercentageInput';
import { SelectBanks } from '@common/components/SelectBanks';
import { useCreateOffer } from '@create-offer/hooks/useCreateOffer';
import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { Controller, FieldError, useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { PickBrokerAccount } from '../../../broker-accounts/common/components/PickBrokerAccount';
import { EasySelect } from '../../../common/components/EasySelect';
import { INDEXER_OPTIONS } from '../../../common/constants/INDEXER_OPTIONS';
import { PRODUCT_OPTIONS } from '../../../common/constants/PRODUCT_OPTIONS';
import { getValidationFunctions } from './getValidationFunctions';
import { ICreateOfferFormValues } from './interfaces/ICreateOfferFormValues';
import AddIcon from '@mui/icons-material/Add';
import { getIndexerParams } from '@common/logic/getIndexerParams';
import { CreateBrokerAccountModal } from '../../../broker-accounts/create-broker-account/components/CreateBrokerAccountModal';
import { useBrokerAccounts } from '../../../broker-accounts/list-broker-accounts/hooks/useBrokerAccounts';
import { useGetResellYieldRate } from '@create-offer/hooks/useGetResellYieldRate';
import { useLoading } from '@common/hooks/useLoading';
import { TextField } from '@animatrix/components/TextField';
import { DatePicker } from '@animatrix/components/DatePicker';
import { endOfYesterday, startOfTomorrow } from 'date-fns';
import { useOfferParameters } from '@create-offer/hooks/useOfferParameters';

export const CreateOfferForm: React.FC = () => {
  const [brokerAccountModalOpen, setBrokerAccountModalOpen] = useState(false);
  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
    setValue,
    watch,
    getValues,
    clearErrors,
  } = useFormContext<ICreateOfferFormValues>();
  const navigate = useNavigate();

  const { loading: loadingBrokerAccounts } = useBrokerAccounts();
  const { createOffer, loading: loadingCreateOffer } = useCreateOffer();

  const [rebuyYieldRate, indexer] = watch(['rebuyYieldRate', 'indexer']);
  const { resellYieldRate, loading: loadingResellYieldRate } =
    useGetResellYieldRate(rebuyYieldRate, indexer);

  const { offerParameters, loading: loadingOfferParameter } =
    useOfferParameters(indexer);

  const { yieldSuffix } = getIndexerParams(indexer);

  const { loadingMutations, loadingQueries, loading } = useLoading(
    [loadingBrokerAccounts, loadingOfferParameter],
    [loadingCreateOffer],
  );

  const validationFunctions = getValidationFunctions(getValues);

  const goBack = () => navigate(-1);

  useEffect(() => {
    if (typeof rebuyYieldRate === 'number') {
      setValue('resellYieldRate', resellYieldRate);
    }
  }, [resellYieldRate]);

  useEffect(() => {
    setValue('yieldRate', 0);
    setValue('rebuyYieldRate', 0);
    setValue('resellYieldRate', 0);
    clearErrors(['yieldRate', 'rebuyYieldRate', 'resellYieldRate']);
  }, [indexer]);

  const onSubmit = async (data: ICreateOfferFormValues) => {
    try {
      if (!data.brokerAccount?.number) {
        return;
      }

      const input = {
        accountNumber: data.brokerAccount.number,
        applicationDate: data.applicationDate,
        applicationDateUnitPrice: data.applicationDateUnitPrice,
        cetipCode: data.cetipCode,
        indexer: data.indexer,
        issuer: data.issuer.value,
        maturityDate: data.maturityDate,
        productKind: data.productKind,
        quantity: data.quantity,
        yieldRate: data.yieldRate,
        rebuyYieldRate: data.rebuyYieldRate,
        resellYieldRate: data.resellYieldRate,
      };

      await createOffer(input);

      toast.success('Oferta cadastrada com sucesso!');
      navigate('/');
    } catch (err) {
      if (err instanceof Error) {
        toast.error(err.message);
      }

      throw err;
    }
  };

  if (!offerParameters) return null;

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={8}>
            <Controller
              name="brokerAccount"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field }) => (
                <PickBrokerAccount
                  value={field.value}
                  onChange={field.onChange}
                  error={!!errors.brokerAccount}
                  helperText={
                    (errors.brokerAccount as FieldError)?.message ?? ''
                  }
                  loading={loadingQueries}
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <Button
              fullWidth
              sx={{ height: '100%' }}
              startIcon={<AddIcon />}
              variant="outlined"
              size="large"
              disabled={loading}
              loading={loadingQueries}
              loadingMode={ButtonLoadingMode.Skeleton}
              onClick={() => setBrokerAccountModalOpen(true)}
            >
              Novo cliente
            </Button>
          </Grid>
          <Grid item md={6} xs={12}>
            <Controller
              rules={{
                required: true,
                validate: validationFunctions.quantity,
              }}
              name="quantity"
              control={control}
              render={({ field }) => (
                <TextField
                  label="Quantidade"
                  type="number"
                  error={!!errors.quantity}
                  helperText={errors.quantity?.message ?? ''}
                  loading={loadingQueries}
                  value={Number(field.value)}
                  onChange={(e) => field.onChange(Number(e.target.value))}
                />
              )}
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <Controller
              rules={{
                required: true,
              }}
              name="cetipCode"
              control={control}
              render={({ field }) => (
                <TextField
                  label="CETIP"
                  error={!!errors.cetipCode}
                  helperText={errors.cetipCode?.message ?? ''}
                  loading={loadingQueries}
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name="issuer"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field }) => (
                <SelectBanks
                  value={field.value}
                  onChange={(event, newValue) => {
                    field.onChange(newValue);
                  }}
                  label={'Emissor'}
                  loading={loadingQueries}
                />
              )}
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <Controller
              name="indexer"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field }) => (
                <EasySelect
                  label="Indexador"
                  value={field.value}
                  options={INDEXER_OPTIONS}
                  onChange={field.onChange}
                  loading={loadingQueries}
                />
              )}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Controller
              name="productKind"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field }) => (
                <EasySelect
                  label="Produto"
                  value={field.value}
                  onChange={field.onChange}
                  options={PRODUCT_OPTIONS}
                  loading={loadingQueries}
                />
              )}
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <Controller
              rules={{
                required: true,
                validate: validationFunctions.applicationDate,
              }}
              name="applicationDate"
              control={control}
              render={({ field }) => (
                <DatePicker
                  value={field.value}
                  onChange={field.onChange}
                  label="Data de Aplicação"
                  error={!!errors.applicationDate}
                  maxDate={endOfYesterday()}
                  loading={loadingQueries}
                />
              )}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Controller
              rules={{
                required: true,
                validate: validationFunctions.maturityDate,
              }}
              name="maturityDate"
              control={control}
              render={({ field }) => (
                <DatePicker
                  value={field.value}
                  onChange={field.onChange}
                  label="Data de Vencimento"
                  error={!!errors.maturityDate}
                  minDate={startOfTomorrow()}
                  loading={loadingQueries}
                />
              )}
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <Controller
              name="yieldRate"
              control={control}
              rules={{
                required: true,
                validate: (value) =>
                  validationFunctions.yieldRate(
                    value,
                    offerParameters.minimumYieldRate,
                  ),
              }}
              render={({ field }) => (
                <PercentageInput
                  suffix={yieldSuffix}
                  value={field.value}
                  precision={2}
                  onChange={field.onChange}
                  label="Taxa"
                  error={!!errors.yieldRate}
                  helperText={errors.yieldRate?.message ?? ''}
                  loading={loadingQueries}
                />
              )}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Controller
              rules={{
                required: true,
                validate: validationFunctions.applicationDateUnitPrice,
              }}
              name="applicationDateUnitPrice"
              control={control}
              render={({ field }) => (
                <MoneyInput
                  value={field.value}
                  onChange={field.onChange}
                  label="PU Inicial"
                  error={!!errors.applicationDateUnitPrice}
                  helperText={errors.applicationDateUnitPrice?.message ?? ''}
                  loading={loadingQueries}
                />
              )}
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <Controller
              name="rebuyYieldRate"
              control={control}
              rules={{
                required: true,
                validate: (value) =>
                  validationFunctions.rebuyYieldRate(
                    value,
                    offerParameters.yieldRateSpread,
                    offerParameters.minimumResellYieldRate,
                  ),
              }}
              render={({ field }) => (
                <PercentageInput
                  suffix={yieldSuffix}
                  value={field.value}
                  precision={2}
                  onChange={field.onChange}
                  label="Taxa de recompra"
                  error={!!errors.rebuyYieldRate}
                  helperText={errors.rebuyYieldRate?.message ?? ''}
                  loading={loadingQueries}
                />
              )}
            />
          </Grid>

          <Grid item md={6} xs={12} mb={3}>
            <Controller
              name="resellYieldRate"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <PercentageInput
                  suffix={yieldSuffix}
                  value={field.value}
                  precision={2}
                  onChange={field.onChange}
                  disabled
                  label="Taxa de revenda"
                  error={!!errors.resellYieldRate}
                  helperText={errors.resellYieldRate?.message ?? ''}
                  loading={loadingQueries || loadingResellYieldRate}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Button
              variant="contained"
              type="submit"
              size="large"
              disabled={loading || !isValid}
              loading={loading}
              loadingMode={
                loadingMutations
                  ? ButtonLoadingMode.Circle
                  : ButtonLoadingMode.Skeleton
              }
              sx={{ mr: 4 }}
            >
              Cadastrar
            </Button>
            <Button
              size="large"
              sx={{ color: 'grey.800' }}
              disabled={loading}
              loading={loadingQueries}
              loadingMode={ButtonLoadingMode.Skeleton}
              onClick={goBack}
            >
              Cancelar
            </Button>
          </Grid>
        </Grid>
      </form>
      <CreateBrokerAccountModal
        open={brokerAccountModalOpen}
        onClose={() => setBrokerAccountModalOpen(false)}
        onBrokerAccountCreated={(brokerAccount) => {
          setValue('brokerAccount', brokerAccount, {
            shouldValidate: true,
          });
        }}
      />
    </>
  );
};
