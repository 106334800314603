import { gql, useMutation } from '@apollo/client';
import {
  RemoveOfferMutation,
  RemoveOfferMutationVariables,
} from '../../../__generated__/types';
import { GET_MY_OFFERS_QUERY } from './useMyOffers';

export const REMOVE_OFFER_MUTATION = gql`
  mutation RemoveOffer($offerId: String!, $offerVersion: Int!) {
    removeOffer(offerId: $offerId, offerVersion: $offerVersion) {
      id
    }
  }
`;

export const useRemoveOffer = () => {
  const [_removeOffer, { loading, error }] = useMutation<
    RemoveOfferMutation,
    RemoveOfferMutationVariables
  >(REMOVE_OFFER_MUTATION);

  const removeOffer = (offerId: string, offerVersion: number) => {
    return _removeOffer({
      variables: {
        offerId,
        offerVersion,
      },
      refetchQueries: [{ query: GET_MY_OFFERS_QUERY }],
      awaitRefetchQueries: true,
    });
  };

  return {
    removeOffer,
    loading,
    error,
  };
};
