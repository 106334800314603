import {
  Box,
  Button as MUIButton,
  ButtonProps,
  CircularProgress,
  Skeleton,
} from '@mui/material';

export enum ButtonLoadingMode {
  Skeleton,
  Circle,
}

interface IButtonProps extends ButtonProps {
  loading?: boolean;
  loadingMode?: ButtonLoadingMode;
}

export const Button: React.FC<IButtonProps> = ({
  children,
  loading,
  loadingMode = ButtonLoadingMode.Circle,
  sx,
  startIcon,
  fullWidth,
  ...props
}) => {
  if (loading && loadingMode === ButtonLoadingMode.Skeleton) {
    return (
      <Box
        display="inline-block"
        sx={sx}
        width={fullWidth ? '100%' : undefined}
      >
        <Skeleton
          variant="rectangular"
          width="100%"
          height="100%"
          sx={{ borderRadius: 1 }}
        >
          <MUIButton fullWidth={fullWidth} {...props}>
            {children}
          </MUIButton>
        </Skeleton>
      </Box>
    );
  }

  if (loading && loadingMode === ButtonLoadingMode.Circle) {
    return (
      <MUIButton
        sx={{ ...sx, position: 'relative' }}
        fullWidth={fullWidth}
        {...props}
      >
        {/* Workaround to keep button size while loading */}
        <Box sx={{ opacity: 0 }}>{children}</Box>
        <CircularProgress
          size={20}
          sx={{ color: 'grey.500', opacity: 0.8, position: 'absolute' }}
          thickness={8}
        />
      </MUIButton>
    );
  }

  return (
    <MUIButton
      sx={sx}
      fullWidth={fullWidth}
      startIcon={!loading && startIcon}
      {...props}
    >
      {children}
    </MUIButton>
  );
};
