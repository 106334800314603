import { PlaceOrderFormContext } from '../../../../place-order/components/PlaceOrderForm/PlaceOrderContext';
import { SelectedOfferContext } from '../../context/SelectedOfferContext';
import { OfferList } from '../OfferList/index';

export const OffersDashboard: React.FC = () => {
  return (
    <SelectedOfferContext.Provider>
      <PlaceOrderFormContext.Provider>
        <OfferList />
      </PlaceOrderFormContext.Provider>
    </SelectedOfferContext.Provider>
  );
};
