import { formatMonths } from '@common/logic/formatMonths';
import { range } from 'ramda';
import { Indexers, ProductKind } from '../../../../../__generated__/types';
import { ICreatePurchaseIntentFormValues } from './interfaces/ICreatePurchaseIntentFormValues';

export const DEFAULT_CREATE_PURCHASE_INTENT_FORM_VALUES: Partial<ICreatePurchaseIntentFormValues> =
  {
    indexer: Indexers.PREFIXADO,
    interestingProducts: [
      ProductKind.CDB,
      ProductKind.LC,
      ProductKind.LCI,
      ProductKind.LCA,
    ],
    maturityDaysMin: 30,
    maturityDaysMax: 30 * 24,
  };

export const MATURITY_DAYS_OPTIONS = range(1, 97).map((i) => {
  return {
    value: 30 * i,
    label: formatMonths(i),
  };
});
