import { Page } from '@animatrix/components/Page';
import { styled } from '@mui/material/styles';

export const Root = styled(Page)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10),
}));
