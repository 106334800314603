import { DisplayMessage } from '@animatrix/components/DisplayMessage';
import { List, ListItem, ListItemText, Skeleton } from '@mui/material';
import { BookItem } from '../BookItem';
import { IBookItem, ItemButtonText } from '../BookItem/interface';

interface BookListProps {
  items: IBookItem[];
  emptyStateMessage: string;
  subtitle?: string;
  onItemClick?: (item: IBookItem) => void;
  loading?: boolean;
  itemButtonText?: ItemButtonText;
  disabledButton?: boolean;
}

export const BookList: React.FC<BookListProps> = ({
  loading,
  items,
  emptyStateMessage,
  onItemClick,
  itemButtonText,
  disabledButton,
}) => {
  const isEmpty = !items.length;

  if (loading) {
    return (
      <>
        {Array.from({ length: 4 }, (_, i) => (
          <Skeleton key={i} variant="rectangular" width="100%" sx={{ mb: 2 }}>
            <ListItem>
              <ListItemText primary="loading" secondary="loading" />
            </ListItem>
          </Skeleton>
        ))}
      </>
    );
  }

  if (isEmpty) {
    return (
      <DisplayMessage severity="warning" variant="outlined">
        {emptyStateMessage}
      </DisplayMessage>
    );
  }

  return (
    <List disablePadding>
      {items.map((item) => (
        <BookItem
          key={item.id}
          item={item}
          onClick={onItemClick}
          buttonText={itemButtonText}
          disabledButton={disabledButton}
        />
      ))}
    </List>
  );
};
