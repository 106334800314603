import { Tab } from '@mui/material';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { PAGE_TITLES } from '../../../layout/components/Dashboard/SidebarConfig';
import { PATH_DASHBOARD } from '../../../routes/paths';
import { PurchasedOrders } from '../PurchasedOrders';
import { SoldOrders } from '../SoldOrders';
import { Typography } from '@animatrix/components/Typography';
import { useMyOrders } from '../../hooks/useMyOrders';
import { OrderSubject } from '../../../../__generated__/types';
import { Tabs } from '@animatrix/components/Tabs';

export const MyOrdersDashboard: React.FC = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { loading } = useMyOrders(OrderSubject.BUYER);

  const handleTabChange = (_: any, value: string) =>
    navigate({
      pathname: value,
    });
  return (
    <>
      <Typography variant="h4" mb={5} loading={loading}>
        {PAGE_TITLES.myOrdersPurchased}
      </Typography>
      <Tabs
        value={pathname}
        onChange={handleTabChange}
        loading={loading}
        sx={{ marginBottom: 2 }}
      >
        <Tab label="Compras" value={PATH_DASHBOARD.app.myOrdersPurchased} />
        <Tab label="Vendas" value={PATH_DASHBOARD.app.myOrdersSold} />
      </Tabs>
      <Routes>
        <Route path={'/purchased'} element={<PurchasedOrders />} />
        <Route path={'/sold'} element={<SoldOrders />} />
      </Routes>
    </>
  );
};
