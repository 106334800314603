import { gql, useQuery } from '@apollo/client';
import {
  GetUnitPriceInput,
  GetUnitPriceQuery,
  GetUnitPriceQueryVariables,
} from '../../../__generated__/types';

const GET_UNIT_PRICE_QUERY = gql`
  query GetUnitPrice($input: GetUnitPriceInput!) {
    getUnitPrice(input: $input)
  }
`;

export const useGetUnitPrice = (input: GetUnitPriceInput) => {
  const { data, loading, error } = useQuery<
    GetUnitPriceQuery,
    GetUnitPriceQueryVariables
  >(GET_UNIT_PRICE_QUERY, {
    variables: { input },
    skip: isNaN(input.newYieldRate),
  });

  return {
    unitPrice: data?.getUnitPrice ?? 0,
    loading,
    error,
  };
};
