import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import {
  OrderSubject as OrderSubject,
  PlaceOrderInput as PlaceOrderInput,
  PlaceOrderMutation,
  PlaceOrderMutationVariables,
} from '../../../__generated__/types';
import { MY_ORDERS_QUERY as MY_ORDERS_QUERY } from '../../my-orders/hooks/useMyOrders';

const PLACE_ORDER_MUTATION = gql`
  mutation PlaceOrder($input: PlaceOrderInput!) {
    placeOrder(input: $input) {
      id
      boughtByUserId
      buyerAccountNumber
      quantity
      offer {
        __typename
        id
        version
        availableQuantity
        version
      }
    }
  }
`;

export const usePlaceOrder = () => {
  const [_placeOrder, { loading, error }] = useMutation<
    PlaceOrderMutation,
    PlaceOrderMutationVariables
  >(PLACE_ORDER_MUTATION, {
    update: (cache, mutationResult) => {
      const updatedOffer = mutationResult.data?.placeOrder.offer;
      const publicOfferIdentifier = `PublicOffer:${updatedOffer?.id}`;

      if (updatedOffer?.availableQuantity === 0) {
        cache.evict({
          id: publicOfferIdentifier,
          broadcast: true,
        });

        cache.gc();
        return;
      }

      cache.modify({
        id: publicOfferIdentifier,
        fields: {
          availableQuantity() {
            return updatedOffer?.availableQuantity;
          },
          version() {
            return updatedOffer?.version;
          },
        },
        broadcast: true,
      });
    },
    refetchQueries: [
      {
        query: MY_ORDERS_QUERY,
        variables: {
          subject: OrderSubject.BUYER,
        },
      },
      {
        query: MY_ORDERS_QUERY,
        variables: {
          subject: OrderSubject.SELLER,
        },
      },
    ],
    awaitRefetchQueries: true,
  });

  const placeOrder = (input: PlaceOrderInput) =>
    _placeOrder({
      variables: {
        input,
      },
    });

  return {
    placeOrder,
    loading,
    error,
  };
};
