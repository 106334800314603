import { PublicOffer } from '@common/types/PublicOffer';
import { SuitabilityIssues } from '../../../../__generated__/types';

export const getOfferSuitability = (offer: PublicOffer): string => {
  const { suitabilityIssues } = offer;

  if (!suitabilityIssues) return '';

  if (suitabilityIssues.includes(SuitabilityIssues.YieldRate))
    return 'Taxa incompatível';

  if (suitabilityIssues.includes(SuitabilityIssues.ProductKind))
    return 'Produto incompatível';

  if (suitabilityIssues.includes(SuitabilityIssues.Issuer))
    return 'Emissor incompatível';

  if (suitabilityIssues.includes(SuitabilityIssues.MaturityDate))
    return 'Vencimento incompatível';

  return '';
};
