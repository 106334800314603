import { TextField } from '@animatrix/components/TextField';
import { Autocomplete } from '@mui/material';
import { useMemo } from 'react';
import { useAllBanks } from '../../../purchase-intent/create-purchase-intent/hooks/useAllBanks';
import { SelectOption } from '../EasySelect';

export interface ISelectBanksProps {
  value: SelectOption<string>[] | SelectOption<string> | null;
  onChange: (
    event: React.SyntheticEvent<Element, Event>,
    newValue: SelectOption<string>[] | SelectOption<string> | null,
  ) => void;
  label: string;
  multiple?: boolean;
  loading?: boolean;
}

export const SelectBanks: React.FC<ISelectBanksProps> = ({
  value,
  onChange,
  label,
  multiple = false,
  loading,
}) => {
  const { allBanks, loading: loadingBanks } = useAllBanks();

  const allBanksFiltered = useMemo(() => {
    if (!value) {
      return allBanks;
    }

    const arrayValue = Array.isArray(value) ? value : [value];
    const selectedKeys = arrayValue.map((bank) => bank.value);

    return allBanks.filter((bank) => !selectedKeys.includes(bank.value));
  }, [value, allBanks]);

  return (
    <Autocomplete
      value={value}
      onChange={onChange}
      fullWidth
      options={allBanksFiltered}
      multiple={multiple}
      disablePortal
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          loading={loading || loadingBanks}
        />
      )}
    />
  );
};
