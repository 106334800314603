import { ConfirmationModal } from '@animatrix/components/ConfirmationModal';
import { Typography } from '@animatrix/components/Typography';
import { toast } from 'react-toastify';
import { SelectedPurchaseIntentContext } from '../../context/SelectedPurchaseIntentContext';
import { useDeletePurchaseIntent } from '../../hooks/useDeletePurchaseIntent';

export const RemovePurchaseIntentModal: React.FC = () => {
  const { selectedPurchaseIntentId, setSelectedPurchaseIntentId } =
    SelectedPurchaseIntentContext.useContainer();
  const { deletePurchaseIntent, loading } = useDeletePurchaseIntent();

  const handleClose = () => setSelectedPurchaseIntentId(null);

  const handleRemove = async () => {
    if (!selectedPurchaseIntentId) {
      return;
    }
    try {
      await deletePurchaseIntent(selectedPurchaseIntentId);
      toast.success('Intenção de compra excluída com sucesso!');
    } catch (err) {
      if (err instanceof Error) {
        toast.error(err.message);
      }

      throw err;
    } finally {
      setSelectedPurchaseIntentId(null);
    }
  };

  return (
    <ConfirmationModal
      open={!!selectedPurchaseIntentId}
      onClose={handleClose}
      onCancel={handleClose}
      onConfirm={handleRemove}
      loading={loading}
    >
      <Typography variant="body1">
        Você tem certeza que deseja excluir essa intenção de compra?
      </Typography>
    </ConfirmationModal>
  );
};
