export enum AppEvents {
  ViewSignIn = 'ViewSignIn',
  SignInComplete = 'SignInComplete',
  ViewSignUpMethods = 'ViewSignUpMethods',
  ClickSignUpMethod = 'ClickSignUpMethod',
  ViewEmailSignUpForm = 'ViewEmailSignUpForm',
  SignUpComplete = 'SignUpComplete',
  ClickSignUpComplete = 'ClickSignUpComplete',
  ViewPlatformHome = 'ViewPlatformHome',
  ClickOnboardingStart = 'ClickOnboardingStart',
  ViewOnboardingIntro = 'ViewOnboardingIntro',
  ClickOnboardingIntroNext = 'ClickOnboardingIntroNext',
  ViewOnboardingBroker = 'ViewOnboardingBroker',
  ClickOnboardingBrokerNext = 'ClickOnboardingBrokerNext',
  ViewOnboardingPurchaseOrder = 'ViewOnboardingPurchaseOrder',
  ClickOnboardingOrderPurchaseNext = 'ClickOnboardingOrderPurchaseNext',
  ViewOnboardingInsertCoupon = 'ViewOnboardingInsertCoupon',
  ClickOnboardingInsertCouponFinish = 'ClickOnboardingInsertCouponFinish',
  ViewOnboardingAuthenticateReminder = 'ViewOnboardingAuthenticateReminder',
  ClickOnboardingAuthenticateReminder = 'ClickOnboardingAuthenticateReminderFinish',
  ClickClaimBrokerAccountNumber = 'ClickClaimBrokerAccountNumber',
  BrokerAccountNumberValidated = 'BrokerAccountNumberValidated',
  SuccessClaimAccountNumber = 'SuccessClaimAccountNumber',
  ClickBrokerAccountAuthentication = 'ClickBrokerAccountAuthentication',
  ViewBrokerAccountAuthentication = 'ViewBrokerAccountAuthentication',
  BrokerAccountPasswordValidated = 'BrokerAccountPasswordValidated',
  BrokerAccountPasswordFail = 'BrokerAccountPasswordFail',
  BrokerAccountTokenValidated = 'BrokerAccountTokenValidated',
  BrokerAccountTokenNotValidated = 'BrokerAccountTokenNotValidated',
  BrokerAccountAuthenticated = 'BrokerAccountAuthenticated',
  BrokerAccountNotAuthenticated = 'BrokerAccountNotAuthenticated',
  ClickCreateFirstBrokerAccount = 'ClickCreateFirstBrokerAccount',
  CreateFirstBrokerAccountSuccess = 'CreateFirstBrokerAccountSuccess',
  ViewNoBrokerAccountInstructions = 'ViewNoBrokerAccountInstructions',
  OpenBrokerAccountFinish = 'OpenBrokerAccountFinish',
  ViewNotImplementedBroker = 'ViewNotImplementedBroker',
  BrokerNotReadyFinish = 'BrokerNotReadyFinish',
  BrokerAccountPinSetupSuccess = 'BrokerAccountPinSetupSuccess',
  ViewBrokerAccountPINModal = 'ViewBrokerAccountPINModal',
  PlatformPageView = 'PlatformPageView',
  ViewBrokerOfficeTransfer = 'ViewBrokerOfficeTransfer',
  ConfirmBrokerOfficeTransfer = 'ConfirmBrokerOfficeTransfer',
  ClickOnBoardingVideo = 'ClickOnBoardingVideo',
  ClickOnAuthenticationVideo = 'ClickOnAuthenticationVideo',
  ClickOnCopyCoupon = 'ClickOnCopyCoupon',
  ViewOnboardingHowParametersWorks = 'ViewOnboardingHowParametersWorks',
  ClickOnboardingHowParametersWorksNext = 'ClickOnboardingHowParametersWorksNext',
  ViewOnboardingHowAuthorizationWorks = 'ViewOnboardingHowAuthorizationWorks',
  ClickOnboardingHowAuthorizationWorksNext = 'ClickOnboardingHowAuthorizationWorksNext',

  ViewChangeBrokerAccountModal = 'ViewChangeBrokerAccountModal',
  ClickChangeBrokerAccount = 'ClickChangeBrokerAccount',

  ViewBrokerAccountAuthenticationConnectYourAccount = 'ViewBrokerAccountAuthenticationConnectYourAccount',
  ViewBrokerAccountAuthenticationDisclaimer = 'ViewBrokerAccountAuthenticationDisclaimer',
  ViewBrokerAccountAuthenticationHowMarketWorks = 'ViewBrokerAccountAuthenticationHowMarketWorks',
}
