import { IBookItem } from '@common/components/BookContainer/components/BookItem/interface';
import { formatDate } from '@common/date/formatters';
import { formatIndexer } from '@common/logic/formatIndexer';
import { formatYieldDescription } from '@common/logic/formatYieldDescription';
import { PurchaseIntent } from '@common/types/PurchaseIntent';
import { formatReais } from '@invest-ai/common';
import { ptBR } from 'date-fns/locale';
import { Indexers } from '../../../../__generated__/types';

export const purchaseIntentToItem = (
  purchaseIntent: PurchaseIntent,
): IBookItem => {
  const products = purchaseIntent.interestingProducts?.join('/') || '';

  const yieldDescription = formatYieldDescription(
    purchaseIntent.indexer as Indexers,
    purchaseIntent.yieldRateMin,
  );

  const indexer = formatIndexer(purchaseIntent.indexer as Indexers);

  const maturityDateMin = formatDate(
    purchaseIntent.maturityDateMin,
    'MMM/yyyy',
    ptBR,
  ).toUpperCase();

  const maturityDateMax = formatDate(
    purchaseIntent.maturityDateMax,
    'MMM/yyyy',
    ptBR,
  ).toUpperCase();

  return {
    id: purchaseIntent.id.toString(),
    title: `${products} ${indexer} ${yieldDescription}`,
    subtitle: `${maturityDateMin} - ${maturityDateMax}`,
    description: formatReais(purchaseIntent.availableLimit),
  };
};
