import { DatePicker as MUIDatePicker, DatePickerProps } from '@mui/lab';
import React from 'react';
import { TextField } from '../TextField';

interface IDatePickerProps {
  error?: boolean;
  loading?: boolean;
}

export const DatePicker: React.FC<
  IDatePickerProps & Omit<DatePickerProps<Date>, 'renderInput'>
> = ({ onChange, value, label, error, loading, ...props }) => {
  return (
    <MUIDatePicker
      renderInput={(props) => (
        <TextField {...props} error={error} loading={loading} />
      )}
      inputFormat="dd/MM/yyyy"
      label={label}
      value={value}
      onChange={onChange}
      {...props}
    />
  );
};
