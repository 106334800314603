import { useAvailableOffers } from '@book/list-offers/hooks/useAvailableOffers';
import { offerToItem } from '../../logic/offerToItem';
import { BookContainer } from '../../../../common/components/BookContainer';
import { useFormContext } from 'react-hook-form';
import { useMemo, useState } from 'react';
import { addDays } from 'date-fns';
import { BuyOfferModal } from '../BuyOfferModal';
import { PublicOffer } from '@common/types/PublicOffer';
import { ICreatePurchaseIntentFormValues } from '../CreatePurchaseIntentForm/interfaces/ICreatePurchaseIntentFormValues';

export const OffersList: React.FC = () => {
  const { watch } = useFormContext<ICreatePurchaseIntentFormValues>();
  const [
    brokerAccount,
    indexer,
    interestingBanks,
    interestingProducts,
    yieldRateMin,
    maturityDaysMin,
    maturityDaysMax,
  ] = watch([
    'brokerAccount',
    'indexer',
    'interestingBanks',
    'interestingProducts',
    'yieldRateMin',
    'maturityDaysMin',
    'maturityDaysMax',
  ]);

  const [selectedOffer, setSelectedOffer] = useState<PublicOffer | null>(null);
  const [isFiltered, setIsFiltered] = useState(false);

  const filters = useMemo(() => {
    const defaultFilters = {
      indexer,
      ...(brokerAccount?.number && {
        brokerAccountBlacklist: [brokerAccount.number],
      }),
    };

    const today = new Date();
    const maturityDateMin = addDays(today, maturityDaysMin);
    const maturityDateMax = addDays(today, maturityDaysMax);

    return isFiltered
      ? {
          ...defaultFilters,
          productKind: interestingProducts,
          issuers: interestingBanks?.map(({ value }) => value),
          maturityDateMin,
          maturityDateMax,
          ...(brokerAccount?.number && {
            brokerAccountBlacklist: [brokerAccount.number],
          }),
        }
      : defaultFilters;
  }, [
    isFiltered,
    brokerAccount,
    indexer,
    interestingBanks,
    interestingProducts,
    maturityDaysMin,
    maturityDaysMax,
  ]);

  const boundaries = useMemo(() => {
    const today = new Date();
    const minMaturityDate = addDays(today, maturityDaysMin);
    const maxMaturityDate = addDays(today, maturityDaysMax);

    return {
      productKind: interestingProducts,
      issuers: interestingBanks?.map(({ value }) => value),
      minYieldRate: yieldRateMin,
      minMaturityDate,
      maxMaturityDate,
    };
  }, [
    interestingBanks,
    interestingProducts,
    yieldRateMin,
    maturityDaysMin,
    maturityDaysMax,
  ]);

  const { offers, loading } = useAvailableOffers(filters, boundaries);

  const offersList = offers.map(offerToItem);
  const canBuyOffers = !!brokerAccount?.number;

  const selectOffer = (offerId: string) => {
    const offer = offers.find((o) => o.id === offerId);

    if (offer) {
      setSelectedOffer(offer);
    }
  };

  return (
    <>
      <BuyOfferModal
        offer={selectedOffer}
        buyerBrokerAccount={brokerAccount}
        onClose={() => setSelectedOffer(null)}
      />
      <BookContainer
        title="Ofertas no mercado"
        subtitle="As ofertas serão ordenadas de acordo com as informações inseridas no formulário."
        emptyStateMessage="Não existem ofertas compatíveis com a sua intenção de compra"
        itemButtonText="Comprar"
        items={offersList}
        loading={loading}
        filter={{ isFiltered, setIsFiltered }}
        onItemClick={({ id }) => selectOffer(id)}
        disabledButton={!canBuyOffers}
      />
    </>
  );
};
