import { OffersDashboard } from '@book/list-offers/components/OffersDashboard';
import { Tab } from '@mui/material';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { PAGE_TITLES } from '../../../layout/components/Dashboard/SidebarConfig';
import { MyPurchaseIntentsDashboard } from '../../../purchase-intent/list-purchase-intents/components/MyPurchaseIntentsDashboard';
import { PATH_DASHBOARD } from '../../../routes/paths';
import { Typography } from '@animatrix/components/Typography';
import { useAvailableOffers } from '@book/list-offers/hooks/useAvailableOffers';
import { Tabs } from '@animatrix/components/Tabs';

export const PurchaseDashboard: React.FC = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { loading } = useAvailableOffers();

  const handleTabChange = (_: any, value: string) =>
    navigate({
      pathname: value,
    });

  return (
    <>
      <Typography variant="h4" mb={5} loading={loading}>
        {PAGE_TITLES.purchase}
      </Typography>
      <Tabs
        value={pathname}
        onChange={handleTabChange}
        loading={loading}
        sx={{ marginBottom: 2 }}
      >
        <Tab label="Prateleira" value={PATH_DASHBOARD.app.purchase} />
        <Tab
          label="Minhas intenções de compra"
          value={PATH_DASHBOARD.app.myPurchaseIntents}
        />
      </Tabs>
      <Routes>
        <Route path="/" element={<OffersDashboard />} />
        <Route
          path="/my-purchase-intents"
          element={<MyPurchaseIntentsDashboard />}
        />
      </Routes>
    </>
  );
};
