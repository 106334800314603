import { useEffect } from 'react';
import { useApolloClient } from '@apollo/client';
import { useTokens } from '@login/hooks/useTokens';
import { useNavigate } from 'react-router';
import { PATH_DASHBOARD } from '../../paths';

export const Logout: React.FC = () => {
  const { deleteToken } = useTokens();
  const client = useApolloClient();
  const navigate = useNavigate();
  const goToLoginPage = () => navigate(PATH_DASHBOARD.app.login);

  useEffect(() => {
    deleteToken();
    client
      .resetStore()
      .then(() => {
        goToLoginPage();
      })
      .catch(() => {
        goToLoginPage();
      });
  }, []);

  return null;
};
