import { Modal } from '@animatrix/components/Modal';
import { PercentageInput } from '@animatrix/components/PercentageInput';
import { getIndexerParams } from '@common/logic/getIndexerParams';
import { getValidationFunctions } from '@create-offer/components/CreateOfferForm/getValidationFunctions';
import { useGetResellYieldRate } from '@create-offer/hooks/useGetResellYieldRate';
import { Button, Grid } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { UpdateOfferContext } from '../../context/UpdateOfferContext';
import { useUpdateOffer } from '../../hooks/useUpdateOffer';
import { OfferSummary } from '../OfferSummary';
import { IUpdateOfferFormValues } from './interfaces/IUpdateOfferFormValues';
import { Typography } from '@animatrix/components/Typography';
import { Indexers } from '../../../../__generated__/types';
import { useOfferParameters } from '@create-offer/hooks/useOfferParameters';

export const UpdateOfferModal: React.FC = () => {
  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
    watch,
    getValues,
    setValue,
  } = useForm<IUpdateOfferFormValues>({
    mode: 'onChange',
  });

  const { offerToUpdate: offer, setOfferToUpdate } =
    UpdateOfferContext.useContainer();
  const { updateOffer, loading } = useUpdateOffer();

  const indexer = offer?.indexer ?? Indexers.PREFIXADO;
  const closeModal = () => setOfferToUpdate(null);

  const { offerParameters, loading: loadingOfferParameter } =
    useOfferParameters(indexer);

  const onUpdateOffer = async (data: IUpdateOfferFormValues) => {
    if (!offer) {
      return;
    }

    try {
      await updateOffer({
        id: offer.id,
        version: offer.version,
        rebuyYieldRate: data.rebuyYieldRate,
      });
      toast.success('Oferta editada com sucesso!');
    } catch (err) {
      if (err instanceof Error) {
        toast.error(err.message);
      }

      throw err;
    } finally {
      setOfferToUpdate(null);
      setValue('rebuyYieldRate', 0);
    }
  };

  const rebuyYieldRate = watch('rebuyYieldRate');

  const validationFunctions = getValidationFunctions(getValues);

  const { resellYieldRate } = useGetResellYieldRate(rebuyYieldRate, indexer);
  const { yieldSuffix } = getIndexerParams(indexer);

  if (!offer || loadingOfferParameter || !offerParameters) return null;

  return (
    <Modal
      open={!!offer}
      title="Editar oferta"
      onClose={closeModal}
      Actions={() => (
        <>
          <Button
            variant="text"
            sx={{ color: 'grey.800' }}
            onClick={closeModal}
            disabled={loading}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={loading || !isValid}
            onClick={handleSubmit(onUpdateOffer)}
          >
            Editar oferta
          </Button>
        </>
      )}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <OfferSummary offer={offer} />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1">Taxa atual</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <PercentageInput
            suffix={yieldSuffix}
            precision={2}
            value={offer.rebuyYieldRate}
            label="Taxa de recompra"
            disabled
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <PercentageInput
            suffix={yieldSuffix}
            precision={2}
            value={offer.resellYieldRate}
            disabled
            label="Taxa de revenda"
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1">Nova taxa</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="rebuyYieldRate"
            control={control}
            rules={{
              required: true,
              validate: (value) =>
                validationFunctions.rebuyYieldRate(
                  value,
                  offerParameters.yieldRateSpread,
                  offerParameters.minimumResellYieldRate,
                ),
            }}
            render={({ field }) => (
              <PercentageInput
                suffix={yieldSuffix}
                precision={2}
                value={field.value}
                onChange={field.onChange}
                label="Taxa de recompra"
                error={!!errors.rebuyYieldRate}
                helperText={errors.rebuyYieldRate?.message ?? ''}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <PercentageInput
            suffix={yieldSuffix}
            precision={2}
            value={resellYieldRate}
            disabled
            label="Taxa de revenda"
          />
        </Grid>
      </Grid>
    </Modal>
  );
};
