import { gql, useMutation } from '@apollo/client';
import {
  DeletePurchaseIntentMutation,
  DeletePurchaseIntentMutationVariables,
} from '../../../../__generated__/types';
import { MY_PURCHASE_INTENTS_QUERY } from './useMyPurchaseIntents';

export const DELETE_PURCHASE_INTENT_MUTATION = gql`
  mutation DeletePurchaseIntent($id: Int!) {
    deletePurchaseIntent(id: $id)
  }
`;

export const useDeletePurchaseIntent = () => {
  const [_deletePurchaseIntent, { loading, error }] = useMutation<
    DeletePurchaseIntentMutation,
    DeletePurchaseIntentMutationVariables
  >(DELETE_PURCHASE_INTENT_MUTATION);

  const deletePurchaseIntent = (purchaseIntentId: number) => {
    return _deletePurchaseIntent({
      variables: {
        id: purchaseIntentId,
      },
      refetchQueries: [
        {
          query: MY_PURCHASE_INTENTS_QUERY,
        },
      ],
      awaitRefetchQueries: true,
    });
  };

  return {
    deletePurchaseIntent,
    loading,
    error,
  };
};
