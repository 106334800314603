import { Label } from '@animatrix/components/Label';
import { LabelColor } from '@animatrix/components/Label/types';
import { OrderStatus } from '../../../../__generated__/types';
import { getLabelStatusProps } from './getLabelStatusProps';

interface OrderStatusLabelProps {
  status: OrderStatus;
}

export const OrderStatusLabel: React.FC<OrderStatusLabelProps> = ({
  status,
}) => {
  const { text, color } = getLabelStatusProps(status);

  return <Label color={color as LabelColor}>{text}</Label>;
};
