import { formatReais } from '@invest-ai/common';
import { Grid, TextField, Typography } from '@mui/material';
import { PickBrokerAccount } from '../../../broker-accounts/common/components/PickBrokerAccount';
import { PlaceOrderFormContext } from './PlaceOrderContext';

const getCaption = (validQuantity: boolean): string => {
  if (!validQuantity) {
    return 'Quantidade insuficiente!';
  }
  return 'Valor total da compra';
};

export const PlaceOrderForm: React.FC<{
  unitPrice: number;
  availableQuantity: number;
}> = ({ unitPrice, availableQuantity }) => {
  const {
    quantity,
    setQuantity,
    customerBrokerAccount,
    setCustomerBrokerAccount,
  } = PlaceOrderFormContext.useContainer();

  const handleChangeQuantity = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newQuantity = parseInt(e.target.value, 10);
    if (isNaN(newQuantity)) {
      setQuantity(0);
    } else {
      setQuantity(newQuantity);
    }
  };

  const validQuantity = quantity <= availableQuantity;

  const caption = getCaption(validQuantity);
  return (
    <form
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <PickBrokerAccount
            value={customerBrokerAccount}
            onChange={setCustomerBrokerAccount}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Quantidade"
            value={`${quantity}`}
            onChange={handleChangeQuantity}
            type="number"
          />
        </Grid>
        <Grid item xs={12}>
          <Typography
            gutterBottom
            color={validQuantity ? 'inherit' : 'error'}
            variant="h5"
            textAlign="center"
          >
            {formatReais(unitPrice * quantity)}
          </Typography>

          <Typography
            variant="caption"
            textAlign="center"
            component="div"
            color="textSecondary"
          >
            {caption}
          </Typography>
        </Grid>
      </Grid>
    </form>
  );
};
