import { ReactNode } from 'react';
import { Box } from '@mui/material';
import { Typography } from '@animatrix/components/Typography';

export interface SummaryProps {
  primaryTitle: string;
  secondaryTitle: string;
  children: ReactNode;
}

export const Summary: React.FC<SummaryProps> = ({
  primaryTitle,
  secondaryTitle,
  children,
}) => {
  return (
    <Box sx={{ backgroundColor: 'grey.200' }}>
      <Box
        display="flex"
        justifyContent="space-between"
        sx={{
          borderBottom: ' 1px solid',
          borderColor: 'divider',
          padding: 1,
        }}
      >
        <Typography variant="subtitle2">{primaryTitle}</Typography>
        <Typography variant="subtitle2">{secondaryTitle}</Typography>
      </Box>
      <Box display="flex" flexWrap="wrap" sx={{ padding: 1 }}>
        {children}
      </Box>
    </Box>
  );
};
