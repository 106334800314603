import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import {
  CreateOfferInput,
  CreateOfferMutation,
  CreateOfferMutationVariables,
} from '../../../__generated__/types';
import { GET_MY_OFFERS_QUERY } from '../../my-offers/hooks/useMyOffers';

const CREATE_OFFER_MUTATION = gql`
  mutation CreateOffer($input: CreateOfferInput!) {
    createOffer(input: $input) {
      offer {
        __typename
        id
      }
    }
  }
`;

export const useCreateOffer = () => {
  const [_createOffer, { data, loading, error }] = useMutation<
    CreateOfferMutation,
    CreateOfferMutationVariables
  >(CREATE_OFFER_MUTATION);

  const createOffer = (input: CreateOfferInput) =>
    _createOffer({
      variables: { input },
      awaitRefetchQueries: true,
      refetchQueries: [{ query: GET_MY_OFFERS_QUERY }],
    });

  return {
    createOffer,
    loading,
    error,
    data,
  };
};
