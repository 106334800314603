import { styled } from '@mui/material/styles';

const PREFIX = 'SignIn';

export const classNames = {
  email: `${PREFIX}-email`,
  password: `${PREFIX}-password`,
  forgotPassword: `${PREFIX}-forgot-password`,
  error: `${PREFIX}-error`,
};

export const TextDivider = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: theme.spacing(3.5),
  marginBottom: theme.spacing(2),
}));

export const GoogleSignInContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
}));

export const Form = styled('form')(({ theme }) => ({
  [`& .${classNames.email}`]: {
    marginBottom: theme.spacing(2),
  },
  [`& .${classNames.password}`]: {
    marginBottom: theme.spacing(0.5),
  },
  [`& .${classNames.forgotPassword}`]: {
    textAlign: 'right',
    marginBottom: theme.spacing(4),
  },
  [`& .${classNames.error}`]: {
    marginBottom: theme.spacing(1),
  },
}));
