import { PublicOffer } from '@common/types/PublicOffer';

type OfferFieldsToRow =
  | 'id'
  | 'version'
  | 'indexer'
  | 'productKind'
  | 'maturityDate'
  | 'unitPrice';

type OfferRow = Pick<PublicOffer, OfferFieldsToRow> & {
  issuer: string;
  yield: number;
  amountAvailable: number;
  createdAt: Date;
  quantity: {
    total: number;
    available: number;
  };
  actions: string;
};

export const publicOfferToRow = (offer: PublicOffer): OfferRow => {
  return {
    id: offer.id,
    version: offer.version,
    indexer: offer.indexer,
    productKind: offer.productKind,
    issuer: offer.issuer.name,
    maturityDate: offer.maturityDate,
    yield: offer.resellYieldRate,
    unitPrice: offer.unitPrice,
    amountAvailable: offer.availableQuantity * offer.unitPrice,
    createdAt: offer.createdAt,
    quantity: {
      total: offer.quantity,
      available: offer.availableQuantity,
    },
    actions: '',
  };
};
