// ----------------------------------------------------------------------

declare module '@mui/system' {
  interface Shape {
    borderRadiusXs: number | string;
    borderRadiusSm: number | string;
    borderRadiusMd: number | string;
  }
}

const shape = {
  borderRadiusXs: 4,
  borderRadius: 8,
  borderRadiusSm: 12,
  borderRadiusMd: 16,
};

export default shape;
