import { Page } from '@animatrix/components/Page';
import { Container } from '@mui/material';
import { MyOrdersDashboard } from '../../components/MyOrdersDashboard';

export const MyOrdersPage: React.FC = () => {
  return (
    <Page title="InvestAI | Minhas Ordens">
      <Container maxWidth={'xl'}>
        <MyOrdersDashboard />
      </Container>
    </Page>
  );
};
