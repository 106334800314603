import { DataGrid } from '@animatrix/components/DataGrid';
import { DisplayError } from '@animatrix/components/DisplayError';
import { PageHeader } from '@common/components/PageHeader';
import { formatDate } from '@common/date/formatters';
import { formatIndexer } from '@common/logic/formatIndexer';
import { formatInterestingProducts } from '@common/logic/formatInterestingProducts';
import { parseYieldColumn } from '@common/logic/parseYieldColumn';
import { renderYieldDescription } from '@common/logic/renderYieldDescription';
import { PurchaseIntent } from '@common/types/PurchaseIntent';
import { useAvailablePurchaseIntents } from '@create-offer/hooks/useAvailablePurchaseIntents';
import {
  GridColDef,
  GridRenderCellParams,
  GridValueFormatterParams,
} from '@mui/x-data-grid-pro';
import { useMemo } from 'react';
import { IssuersList } from '../../list-purchase-intents/components/IssuersList/IssuersList';
import { PurchasedAndMaximum } from '../../list-purchase-intents/components/PurchasedAndMaximum';

const columns: GridColDef[] = [
  {
    field: 'createdAt',
    headerName: 'Data de criação',
    hide: true,
    valueFormatter: (params: GridValueFormatterParams<Date>) =>
      formatDate(params.value),
  },
  {
    field: 'interestingProducts',
    headerName: 'Produto',
    valueFormatter: (params: GridValueFormatterParams) =>
      formatInterestingProducts(params.value),
    minWidth: 200,
  },
  {
    field: 'issuers',
    headerName: 'Emissores',
    minWidth: 150,
    flex: 1,
    renderCell: (params: GridRenderCellParams) => (
      <IssuersList issuers={params.value} />
    ),
  },
  {
    field: 'maturityDateMin',
    headerName: 'Venc. mínimo',
    minWidth: 120,
    valueFormatter: (params: GridValueFormatterParams<Date>) =>
      formatDate(params.value),
  },
  {
    field: 'maturityDateMax',
    headerName: 'Venc. máximo',
    minWidth: 120,
    valueFormatter: (params: GridValueFormatterParams<Date>) =>
      formatDate(params.value),
  },
  {
    field: 'indexer',
    headerName: 'Indexador',
    valueFormatter: (params: GridValueFormatterParams) =>
      formatIndexer(params.value),
    minWidth: 120,
    hide: true,
  },
  {
    field: 'yieldRateMin',
    headerName: 'Taxa mínima',
    minWidth: 120,
    type: 'number',
    renderCell: renderYieldDescription,
    valueParser: parseYieldColumn,
  },
  {
    field: 'amount',
    headerName: 'Valor',
    renderCell: (cellValue) => {
      return (
        <PurchasedAndMaximum
          purchased={cellValue.value.purchased}
          maximum={cellValue.value.maximum}
        />
      );
    },
    minWidth: 200,
  },
  {
    field: 'updatedAt',
    headerName: 'Última atualização',
    hide: true,
    valueFormatter: (params: GridValueFormatterParams<Date>) =>
      formatDate(params.value),
  },
];

export const purchaseIntentToRow = (purchaseIntent: PurchaseIntent) => ({
  ...purchaseIntent,
  issuers: purchaseIntent.interestingBanks?.map((bank) => bank.name) ?? [],
  brokerAccountNumber: purchaseIntent.accountNumber,
  amount: {
    purchased: purchaseIntent.totalPurchased,
    maximum: purchaseIntent.limit,
  },
});

export const AvailablePurchaseIntentsList: React.FC = () => {
  const { purchaseIntents, loading, error } = useAvailablePurchaseIntents();

  const rows = useMemo(
    () => purchaseIntents?.map(purchaseIntentToRow) ?? [],
    [purchaseIntents],
  );

  if (error) {
    return <DisplayError error={error} />;
  }

  return (
    <>
      <PageHeader
        title="Veja o que os usuários estão buscando comprar no momento."
        loading={loading}
      />
      <DataGrid
        rows={rows}
        columns={columns}
        disableSelectionOnClick
        autoHeight
        rowHeight={80}
        loading={loading}
      />
    </>
  );
};
