import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Form(theme: Theme) {
  return {
    MuiFormControl: {
      styleOverrides: {
        root: {
          width: '100%',
        },
      },
    },
  };
}
