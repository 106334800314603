import { Typography } from '@animatrix/components/Typography';
import { PageHeader } from '@common/components/PageHeader';
import { useNavigate } from 'react-router-dom';
import { PAGE_TITLES } from '../../../../layout/components/Dashboard/SidebarConfig';
import { PATH_DASHBOARD } from '../../../../routes/paths';
import { BrokerAccountsList } from '../../../list-broker-accounts/components/BrokerAccountsList';
import { useBrokerAccounts } from '../../../list-broker-accounts/hooks/useBrokerAccounts';

export const BrokerAccountsDashboard: React.FC = () => {
  const navigate = useNavigate();
  const { loading } = useBrokerAccounts();

  return (
    <>
      <Typography variant="h4" mb={4} loading={loading}>
        {PAGE_TITLES.customers}
      </Typography>
      <PageHeader
        title="Gerencie os clientes assessorados nesta conta."
        loading={loading}
        buttonProps={{
          label: 'Cadastrar Cliente',
          minWidth: 150,
          onClick: () => {
            navigate(PATH_DASHBOARD.app.createBrokerAccount);
          },
        }}
      />
      <BrokerAccountsList />
    </>
  );
};
