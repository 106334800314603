import { BrokerAccount } from '@common/types/BrokerAccount';
import { useState } from 'react';
import { createContainer } from 'unstated-next';

export const PlaceOrderFormContext = createContainer(() => {
  const [quantity, setQuantity] = useState(1);
  const [customerBrokerAccount, setCustomerBrokerAccount] =
    useState<BrokerAccount | null>(null);

  return {
    quantity,
    setQuantity,
    customerBrokerAccount,
    setCustomerBrokerAccount,
  };
});
