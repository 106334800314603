import { gql, useMutation } from '@apollo/client';
import { SelectOption } from '@common/components/EasySelect';
import {
  CreatePurchaseIntentInput,
  CreatePurchaseIntentMutation,
  CreatePurchaseIntentMutationVariables,
  Indexers,
  ProductKind,
} from '../../../../__generated__/types';
import { MY_PURCHASE_INTENTS_QUERY } from '../../list-purchase-intents/hooks/useMyPurchaseIntents';

const CREATE_PURCHASE_INTENT_MUTATION = gql`
  mutation CreatePurchaseIntent($input: CreatePurchaseIntentInput!) {
    createPurchaseIntent(input: $input) {
      id
      yieldRateMin
      maturityDaysMin
      maturityDaysMax
    }
  }
`;

export const useCreatePurchaseIntent = () => {
  const [_createPurchaseIntent, { loading, error }] = useMutation<
    CreatePurchaseIntentMutation,
    CreatePurchaseIntentMutationVariables
  >(CREATE_PURCHASE_INTENT_MUTATION, {
    update: (cache, result) => {
      if (result.data?.createPurchaseIntent?.id) {
        cache.evict({
          fieldName: 'purchaseIntents',
          broadcast: false,
        });
      }
    },
    refetchQueries: [
      {
        query: MY_PURCHASE_INTENTS_QUERY,
      },
    ],
  });

  const createPurchaseIntent = (
    input: Pick<
      CreatePurchaseIntentInput,
      | 'accountNumber'
      | 'yieldRateMin'
      | 'maturityDaysMin'
      | 'maturityDaysMax'
      | 'limit'
    > & {
      indexer: Indexers;
      interestingProducts: ProductKind[];
      interestingBanks: SelectOption<string>[];
    },
  ) => {
    return _createPurchaseIntent({
      variables: {
        input: {
          ...input,
          interestingBanks: input.interestingBanks?.map(({ value }) => value),
          interestingProducts: input.interestingProducts,
        },
      },
    });
  };

  return {
    createPurchaseIntent,
    loading,
    error,
  };
};
