import { Button, ButtonLoadingMode } from '@animatrix/components/Button';
import { MoneyInput } from '@animatrix/components/MoneyInput';
import { PercentageInput } from '@animatrix/components/PercentageInput';
import { EasySelect } from '@common/components/EasySelect';
import { SelectBanks } from '@common/components/SelectBanks';
import { INDEXER_OPTIONS } from '@common/constants/INDEXER_OPTIONS';
import { PRODUCT_OPTIONS } from '@common/constants/PRODUCT_OPTIONS';
import { getIndexerParams } from '@common/logic/getIndexerParams';
import { Grid } from '@mui/material';
import { useState } from 'react';
import { Controller, FieldError, useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { PickBrokerAccount } from '../../../../broker-accounts/common/components/PickBrokerAccount';
import { useAllBanks } from '../../hooks/useAllBanks';
import { useCreatePurchaseIntent } from '../../hooks/useCreatePurchaseIntent';
import { MATURITY_DAYS_OPTIONS } from './constants';
import { ICreatePurchaseIntentFormValues } from './interfaces/ICreatePurchaseIntentFormValues';
import AddIcon from '@mui/icons-material/Add';
import { useBrokerAccounts } from '../../../../broker-accounts/list-broker-accounts/hooks/useBrokerAccounts';
import { CreateBrokerAccountModal } from '../../../../broker-accounts/create-broker-account/components/CreateBrokerAccountModal';
import { useLoading } from '@common/hooks/useLoading';

export const CreatePurchaseIntentForm: React.FC = () => {
  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
    watch,
    setValue,
  } = useFormContext<ICreatePurchaseIntentFormValues>();
  const navigate = useNavigate();
  const { createPurchaseIntent, loading: loadingCreatePurchaseIntent } =
    useCreatePurchaseIntent();
  const { loading: loadingBanks } = useAllBanks();
  const { loading: loadingBrokerAccounts } = useBrokerAccounts();

  const { loadingQueries, loadingMutations, loading } = useLoading(
    [loadingBanks, loadingBrokerAccounts],
    [loadingCreatePurchaseIntent],
  );

  const indexer = watch('indexer');
  const { yieldSuffix } = getIndexerParams(indexer);
  const [brokerAccountModalOpen, setBrokerAccountModalOpen] = useState(false);

  const goBack = () => navigate(-1);

  const onSubmit = async (data: ICreatePurchaseIntentFormValues) => {
    try {
      const { brokerAccount, ...purchaseIntent } = data;

      if (!brokerAccount?.number) {
        return;
      }

      await createPurchaseIntent({
        ...purchaseIntent,
        accountNumber: brokerAccount.number,
      });
      toast.success('Intenção de compra cadastrada com sucesso!');
      navigate('/purchase/my-purchase-intents');
    } catch (err) {
      if (err instanceof Error) {
        toast.error(err.message);
      }

      throw err;
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={8}>
            <Controller
              name="brokerAccount"
              control={control}
              rules={{
                required: 'O Cliente é mandatório.',
              }}
              render={({ field }) => (
                <PickBrokerAccount
                  value={field.value}
                  onChange={field.onChange}
                  error={!!errors.brokerAccount}
                  helperText={
                    (errors.brokerAccount as FieldError)?.message ?? ''
                  }
                  loading={loadingQueries}
                />
              )}
            />
          </Grid>

          <Grid item xs={4}>
            <Button
              fullWidth
              sx={{ height: '100%' }}
              startIcon={<AddIcon />}
              variant="outlined"
              size="large"
              onClick={() => setBrokerAccountModalOpen(true)}
              disabled={loading}
              loading={loadingQueries}
              loadingMode={ButtonLoadingMode.Skeleton}
            >
              Novo cliente
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Controller
              rules={{
                required: 'O Valor é mandatório.',
              }}
              name="limit"
              control={control}
              render={({ field }) => (
                <MoneyInput
                  value={field.value}
                  onChange={field.onChange}
                  label="Valor"
                  error={!!errors.limit}
                  helperText={errors.limit?.message ?? ''}
                  loading={loadingQueries}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name="interestingProducts"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field }) => (
                <EasySelect
                  label="Produto"
                  displayEmpty
                  renderEmptyValue={() => {
                    return 'TODOS';
                  }}
                  value={field.value}
                  multiple
                  onChange={field.onChange}
                  options={PRODUCT_OPTIONS}
                  loading={loadingQueries}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name="interestingBanks"
              control={control}
              render={({ field }) => (
                <SelectBanks
                  value={field.value}
                  onChange={(event, newValue) => {
                    field.onChange(newValue);
                  }}
                  label={'Emissores Aceitos'}
                  multiple
                  loading={loadingQueries}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name="indexer"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field }) => (
                <EasySelect
                  label="Indexador"
                  value={field.value}
                  options={INDEXER_OPTIONS}
                  onChange={field.onChange}
                  loading={loadingQueries}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name="yieldRateMin"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field }) => (
                <PercentageInput
                  suffix={yieldSuffix}
                  precision={2}
                  value={field.value}
                  onChange={field.onChange}
                  label="Taxa Mínima"
                  error={!!errors.yieldRateMin}
                  helperText={errors.yieldRateMin?.message ?? ''}
                  loading={loadingQueries}
                />
              )}
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <Controller
              name="maturityDaysMin"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field }) => (
                <EasySelect
                  label="Vencimento mínimo"
                  value={field.value}
                  onChange={field.onChange}
                  options={MATURITY_DAYS_OPTIONS}
                  loading={loadingQueries}
                />
              )}
            />
          </Grid>
          <Grid item md={6} xs={12} mb={3}>
            <Controller
              name="maturityDaysMax"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field }) => (
                <EasySelect
                  label="Vencimento máximo"
                  value={field.value}
                  onChange={field.onChange}
                  options={MATURITY_DAYS_OPTIONS}
                  loading={loadingQueries}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Button
              variant="contained"
              type="submit"
              size="large"
              disabled={loading || !isValid}
              loading={loading}
              loadingMode={
                loadingMutations
                  ? ButtonLoadingMode.Circle
                  : ButtonLoadingMode.Skeleton
              }
              sx={{ mr: 4 }}
            >
              Cadastrar
            </Button>
            <Button
              size="large"
              sx={{ color: 'grey.800' }}
              disabled={loading}
              loading={loadingQueries}
              loadingMode={ButtonLoadingMode.Skeleton}
              onClick={goBack}
            >
              Cancelar
            </Button>
          </Grid>
        </Grid>
      </form>
      <CreateBrokerAccountModal
        open={brokerAccountModalOpen}
        onClose={() => setBrokerAccountModalOpen(false)}
        onBrokerAccountCreated={(brokerAccount) => {
          setValue('brokerAccount', brokerAccount, {
            shouldValidate: true,
          });
        }}
      />
    </>
  );
};
