import { Page } from '@animatrix/components/Page';
import { Container } from '@mui/material';
import { BrokerAccountsDashboard } from '../../broker-accounts-dashboard/components/BrokerAccountsDashboard';

export const BrokerAccountsPage: React.FC = () => {
  return (
    <Page title="InvestAI | Meus Clientes">
      <Container maxWidth={'xl'}>
        <BrokerAccountsDashboard />
      </Container>
    </Page>
  );
};
