import { Box } from '@mui/material';
import { Typography } from '@animatrix/components/Typography';

export const InfoBox: React.FC<{
  title: string;
  value: string | number;
  flexGrow?: number;
  flexBasis?: number;
}> = ({ title, value, flexGrow = 1, flexBasis = 0 }) => {
  return (
    <Box flexGrow={flexGrow} flexBasis={flexBasis}>
      <Typography variant="body2" color="grey.600">
        {title}
      </Typography>
      <Typography variant="body2">{value}</Typography>
    </Box>
  );
};
