import { Button, ButtonLoadingMode } from '@animatrix/components/Button';
import { Typography } from '@animatrix/components/Typography';
import { Box } from '@mui/material';

interface IPageHeaderProps {
  title: string;
  loading?: boolean;
  buttonProps?: {
    label: string;
    minWidth: number;
    onClick: () => void;
  };
}

export const PageHeader: React.FC<IPageHeaderProps> = ({
  title,
  loading,
  buttonProps,
}) => {
  return (
    // TODO: remove this minHeight when button gets moved to table
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      mb={2}
      sx={{ minHeight: 48 }}
    >
      <Typography
        variant="body2"
        color="grey.600"
        flexGrow={1}
        loading={loading}
      >
        {title}
      </Typography>

      {buttonProps && (
        <Button
          color="primary"
          variant="contained"
          size="large"
          onClick={buttonProps.onClick}
          sx={{ minWidth: buttonProps.minWidth }}
          loading={loading}
          loadingMode={ButtonLoadingMode.Skeleton}
        >
          {buttonProps.label}
        </Button>
      )}
    </Box>
  );
};
