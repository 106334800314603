import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { IconButton, InputAdornment } from '@mui/material';
import { MouseEvent, useState } from 'react';
import { TextField } from '../TextField';
import { IPasswordInputProps } from './interface';

export const PasswordInput: React.FC<IPasswordInputProps> = ({
  onChange,
  value,
  label = 'Senha',
  disabled = false,
  showPinHelp = false,
  className,
  style,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword((s) => !s);
  };

  const handleMouseDownPassword = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
  };

  return (
    <TextField
      label={label}
      type={showPassword ? 'text' : 'password'}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      className={className}
      style={style}
      disabled={disabled}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="mudar visibilidade da senha"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              size="large"
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};
