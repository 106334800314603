import { useTracker } from '@analytics/hooks/useTracker';
import { useEffect } from 'react';

export const SetExperimentData: React.FC<{
  experimentName: string;
}> = ({ experimentName }) => {
  const tracker = useTracker();
  useEffect(() => {
    tracker.setExperiment(experimentName);
  }, []);
  return null;
};
