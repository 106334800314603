import { Modal } from '@animatrix/components/Modal';
import { MoneyInput } from '@animatrix/components/MoneyInput';
import { QuantityInput } from '@animatrix/components/QuantityInput';
import { PublicOffer } from '@common/types/PublicOffer';
import { formatReais } from '@invest-ai/common';
import { Box, Grid } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { IBuyOfferFormValues } from './interfaces/IBuyOfferFormValues';
import { OfferSummary } from '../OfferSummary';
import { getValidationFunctions } from './logic/getValidationFunctions';
import BigNumber from 'bignumber.js';
import { Button } from '@animatrix/components/Button';
import { toast } from 'react-toastify';
import { usePlaceOrder } from '../../../../place-order/hooks/usePlaceOrder';
import { TextField } from '@animatrix/components/TextField';
import { BrokerAccount } from '@common/types/BrokerAccount';
import { Typography } from '@animatrix/components/Typography';

interface IBuyOfferModalProps {
  onClose: () => void;
  offer: PublicOffer | null;
  buyerBrokerAccount?: BrokerAccount | null;
}

export const BuyOfferModal: React.FC<IBuyOfferModalProps> = ({
  offer,
  buyerBrokerAccount,
  onClose,
}) => {
  const { placeOrder, loading: loadingPlaceOrder } = usePlaceOrder();

  const {
    handleSubmit,
    control,
    watch,
    resetField,
    formState: { isValid, errors },
  } = useForm<IBuyOfferFormValues>({ mode: 'onChange' });

  const [quantityToBuy] = watch(['quantityToBuy']);

  const validationFunctions = getValidationFunctions(
    offer?.availableQuantity ?? 0,
  );

  const buyOffer = async (data: IBuyOfferFormValues) => {
    if (!offer || !buyerBrokerAccount?.number) return;

    try {
      await placeOrder({
        offerId: offer.id,
        offerVersion: offer.version,
        quantity: data.quantityToBuy,
        buyerAccountNumber: buyerBrokerAccount.number,
      });

      toast.success('Compra executada com sucesso');
      closeModal();
    } catch (err) {
      if (err instanceof Error) {
        toast.error(err.message);
      }

      throw err;
    }
  };

  const closeModal = () => {
    if (loadingPlaceOrder) return;
    onClose();
    resetField('quantityToBuy', { defaultValue: 0 });
  };

  return (
    <Modal
      open={!!offer}
      title="Comprar ativo"
      onClose={onClose}
      Actions={() => (
        <>
          <Button
            variant="text"
            sx={{ color: 'grey.800' }}
            onClick={closeModal}
            disabled={loadingPlaceOrder}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit(buyOffer)}
            disabled={!isValid || loadingPlaceOrder}
            loading={loadingPlaceOrder}
          >
            Executar compra
          </Button>
        </>
      )}
    >
      <Grid container spacing={3} mb={3}>
        <Grid item xs={12}>
          <OfferSummary
            offer={offer}
            buyer={`${buyerBrokerAccount?.number}, ${buyerBrokerAccount?.customerName}`}
          />
        </Grid>
        <Grid item xs={4}>
          <MoneyInput
            label="Valor do PU"
            value={offer?.unitPrice ?? 0}
            disabled
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            label="Unidades disponíveis"
            value={offer?.availableQuantity}
            disabled
          />
        </Grid>
        <Grid item xs={4}>
          <Controller
            name="quantityToBuy"
            control={control}
            defaultValue={0}
            rules={{
              required: true,
              validate: validationFunctions.quantityToBuy,
            }}
            render={({ field }) => (
              <QuantityInput
                label="Quantidade"
                value={field.value}
                onChange={field.onChange}
                error={!!errors.quantityToBuy}
                helperText={errors.quantityToBuy?.message ?? ''}
              />
            )}
          />
        </Grid>
      </Grid>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h6">Total</Typography>
        <Typography variant="body1">
          {formatReais(
            new BigNumber(quantityToBuy)
              .times(offer?.unitPrice ?? 0)
              .toNumber(),
          )}
        </Typography>
      </Box>
    </Modal>
  );
};
