import { Button } from '@animatrix/components/Button';
import { Modal } from '@animatrix/components/Modal';
import { BrokerAccount } from '@common/types/BrokerAccount';
import { Grid, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { BrokerName } from '../../../../../__generated__/types';
import { useCreateBrokerAccount } from '../../hooks/useCreateBrokerAccount';
import { ICreateBrokerAccountFormValues } from '../../interfaces/ICreateBrokerAccountFormValues';

interface ICreateBrokerAccountModalProps {
  onBrokerAccountCreated: (brokerAccount: BrokerAccount) => void;
  open: boolean;
  onClose: () => void;
}

export const CreateBrokerAccountModal: React.FC<
  ICreateBrokerAccountModalProps
> = ({ onBrokerAccountCreated, open, onClose }) => {
  const {
    register,
    handleSubmit,
    resetField,
    formState: { errors, isValid },
  } = useForm<ICreateBrokerAccountFormValues>({
    mode: 'onChange',
  });
  const { createBrokerAccount, loading } = useCreateBrokerAccount();

  const onSubmit = async (data: ICreateBrokerAccountFormValues) => {
    try {
      const response = await createBrokerAccount({
        account: data.accountNumber,
        customerName: data.customerName,
        institutionId: BrokerName.GENIAL,
      });

      //Enqueue task to wait for cache update
      await new Promise((resolve) => setTimeout(resolve));

      if (!response.data) {
        throw new Error('Mutation returned but data is undefined');
      }
      toast.success('Cliente cadastrado com sucesso!');
      if (onBrokerAccountCreated)
        onBrokerAccountCreated(response.data.createBrokerAccount);
    } catch (err) {
      if (err instanceof Error) {
        toast.error(err.message);
      }

      throw err;
    } finally {
      onClose();
      resetField('customerName');
      resetField('accountNumber');
    }
  };

  return (
    <Modal
      open={open}
      title={'Cadastrar novo cliente'}
      onClose={() => !loading && onClose()}
      Actions={() => (
        <>
          <Button
            variant="text"
            sx={{ color: 'grey.800' }}
            onClick={onClose}
            disabled={loading}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit(onSubmit)}
            disabled={!isValid || loading}
            loading={loading}
          >
            Cadastrar cliente
          </Button>
        </>
      )}
    >
      <Grid container spacing={3}>
        <Grid item md={6} xs={12}>
          <TextField
            {...register('customerName', {
              required: 'O Nome do Cliente é mandatório.',
            })}
            label="Nome do Cliente"
            error={!!errors.customerName}
            helperText={errors.customerName?.message ?? ''}
          />
        </Grid>

        <Grid item md={6} xs={12}>
          <TextField
            {...register('accountNumber', {
              required: 'O Número da Conta é mandatório.',
            })}
            label="Número da Conta"
            error={!!errors.accountNumber}
            helperText={errors.accountNumber?.message ?? ''}
          />
        </Grid>
      </Grid>
    </Modal>
  );
};
