import { IEventTracker } from '@analytics/interfaces/IEventTracker';

export class BroadcasterEventTracker implements IEventTracker {
  private readonly trackers: IEventTracker[];
  constructor(trackers: IEventTracker[]) {
    this.trackers = trackers;
  }
  pageView(pageId: string): void {
    this.trackers.forEach((tracker) => tracker.pageView(pageId));
  }

  setUser(id: number, email: string): void {
    this.trackers.forEach((tracker) => tracker.setUser(id, email));
  }

  public trackEvent = (eventName: string, data: Record<string, any>): void => {
    this.trackers.forEach((tracker) => tracker.trackEvent(eventName, data));
  };

  public setExperiment(experimentName: string): void {
    this.trackers.forEach((tracker) => tracker.setExperiment(experimentName));
  }
}
