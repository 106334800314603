import { Button } from '@animatrix/components/Button';
import { SelectedOfferContext } from '../../context/SelectedOfferContext';

export const OpenPlaceOrderModalButton: React.FC<{
  offerId: string;
  offerVersion: number;
  availableQuantity: number;
}> = ({ offerId, offerVersion, availableQuantity }) => {
  const { setSelectedOfferId, setSelectedOfferVersion } =
    SelectedOfferContext.useContainer();

  return (
    <Button
      variant="contained"
      color="primary"
      size="small"
      onClick={() => {
        setSelectedOfferId(offerId);
        setSelectedOfferVersion(offerVersion);
      }}
      disabled={availableQuantity <= 0}
    >
      Comprar
    </Button>
  );
};
