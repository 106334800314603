import { Page } from '@animatrix/components/Page';
import { CreateOfferDashboard } from '@create-offer/components/CreateOfferDashboard';
import { Container } from '@mui/material';
import React from 'react';

export const CreateOfferPage: React.FC = () => {
  return (
    <Page title="InvestAI | Criar Intenção de Compra">
      <Container maxWidth={'xl'}>
        <CreateOfferDashboard />
      </Container>
    </Page>
  );
};
