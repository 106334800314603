import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import {
  UpdateOfferInput,
  UpdateOfferMutation,
  UpdateOfferMutationVariables,
} from '../../../__generated__/types';
import { GET_MY_OFFERS_QUERY } from './useMyOffers';

const UPDATE_OFFER_MUTATION = gql`
  mutation UpdateOffer($input: UpdateOfferInput!) {
    updateOffer(input: $input) {
      id
      rebuyYieldRate
      resellYieldRate
    }
  }
`;

export const useUpdateOffer = () => {
  const [_updateOffer, { loading, error }] = useMutation<
    UpdateOfferMutation,
    UpdateOfferMutationVariables
  >(UPDATE_OFFER_MUTATION, {
    refetchQueries: [{ query: GET_MY_OFFERS_QUERY }],
  });

  const updateOffer = (input: UpdateOfferInput) => {
    return _updateOffer({
      variables: {
        input,
      },
    });
  };

  return {
    updateOffer,
    loading,
    error,
  };
};
