import { DataGrid } from '@animatrix/components/DataGrid';
import { DisplayError } from '@animatrix/components/DisplayError';
import { ActionsMenu } from '@common/components/ActionsMenu';
import { PageHeader } from '@common/components/PageHeader';
import { formatDate, formatUuid } from '@common/date/formatters';
import { parseServerDate } from '@common/date/parsers';
import { parseYieldColumn } from '@common/logic/parseYieldColumn';
import { renderYieldDescription } from '@common/logic/renderYieldDescription';
import { formatReais } from '@invest-ai/common';
import FilterListIcon from '@mui/icons-material/FilterList';
import {
  GridColDef,
  GridFilterModel,
  GridRenderCellParams,
  GridValueFormatterParams,
} from '@mui/x-data-grid-pro';
import { useState } from 'react';

import { OrderSubject } from '../../../../__generated__/types';
import { IssuersList } from '../../../purchase-intent/list-purchase-intents/components/IssuersList/IssuersList';
import { useMyOrders } from '../../hooks/useMyOrders';
import { OrderStatusLabel } from '../OrderStatus';
import { SoldOrderRow } from './interfaces';

const sellerColumns: GridColDef[] = [
  {
    field: 'shortOfferId',
    headerName: 'ID Oferta',
  },
  {
    flex: 1,
    field: 'date',
    headerName: 'Data da venda',
    valueFormatter: (params: GridValueFormatterParams<Date>) =>
      formatDate(params.value),
  },
  {
    flex: 1,
    field: 'buyerAccountNumber',
    headerName: 'Cliente',
  },
  {
    flex: 1,
    field: 'assetNickname',
    headerName: 'Ativo',
  },
  {
    field: 'issuer',
    headerName: 'Emissor',
    flex: 1,
    minWidth: 200,
    hide: true,
    renderCell: (params: GridRenderCellParams) => (
      <IssuersList issuers={[params.value]} />
    ),
  },
  {
    field: 'maturityDate',
    headerName: 'Vencimento',
    valueFormatter: (params: GridValueFormatterParams<Date>) =>
      formatDate(params.value),
    type: 'date',
    hide: true,
  },
  {
    field: 'indexer',
    headerName: 'Indexador',
    width: 100,
    hide: true,
  },
  {
    field: 'rebuyYieldRate',
    headerName: 'Taxa',
    type: 'number',
    renderCell: renderYieldDescription,
    valueParser: parseYieldColumn,
  },
  {
    field: 'rebuyUnitPrice',
    headerName: 'PU Recompra',
    type: 'number',
    valueFormatter: (params: GridValueFormatterParams<number>) =>
      formatReais(params.value),
  },
  {
    flex: 1,
    field: 'quantity',
    headerName: 'Quantidade',
  },
  {
    flex: 1,
    field: 'totalAmount',
    headerName: 'Valor',
    valueFormatter: (params: GridValueFormatterParams<number>) =>
      formatReais(params.value),
  },

  {
    flex: 1,
    field: 'status',
    headerName: 'Status',
    renderCell: (cellValues) => {
      return <OrderStatusLabel status={cellValues.value} />;
    },
  },
  {
    field: 'actions',
    headerName: 'Ações',
    align: 'right',
    renderCell: (params: GridRenderCellParams) => (
      <ActionsMenu actions={params.row.actions} />
    ),
  },
];

export const SoldOrders: React.FC = () => {
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
  });

  const { orders, loading, error } = useMyOrders(OrderSubject.SELLER);

  if (error) {
    return <DisplayError error={error} />;
  }

  const onFilterByOfferId = (shortOfferId: string) => {
    const offerColumnFieldName = 'shortOfferId';

    const items = filterModel.items.filter(
      (filter) => filter.columnField !== offerColumnFieldName,
    );

    const offerFilterItem = {
      id: offerColumnFieldName + shortOfferId,
      columnField: offerColumnFieldName,
      operatorValue: 'contains',
      value: shortOfferId,
    };

    setFilterModel((prev) => ({
      ...prev,
      items: [...items, offerFilterItem],
    }));
  };

  const onFilterModelChange = (newFilterModel: GridFilterModel) => {
    setFilterModel(newFilterModel);
  };

  const rows: SoldOrderRow[] = orders.map((order) => ({
    id: order.id,
    shortOfferId: formatUuid(order.offer.id),
    date: parseServerDate(order.date),
    buyerAccountNumber: order.buyerAccountNumber,
    quantity: order.quantity,
    totalAmount: order.quantity * order.rebuyUnitPrice,
    assetNickname: order.assetNickname,
    rebuyUnitPrice: order.rebuyUnitPrice,
    rebuyYieldRate: order.rebuyYieldRate,
    status: order.status,
    issuer: order.offer.issuer.name,
    indexer: order.indexer,
    maturityDate: parseServerDate(order.offer.maturityDate),
    actions: [
      {
        icon: <FilterListIcon />,
        label: 'Filtrar compras da mesma oferta',
        onClick: () => onFilterByOfferId(formatUuid(order.offer.id)),
      },
    ],
  }));

  return (
    <>
      <PageHeader
        title="Histórico de vendas realizadas na plataforma."
        loading={loading}
      />
      <DataGrid
        rows={rows}
        columns={sellerColumns}
        disableSelectionOnClick
        autoHeight
        loading={loading}
        getRowId={(row: SoldOrderRow) => row.id}
        filterModel={filterModel}
        onFilterModelChange={onFilterModelChange}
      />
    </>
  );
};
