import { gql, useQuery } from '@apollo/client';
import { parseServerDate } from '@common/date/parsers';
import { Unpacked } from '@common/types/helper-types';
import { addDays } from 'date-fns';
import {
  MyPurchaseIntentsQuery,
  MyPurchaseIntentsQueryVariables,
} from '../../../../__generated__/types';

export type PurchaseIntent = Unpacked<
  MyPurchaseIntentsQuery['myPurchaseIntents']
>;

export const MY_PURCHASE_INTENTS_QUERY = gql`
  query MyPurchaseIntents {
    myPurchaseIntents {
      id
      yieldRateMin
      interestingProducts
      indexer
      maturityDaysMin
      maturityDaysMax
      accountNumber
      limit
      totalPurchased
      createdAt
      updatedAt
      interestingBanks {
        id
        name
      }
    }
  }
`;

export const useMyPurchaseIntents = () => {
  const { data, loading, error } = useQuery<
    MyPurchaseIntentsQuery,
    MyPurchaseIntentsQueryVariables
  >(MY_PURCHASE_INTENTS_QUERY);

  return {
    purchaseIntents:
      data?.myPurchaseIntents.map((purchaseIntent: any) => ({
        ...purchaseIntent,
        createdAt: parseServerDate(purchaseIntent.createdAt),
        updatedAt: parseServerDate(purchaseIntent.updatedAt),
        maturityDateMin: addDays(new Date(), purchaseIntent.maturityDaysMin),
        maturityDateMax: addDays(new Date(), purchaseIntent.maturityDaysMax),
        feeMin: purchaseIntent.yieldRateMin / 100,
      })) ?? [],
    loading,
    error,
  };
};
