import { Skeleton } from '@mui/material';
import {
  DataGridPro,
  DataGridProProps,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  ptBR,
} from '@mui/x-data-grid-pro';

const CustomToolbar: React.FC = () => (
  <GridToolbarContainer>
    <GridToolbarFilterButton />
    <GridToolbarColumnsButton />
  </GridToolbarContainer>
);

export const DataGrid: React.FC<DataGridProProps> = ({
  components,
  ...props
}) => {
  if (props.loading) {
    return (
      <Skeleton
        variant="rectangular"
        width="100%"
        height={250}
        sx={{ borderRadius: 2 }}
      />
    );
  }

  return (
    <DataGridPro
      localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
      components={{
        Toolbar: CustomToolbar,
        ...components,
      }}
      {...props}
    />
  );
};
