import { addMinutes, format, Locale } from 'date-fns';

const DATE_FORMAT_FOR_PARAM = 'dd/MM/yyyy';
const DATE_TIME_FORMAT_FOR_PARAM = 'dd/MM/yyyy HH:mm:ss';

export const formatDateTime = (date: Date) =>
  format(
    addMinutes(date, -date.getTimezoneOffset()),
    DATE_TIME_FORMAT_FOR_PARAM,
  );

export const formatDate = (
  date: Date,
  formatString = DATE_FORMAT_FOR_PARAM,
  locale?: Locale,
) =>
  format(addMinutes(date, date.getTimezoneOffset()), formatString, { locale });

export const formatDateSubtractTimezone = (date: Date) =>
  format(addMinutes(date, -date.getTimezoneOffset()), DATE_FORMAT_FOR_PARAM);

export const formatUuid = (uuid: string): string => uuid?.split('-')[0] || '';
