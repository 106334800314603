import { gql, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import {
  GetResellYieldRateQuery,
  GetResellYieldRateQueryVariables,
  Indexers,
} from '../../../__generated__/types';

export const GET_RESELL_YIELD_RATE_QUERY = gql`
  query GetResellYieldRate($rebuyYieldRate: Float!, $indexer: Indexers!) {
    getResellYieldRate(rebuyYieldRate: $rebuyYieldRate, indexer: $indexer)
  }
`;

export const useGetResellYieldRate = (
  rebuyYieldRate: number,
  indexer: string,
) => {
  const { loading, error, data } = useQuery<
    GetResellYieldRateQuery,
    GetResellYieldRateQueryVariables
  >(GET_RESELL_YIELD_RATE_QUERY, {
    variables: {
      rebuyYieldRate,
      indexer: indexer as Indexers,
    },
    skip: isNaN(rebuyYieldRate),
  });

  const [resellYieldRate, setResellYieldRate] = useState(0);

  useEffect(() => {
    if (!data) return;
    setResellYieldRate(data.getResellYieldRate);
  }, [data]);

  return { loading, error, resellYieldRate };
};
