// components
import { CreateOfferPage } from '@create-offer/pages/CreateOfferPage';
import { ResetPassword } from '@forgot-password/components/ResetPassword';
import { Page404 } from '@global/common-pages/404';
import { SignIn } from '@login/components/SignIn';
import { useTokens } from '@login/hooks/useTokens';
import { Navigate, useRoutes } from 'react-router-dom';
import { CreateBrokerAccountPage } from '../../../broker-accounts/create-broker-account/pages/CreateBrokerAccountPage';
import { BrokerAccountsPage } from '../../../broker-accounts/pages/BrokerAccountsPage';
import { ForgotPassword } from '../../../forgot-password/components/ForgotPassword';
import { DashboardLayout } from '../../../layout/components/Dashboard';
import { LogoOnlyLayout } from '../../../layout/components/LogoOnlyLayout';
import { MyOffersPage } from '../../../my-offers/pages/MyOffersPage';
import { MyOrdersPage } from '../../../my-orders/pages/MyOrdersPage';
import { CreatePurchaseIntentPage } from '../../../purchase-intent/create-purchase-intent/pages/CreatePurchaseIntentPage';
import { PurchasePage } from '../../../purchase/pages/PurchasePage';
import { BookPage } from '../../pages/book/BookPage';
import { ForgotPasswordPage } from '../../pages/forgot-password/ForgotPasswordPage';
import { ResetPasswordPage } from '../../pages/forgot-password/ResetPasswordPage';
import { LoginPage } from '../../pages/login/loginPage';
import { Logout } from '../../pages/logout';
import { PATH_DASHBOARD } from '../../paths';

export const AppRouter: React.FC = () => {
  const { getToken } = useTokens();
  const token = getToken();

  return useRoutes([
    // Dashboard Routes
    {
      path: '/',
      element: token ? <DashboardLayout /> : <UnauthenticatedNavigate />,
      children: [
        {
          path: '',
          element: <Navigate to={PATH_DASHBOARD.app.myOffers} replace />,
        },
        // Offer Routes
        {
          path: PATH_DASHBOARD.app.sell,
          element: <Navigate to={PATH_DASHBOARD.app.myOffers} replace />,
        },
        { path: `${PATH_DASHBOARD.app.sell}/*`, element: <MyOffersPage /> },
        { path: PATH_DASHBOARD.app.createOffer, element: <CreateOfferPage /> },
        // Purchase Routes
        { path: `${PATH_DASHBOARD.app.purchase}/*`, element: <PurchasePage /> },
        {
          path: PATH_DASHBOARD.app.createPurchaseIntent,
          element: <CreatePurchaseIntentPage />,
        },
        // Order Routes
        {
          path: PATH_DASHBOARD.app.myOrders,
          element: (
            <Navigate to={PATH_DASHBOARD.app.myOrdersPurchased} replace />
          ),
        },
        { path: `${PATH_DASHBOARD.app.myOrders}/*`, element: <MyOrdersPage /> },
        {
          path: PATH_DASHBOARD.app.createBrokerAccount,
          element: <CreateBrokerAccountPage />,
        },
        {
          path: PATH_DASHBOARD.app.brokerAccounts,
          element: <BrokerAccountsPage />,
        },
      ],
    },
    {
      path: '/book',
      element: token ? <DashboardLayout /> : <UnauthenticatedNavigate />,
      children: [{ path: PATH_DASHBOARD.app.book, element: <BookPage /> }],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },

    {
      path: PATH_DASHBOARD.app.login,
      element: <LoginPage />,
      children: [{ element: <SignIn />, path: '' }],
    },
    {
      path: PATH_DASHBOARD.app.forgotPassword,
      element: <ForgotPasswordPage />,
      children: [{ element: <ForgotPassword />, path: '' }],
    },
    {
      path: PATH_DASHBOARD.app.resetPassword,
      element: <ResetPasswordPage />,
      children: [{ element: <ResetPassword />, path: '' }],
    },
    { path: '/logout', element: <Logout /> },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
};

const UnauthenticatedNavigate: React.FC = () => {
  const unauthenticatedNavigatePath = PATH_DASHBOARD.app.login;

  return <Navigate replace to={unauthenticatedNavigatePath} />;
};
