import { useSendResetPassword } from '@forgot-password/hooks/useSendResetPassword';
import { Link } from '@login/components/Link';
import Alert from '@mui/material/Alert';
import { FormEventHandler, useState } from 'react';
import { Button } from '@animatrix/components/Button';
import { TextField } from '@animatrix/components/TextField';
import { DisplayError } from '@animatrix/components/DisplayError';
import { Form, classes } from './styles';
import { Title } from '@login/components/Title';
import { Subtitle } from '@login/components/Subtitle';
import { PATH_DASHBOARD } from '../../../routes/paths';

export const ForgotPassword = () => {
  const { sendResetPassword, data, loading, error } = useSendResetPassword();
  const [email, setEmail] = useState('');
  const valid = !!email;

  const performResetPassword: FormEventHandler = async (e) => {
    e.preventDefault();
    await sendResetPassword(email);
    setEmail('');
  };

  return (
    <Form onSubmit={performResetPassword}>
      <Title>Recuperar senha</Title>
      <Subtitle>
        Digite o seu email e enviaremos um link para recuperar a senha do email.
      </Subtitle>
      <TextField
        label="E-mail"
        variant="outlined"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        fullWidth
        InputProps={{ type: 'email', autoCapitalize: 'none' }}
        InputLabelProps={{ shrink: true }}
        className={classes.email}
      />
      <DisplayError error={error} className={classes.message} />
      {data && (
        <Alert severity="success" className={classes.message}>
          Enviamos um e-mail para você com as instruções para resetar a senha.
        </Alert>
      )}
      <Button
        variant="contained"
        color="primary"
        fullWidth
        disabled={loading || !valid}
        size="large"
        type="submit"
      >
        Enviar
      </Button>
      <Link to={PATH_DASHBOARD.app.login} className={classes.link}>
        Voltar ao Login
      </Link>
    </Form>
  );
};
