import { useTheme } from '@mui/material/styles';
import { BoxProps } from '@mui/material';
import { RootStyle } from './styles';
import { LabelColor, LabelVariant } from './types';

interface LabelProps extends BoxProps {
  color?: LabelColor;
  variant?: LabelVariant;
}

export const Label: React.FC<LabelProps> = ({
  color = 'default',
  variant = 'ghost',
  children,
  sx,
}) => {
  const theme = useTheme();

  return (
    <RootStyle ownerState={{ color, variant }} sx={sx} theme={theme}>
      {children}
    </RootStyle>
  );
};
