import { MoreVert } from '@mui/icons-material';
import {
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  PopoverOrigin,
} from '@mui/material';
import { useState } from 'react';
import { Typography } from '@animatrix/components/Typography';

export interface Action {
  label: string;
  icon: JSX.Element;
  onClick: () => void;
}

interface IActionsMenu {
  actions: Action[];
  disabled?: boolean;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
}

export const ActionsMenu: React.FC<IActionsMenu> = ({
  actions,
  disabled,
  anchorOrigin = { vertical: 'top', horizontal: 'right' },
  transformOrigin = { vertical: 'top', horizontal: 'right' },
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);

  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget);

  const closeMenu = () => setAnchorEl(null);

  return (
    <>
      <IconButton disabled={disabled} onClick={openMenu}>
        <MoreVert />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        onClose={closeMenu}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        PaperProps={{ sx: { borderRadius: 2, boxShadow: 24 } }}
        open={open}
      >
        {actions.map(({ label, icon, onClick }) => {
          return (
            <MenuItem
              key={label}
              sx={{ py: 1.5, px: 2 }}
              onClick={() => {
                onClick();
                closeMenu();
              }}
            >
              <ListItemIcon>{icon}</ListItemIcon>
              <Typography>{label}</Typography>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};
