import { OptionValue, Select } from '@animatrix/components/Select';
import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  ListItemText,
  MenuItem,
} from '@mui/material';

export interface SelectOption<T> {
  value: T;
  label: string;
}
interface IEasySelectProps {
  value: OptionValue;
  options: SelectOption<string | number>[];
  label: string;
  onChange?: (value: OptionValue) => void;
  style?: React.CSSProperties;
  multiple?: boolean;
  displayEmpty?: boolean;
  disabled?: boolean;
  renderEmptyValue?: (value: OptionValue) => React.ReactNode;
  loading?: boolean;
}

export const EasySelect: React.FC<IEasySelectProps> = ({
  onChange,
  value,
  options,
  label,
  style,
  multiple,
  displayEmpty,
  disabled,
  renderEmptyValue,
  loading,
}) => {
  const renderValue = (selected: OptionValue) => {
    if (multiple && Array.isArray(selected)) {
      if (selected.length) {
        return (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((value) => (
              <Chip key={value} label={value} size="small" />
            ))}
          </Box>
        );
      }
      return renderEmptyValue?.(selected) ?? null;
    }
    return selected;
  };

  return (
    <FormControl style={style}>
      <Select
        displayEmpty={displayEmpty}
        renderValue={renderValue}
        multiple={multiple}
        value={value}
        label={label}
        disabled={disabled}
        onChange={(e) => {
          if (!onChange) return;
          onChange(e.target.value);
        }}
        MenuProps={{
          PaperProps: { sx: { maxHeight: 300 } },
        }}
        loading={loading}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {multiple ? (
              <>
                <Checkbox
                  checked={(value as any[]).indexOf(option.value) > -1}
                />
                <ListItemText primary={option.label} />
              </>
            ) : (
              <ListItemText primary={option.label} />
            )}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
