import { InfoBox } from '@common/components/InfoBox';
import { Summary } from '@common/components/Summary';
import { formatDate } from '@common/date/formatters';
import { formatIndexer } from '@common/logic/formatIndexer';
import { formatYieldDescription } from '@common/logic/formatYieldDescription';
import { PublicOffer } from '@common/types/PublicOffer';
import { formatReais } from '@invest-ai/common';
import { Box } from '@mui/material';
import { ptBR } from 'date-fns/locale';
import { Indexers } from '../../../../../__generated__/types';

export interface IOfferSummaryProps {
  offer: PublicOffer | null;
  buyer: string;
}

export const OfferSummary: React.FC<IOfferSummaryProps> = ({
  offer,
  buyer,
}) => {
  if (!offer) return null;

  const indexer = formatIndexer(offer.indexer as Indexers);
  const yieldRate = formatYieldDescription(
    offer.indexer as Indexers,
    offer.resellYieldRate,
  );
  const availableQuantity = formatReais(
    offer.availableQuantity * offer.unitPrice,
  );
  const maturityDate = formatDate(
    offer.maturityDate,
    'dd/MM/yyyy',
    ptBR,
  ).toUpperCase();

  return (
    <Summary
      primaryTitle={`${offer.productKind} ${offer.issuer.name} ${maturityDate}`}
      secondaryTitle={availableQuantity}
    >
      <Box width="100%" display="flex" mb={2}>
        <InfoBox title="Produto" value={offer.productKind} />
        <InfoBox title="Indexador" value={indexer} />
        <InfoBox title="Taxa" value={yieldRate} />
      </Box>
      <Box width="100%" display="flex" mb={2}>
        <InfoBox title="Emissor" value={offer.issuer.name} />
        <InfoBox title="Vencimento" value={maturityDate} flexGrow={2} />
      </Box>
      <InfoBox title="Comprador" value={buyer} />
    </Summary>
  );
};
