import { ApolloError } from '@apollo/client';
import { GraphQLError } from 'graphql';
import { head } from 'ramda';
import { ErrorCodes } from '../constants/ErrorCodes';
import { ErrorMessages } from '../constants/ErrorMessages';

export const extractFirstError = (error: ApolloError): GraphQLError | null => {
  if (!error || !error.graphQLErrors || !error.graphQLErrors.length) {
    return null;
  }

  return head(error.graphQLErrors) ?? null;
};

export const extractGraphQLErrorCode = (
  graphQLError: GraphQLError,
): ErrorCodes => {
  return graphQLError?.extensions?.code as ErrorCodes;
};

export const extractGraphQLErrorMessage = (
  graphQLError: GraphQLError,
): string => {
  return graphQLError?.message;
};

export const matchesError =
  (errorCode: ErrorCodes, errorMessage?: string) =>
  (error: ApolloError): boolean => {
    const firstError = extractFirstError(error);
    if (!firstError) {
      return false;
    }

    const errorMessageMatch =
      errorMessage === extractGraphQLErrorMessage(firstError);

    return (
      extractGraphQLErrorCode(firstError) === errorCode &&
      (!errorMessage || errorMessageMatch)
    );
  };

export const matchesNotFound = matchesError(ErrorCodes.NotFound);
export const matchesBadUserInput = matchesError(ErrorCodes.BadUserInput);
export const matchesUserNotFound = matchesError(
  ErrorCodes.BadUserInput,
  ErrorMessages.UserNotFound,
);
export const matchesIncorrectPassword = matchesError(
  ErrorCodes.BadUserInput,
  ErrorMessages.IncorrectPassword,
);
export const matchesEmailAlreadyExists = matchesError(
  ErrorCodes.BadUserInput,
  ErrorMessages.EmailAlreadyExists,
);
