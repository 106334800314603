import { isDateBetween } from '@common/date/parsers';
import { PurchaseIntent } from '@common/types/PurchaseIntent';
import { ICreateOfferFormValues } from '@create-offer/components/CreateOfferForm/interfaces/ICreateOfferFormValues';
import { isValid } from 'date-fns';
import { ProductKind } from '../../../__generated__/types';

export const getPurchaseIntentSuitability = (
  purchaseIntent: PurchaseIntent,
  params: Pick<
    ICreateOfferFormValues,
    'productKind' | 'issuer' | 'resellYieldRate' | 'maturityDate'
  > & {
    resellUnitPrice: number;
  },
): string => {
  const {
    productKind,
    issuer,
    resellYieldRate,
    maturityDate,
    resellUnitPrice,
  } = params;

  if (purchaseIntent.yieldRateMin > resellYieldRate) {
    return 'Taxa incompatível';
  }

  if (
    productKind &&
    !purchaseIntent.interestingProducts?.includes(productKind as ProductKind)
  ) {
    return 'Produto incompatível';
  }

  if (
    issuer &&
    purchaseIntent.interestingBanks?.length &&
    !purchaseIntent.interestingBanks?.some((bank) => bank.id === issuer.value)
  ) {
    return 'Emissor incompatível';
  }

  if (
    isValid(maturityDate) &&
    !isDateBetween(maturityDate, [
      purchaseIntent.maturityDateMin,
      purchaseIntent.maturityDateMax,
    ])
  ) {
    return 'Vencimento incompatível';
  }

  if (resellUnitPrice > purchaseIntent.availableLimit) {
    return 'Limite insuficiente';
  }

  return '';
};
