import { ApolloError } from '@apollo/client';
import {
  extractFirstError,
  matchesBadUserInput,
  matchesEmailAlreadyExists,
  matchesIncorrectPassword,
  matchesNotFound,
  matchesUserNotFound,
} from './matchesError';

export const getErrorMessage = (error?: ApolloError | Error): string | null => {
  if (!error) {
    return null;
  }

  if (typeof error === 'object' && error instanceof ApolloError) {
    const firstError = extractFirstError(error);

    if (matchesNotFound(error)) {
      return `${firstError?.extensions?.entity} ${firstError?.extensions?.entityId} not found.`;
    }

    if (matchesEmailAlreadyExists(error)) {
      return 'Email já cadastrado.';
    }

    if (matchesIncorrectPassword(error) || matchesUserNotFound(error)) {
      return 'Email ou senha inválidos.';
    }

    if (matchesBadUserInput(error)) {
      return `Wrong data sent to the server: ${firstError?.message}`;
    }

    if (firstError) {
      return `${firstError.message}`;
    }
  }

  return 'Algo deu errado. Por favor, tente novamente mais tarde.';
};
