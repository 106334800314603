import { OrderStatus } from '../../../../__generated__/types';

export const getLabelStatusProps = (status: OrderStatus) => {
  const labelStatusProps = {
    [OrderStatus.SCHEDULED]: {
      text: 'Agendado',
      color: 'warning',
    },
    [OrderStatus.CANCELLED]: {
      text: 'Cancelado',
      color: 'warning',
    },
    [OrderStatus.DISPATCHED]: {
      text: 'Enviado',
      color: 'info',
    },
    [OrderStatus.SETTLED]: { text: 'Executado', color: 'success' },
    [OrderStatus.ERROR]: { text: 'Erro', color: 'error' },
  };

  return labelStatusProps[status] ?? { text: status, color: 'default' };
};
