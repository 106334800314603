import { InputAdornment } from '@mui/material';
import BigNumber from 'bignumber.js';
import React from 'react';
import { TextField } from '../TextField';

export const MoneyInput: React.FC<{
  label: string;
  value: number;
  onChange?: (value: number) => void;
  fullWidth?: boolean;
  helperText?: string;
  error?: boolean;
  disabled?: boolean;
  loading?: boolean;
}> = ({
  label,
  value,
  onChange,
  fullWidth,
  helperText,
  error,
  disabled,
  loading,
}) => {
  const stringValue = value?.toLocaleString('pt-BR', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });

  return (
    <TextField
      label={label}
      variant="outlined"
      fullWidth={fullWidth}
      InputLabelProps={{
        shrink: true,
      }}
      value={stringValue}
      InputProps={{
        startAdornment: <InputAdornment position="start">R$</InputAdornment>,
      }}
      helperText={helperText}
      error={error}
      disabled={disabled}
      onChange={(event) => {
        if (!onChange) return;
        const value = event.target.value;
        const pureNumericValue = value.replace(/\D/g, '');
        const floatValue = new BigNumber(pureNumericValue).div(100).toNumber();
        if (isNaN(floatValue)) return onChange(0);
        onChange(floatValue);
      }}
      placeholder={'0,00'}
      loading={loading}
    />
  );
};
