import { formatReais } from '@invest-ai/common';
import { Typography } from '@animatrix/components/Typography';

interface IPurchasedAndMaximumProps {
  purchased: number;
  maximum: number;
}

export const PurchasedAndMaximum: React.FC<IPurchasedAndMaximumProps> = ({
  purchased,
  maximum,
}) => {
  return (
    <div>
      <Typography variant="body2">{formatReais(purchased)} /</Typography>
      <Typography variant="caption" color="grey.600">
        {formatReais(maximum)}
      </Typography>
    </div>
  );
};
