import { DataGrid } from '@animatrix/components/DataGrid';
import { DisplayError } from '@animatrix/components/DisplayError';
import { SelectedOfferContext } from '@book/list-offers/context/SelectedOfferContext';
import { PageHeader } from '@common/components/PageHeader';
import { formatDate } from '@common/date/formatters';
import { formatReais } from '@invest-ai/common';
import {
  GridColDef,
  GridRenderCellParams,
  GridValueFormatterParams,
} from '@mui/x-data-grid-pro';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { UpdateOfferContext } from '../../context/UpdateOfferContext';
import { ParsedOffer, useMyOffers } from '../../hooks/useMyOffers';
import { UpdateOfferModal } from '../UpdateOfferModal';
import { MyOffersActionsMenu } from '../MyOffersActionsMenu';
import { RemoveOfferModal } from '../RemoveOfferModal';
import { Offer } from '@common/types/Offer';
import { renderYieldDescription } from '@common/logic/renderYieldDescription';
import { parseYieldColumn } from '@common/logic/parseYieldColumn';
import { AgioColumn } from '../AgioColumn';
import { IssuersList } from '../../../purchase-intent/list-purchase-intents/components/IssuersList/IssuersList';
import { formatIndexer } from '@common/logic/formatIndexer';

const columns: GridColDef[] = [
  {
    field: 'createdAt',
    headerName: 'Criado em',
    valueFormatter: (params: GridValueFormatterParams<Date>) =>
      formatDate(params.value),
    type: 'date',
  },
  {
    field: 'cetipCode',
    headerName: 'CETIP',
    minWidth: 120,
    hide: true,
  },
  {
    field: 'ownerAccountNumber',
    headerName: 'Cliente',
    minWidth: 120,
  },
  {
    field: 'productKind',
    headerName: 'Produto',
  },
  {
    field: 'issuer',
    headerName: 'Emissor',
    renderCell: (params: GridRenderCellParams) => (
      <IssuersList issuers={[params.value]} />
    ),
  },
  {
    field: 'applicationDate',
    headerName: 'Data de aplicação',
    valueFormatter: (params: GridValueFormatterParams<Date>) =>
      formatDate(params.value),
    type: 'date',
    hide: true,
  },
  {
    field: 'maturityDate',
    headerName: 'Vencimento',
    valueFormatter: (params: GridValueFormatterParams<Date>) =>
      formatDate(params.value),
    type: 'date',
  },
  {
    field: 'indexer',
    headerName: 'Indexador',
    valueFormatter: (params: GridValueFormatterParams) =>
      formatIndexer(params.value),
    minWidth: 120,
    hide: true,
  },
  {
    field: 'yieldRate',
    headerName: 'Taxa original',
    type: 'number',
    renderCell: renderYieldDescription,
    valueParser: parseYieldColumn,
    hide: true,
  },
  {
    field: 'rebuyYieldRate',
    headerName: 'Taxa recompra',
    type: 'number',
    renderCell: renderYieldDescription,
    valueParser: parseYieldColumn,
  },
  {
    field: 'resellYieldRate',
    headerName: 'Taxa revenda',
    type: 'number',
    renderCell: renderYieldDescription,
    valueParser: parseYieldColumn,
    hide: true,
  },
  {
    field: 'applicationDateUnitPrice',
    headerName: 'PU original',
    valueFormatter: (params: GridValueFormatterParams<number>) =>
      formatReais(params.value),
    type: 'number',
    hide: true,
  },
  {
    field: 'rebuyUnitPrice',
    headerName: 'PU recompra',
    valueFormatter: (params: GridValueFormatterParams<number>) =>
      formatReais(params.value),
    type: 'number',
  },
  {
    field: 'resellUnitPrice',
    headerName: 'PU revenda',
    valueFormatter: (params: GridValueFormatterParams<number>) =>
      formatReais(params.value),
    type: 'number',
    hide: true,
  },
  {
    field: 'quantityProgress',
    headerName: 'Quantidade',
    minWidth: 150,
    type: 'number',
  },
  {
    field: 'rebuyPosition',
    headerName: 'Total recompra',
    flex: 1,
    valueFormatter: (params: GridValueFormatterParams<number>) =>
      formatReais(params.value),
    type: 'number',
  },
  {
    field: 'resellPosition',
    headerName: 'Total revenda',
    hide: true,
    valueFormatter: (params: GridValueFormatterParams<number>) =>
      formatReais(params.value),
    type: 'number',
  },
  {
    field: 'agio',
    headerName: 'Ágio',
    type: 'number',
    hide: true,
    renderCell: (params: GridRenderCellParams) => (
      <AgioColumn agio={params.value} />
    ),
  },
  {
    field: 'actions',
    headerName: 'Ações',
    align: 'right',
    renderCell: (params: GridRenderCellParams) => (
      <MyOffersActionsMenu offer={params.row} />
    ),
  },
];

const offerToRow = (offer: ParsedOffer): Partial<Offer> => ({
  id: offer.id,
  cetipCode: offer.cetipCode,
  version: offer.version,
  createdAt: offer.createdAt,
  ownerAccountNumber: offer.ownerAccountNumber,
  quantityProgress: `${offer.availableQuantity}/${offer.quantity}`,
  currentPosition: offer.currentPosition,
  rebuyPosition: offer.rebuyPosition,
  resellPosition: offer.resellPosition,
  applicationDateUnitPrice: offer.applicationDateUnitPrice,
  rebuyUnitPrice: offer.rebuyUnitPrice,
  resellUnitPrice: offer.resellUnitPrice,
  productKind: offer.productKind,
  issuer: offer.issuer.name,
  indexer: offer.indexer,
  maturityDate: offer.maturityDate,
  yieldRate: offer.yieldRate,
  rebuyYieldRate: offer.rebuyYieldRate,
  resellYieldRate: offer.resellYieldRate,
  applicationDate: offer.applicationDate,
  agio: offer.rebuyPosition - offer.currentPosition,
});

export const MyOffersList: React.FC = () => {
  const navigate = useNavigate();
  const { offers, loading, error } = useMyOffers();

  const rows = useMemo(() => {
    return offers?.map(offerToRow) ?? [];
  }, [offers]);

  if (error) {
    return <DisplayError error={error} />;
  }

  return (
    <SelectedOfferContext.Provider>
      <UpdateOfferContext.Provider>
        <RemoveOfferModal />
        <UpdateOfferModal />
        <PageHeader
          title="Publique uma oferta de venda na plataforma."
          loading={loading}
          buttonProps={{
            label: 'Cadastrar oferta',
            minWidth: 165,
            onClick: () => {
              navigate('/offers/create');
            },
          }}
        />

        <DataGrid
          rows={rows}
          columns={columns}
          disableSelectionOnClick
          autoHeight
          loading={loading}
        />
      </UpdateOfferContext.Provider>
    </SelectedOfferContext.Provider>
  );
};
