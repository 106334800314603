import Scrollbar from '@animatrix/components/Scrollbar';
import { Box, Checkbox, FormControlLabel, Paper } from '@mui/material';
import React from 'react';
import { BookList } from './components/BookList';
import { IBookContainerProps } from './interface';
import { Typography } from '@animatrix/components/Typography';

export const BookContainer: React.FC<IBookContainerProps> = ({
  title,
  subtitle,
  loading,
  items,
  emptyStateMessage,
  filter,
  onItemClick,
  itemButtonText,
  disabledButton,
}) => {
  return (
    <Paper
      elevation={8}
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        padding: 2,
        borderRadius: 2,
      }}
    >
      <Box mb={2}>
        <Typography variant="h6" mb={1}>
          {title}
        </Typography>
        {subtitle && <Typography variant="body2">{subtitle}</Typography>}
      </Box>
      <Box flexGrow={1} sx={{ height: 0, overflow: 'visible' }}>
        <Scrollbar>
          <BookList
            loading={loading}
            items={items}
            emptyStateMessage={emptyStateMessage}
            onItemClick={onItemClick}
            itemButtonText={itemButtonText}
            disabledButton={disabledButton}
          />
        </Scrollbar>
      </Box>

      {filter && (
        <Box pt={1}>
          <FormControlLabel
            control={
              <Checkbox
                checked={filter.isFiltered}
                onChange={(_, v) => filter.setIsFiltered(v)}
              />
            }
            label={
              'Filtrar apenas resultados compatíveis com emissor, produto e vencimento do meu ativo.'
            }
          />
        </Box>
      )}
    </Paper>
  );
};
