import { DataGrid } from '@animatrix/components/DataGrid';
import { DisplayError } from '@animatrix/components/DisplayError';
import { useAvailableOffers } from '@book/list-offers/hooks/useAvailableOffers';
import { PageHeader } from '@common/components/PageHeader';
import { formatDate } from '@common/date/formatters';
import { formatIndexer } from '@common/logic/formatIndexer';
import { parseYieldColumn } from '@common/logic/parseYieldColumn';
import { renderYieldDescription } from '@common/logic/renderYieldDescription';
import { formatReais } from '@invest-ai/common';
import { LinearProgress, Typography } from '@mui/material';
import {
  GridColDef,
  GridRenderCellParams,
  GridValueFormatterParams,
} from '@mui/x-data-grid-pro';
import { useMemo } from 'react';
import { IssuersList } from '../../../../purchase-intent/list-purchase-intents/components/IssuersList/IssuersList';
import { OpenPlaceOrderModalButton } from '../OpenPlaceOrderModalButton';
import { PlaceOrderModal } from '../PlaceOrderModal';
import { publicOfferToRow } from './logic/publicOfferToRow';

const columns: GridColDef[] = [
  {
    field: 'createdAt',
    headerName: 'Criado em',
    hide: true,
    valueFormatter: (params: GridValueFormatterParams<Date>) =>
      formatDate(params.value),
  },
  {
    field: 'productKind',
    headerName: 'Produto',
  },
  {
    field: 'issuer',
    headerName: 'Emissor',
    flex: 1,
    minWidth: 100,
    renderCell: (params: GridRenderCellParams) => (
      <IssuersList issuers={[params.value]} />
    ),
  },
  {
    field: 'maturityDate',
    headerName: 'Vencimento',
    valueFormatter: (params: GridValueFormatterParams<Date>) =>
      formatDate(params.value),
  },
  {
    field: 'indexer',
    headerName: 'Indexador',
    valueFormatter: (v) => formatIndexer(v.value),
  },
  {
    field: 'yield',
    headerName: 'Taxa',
    minWidth: 120,
    type: 'number',
    renderCell: renderYieldDescription,
    valueParser: parseYieldColumn,
  },
  {
    field: 'unitPrice',
    headerName: 'Preço Unitário',
    valueFormatter: (params: GridValueFormatterParams) =>
      formatReais(params.value),
  },
  {
    field: 'quantity',
    headerName: 'Quantidades',
    renderCell: ({
      value,
    }: GridRenderCellParams<{ available: number; total: number }>) => {
      if (!value) {
        return null;
      }
      return (
        <DisplayOfferQuantity available={value.available} total={value.total} />
      );
    },
  },
  {
    field: 'amountAvailable',
    headerName: 'Total disponível',
    width: 150,
    valueFormatter: (params: GridValueFormatterParams) =>
      formatReais(params.value),
  },
  {
    field: 'actions',
    headerName: 'Ações',
    renderCell: (params: GridRenderCellParams<Date>) => (
      <OpenPlaceOrderModalButton
        offerId={params.row.id}
        offerVersion={params.row.version}
        availableQuantity={params.row.quantity.available}
      />
    ),
  },
];

const DisplayOfferQuantity: React.FC<{
  available: number;
  total: number;
}> = ({ available, total }) => {
  if (available === 0) {
    return (
      <Typography
        color="textSecondary"
        variant="caption"
        component="div"
        textAlign="center"
        style={{ width: '100%' }}
      >
        Esgotado!
      </Typography>
    );
  }
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Typography
        textAlign="center"
        variant="caption"
        component="div"
        color="textSecondary"
        style={{ marginBottom: 4 }}
      >
        {available} / {total}
      </Typography>
      <LinearProgress
        variant="determinate"
        value={(available / total) * 100}
        style={{ width: '100%' }}
      />
    </div>
  );
};

export const OfferList: React.FC = () => {
  const { offers, loading, error } = useAvailableOffers();

  const rows = useMemo(() => offers?.map(publicOfferToRow) ?? [], [offers]);

  if (error) {
    return <DisplayError error={error} />;
  }

  return (
    <>
      <PlaceOrderModal />
      <PageHeader
        title="Veja o que está sendo vendido na plataforma agora."
        loading={loading}
      />
      <DataGrid
        rows={rows}
        columns={columns}
        loading={loading}
        disableSelectionOnClick
        autoHeight
      />
    </>
  );
};
