import { Label } from '@animatrix/components/Label';
import { Box, Tooltip } from '@mui/material';
import { splitAt } from 'ramda';
import { IssuersListProps } from './interface';
import { getVisibleItemsCount } from './logic';

export const IssuersList: React.FC<IssuersListProps> = ({ issuers }) => {
  if (!issuers.length) {
    return <Label>Todos</Label>;
  }

  const visibleIssuersCount = getVisibleItemsCount(issuers);

  const [visibleIssuers, hiddenIssuers] = splitAt(visibleIssuersCount, issuers);
  const showMoreLabel = hiddenIssuers.length > 0;

  return (
    <Box display="flex" flexDirection="row" flexWrap="wrap" gap={0.5}>
      {visibleIssuers.map((issuer, i) => (
        <Label key={issuer + i}>{issuer}</Label>
      ))}

      {showMoreLabel ? (
        <Tooltip arrow placement="top" title={hiddenIssuers.join(', ')}>
          <div>
            <Label>E mais {hiddenIssuers.length}</Label>
          </div>
        </Tooltip>
      ) : null}
    </Box>
  );
};
