import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import {
  SignInEmailInput,
  SignInMutation,
  SignInMutationVariables,
} from '../../../__generated__/types';

export const SIGN_IN_MUTATION = gql`
  mutation SignIn($input: SignInEmailInput!) {
    signIn: signInEmail(input: $input) {
      account {
        id
        email
        user {
          __typename
          id
          hasBot
        }
      }
      newUser
      tokens {
        accessToken
        refreshToken
      }
    }
  }
`;

export const useSignIn = () => {
  const [_signIn, { data, loading, error }] = useMutation<
    SignInMutation,
    SignInMutationVariables
  >(SIGN_IN_MUTATION);

  const signIn = (input: SignInEmailInput) => {
    return _signIn({
      variables: {
        input,
      },
    });
  };
  return {
    signIn,
    data,
    loading,
    error,
  };
};
