import { Offer } from '@common/types/Offer';
import { useState } from 'react';
import { createContainer } from 'unstated-next';

export const UpdateOfferContext = createContainer(() => {
  const [offerToUpdate, setOfferToUpdate] = useState<Offer | null>(null);

  return {
    offerToUpdate,
    setOfferToUpdate,
  };
});
