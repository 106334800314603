import { Indexers } from '../../../__generated__/types';

export const formatIndexer = (indexer: Indexers): string => {
  const map: Record<string, string> = {
    [Indexers.PREFIXADO]: 'Prefixado',
    [Indexers.CDI]: 'Pós-fixado',
  };

  return map[indexer] ?? indexer;
};
