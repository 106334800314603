import {
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
} from '@mui/material';
import React from 'react';
import { Button } from '../Button';

export interface IConfirmationModalProps {
  open: boolean;
  onClose: () => void;
  onCancel: () => void;
  onConfirm: () => void;
  cancelText?: string;
  confirmText?: string;
  cancelColor?: ButtonProps['color'];
  confirmColor?: ButtonProps['color'];
  loading?: boolean;
  children?: React.ReactNode;
}

export const ConfirmationModal: React.FC<IConfirmationModalProps> = ({
  open,
  onClose,
  onCancel,
  onConfirm,
  cancelText = 'Cancelar',
  confirmText = 'Confirmar',
  confirmColor = 'primary',
  cancelColor = 'inherit',
  loading = false,
  children,
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color={cancelColor} disabled={loading}>
          {cancelText}
        </Button>
        <Button
          onClick={onConfirm}
          color={confirmColor}
          variant="contained"
          loading={loading}
          disabled={loading}
        >
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
