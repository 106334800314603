// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_DASHBOARD = '/';
const LANDING_ROOT = 'https://invest-ai.com.br/';
// ----------------------------------------------------------------------

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  app: {
    sell: path(ROOTS_DASHBOARD, 'offers'),
    createOffer: path(ROOTS_DASHBOARD, 'offers/create'),
    myOffers: path(ROOTS_DASHBOARD, 'offers/my-offers'),
    purchaseIntents: path(ROOTS_DASHBOARD, 'offers/purchase-intents'),
    purchase: path(ROOTS_DASHBOARD, 'purchase'),
    myPurchaseIntents: path(ROOTS_DASHBOARD, 'purchase/my-purchase-intents'),
    createBrokerAccount: path(ROOTS_DASHBOARD, 'customers/create'),
    createPurchaseIntent: path(ROOTS_DASHBOARD, 'purchase-intents/create'),
    buy: path(ROOTS_DASHBOARD, 'buy'),
    myOrders: path(ROOTS_DASHBOARD, 'my-orders'),
    myOrdersSold: path(ROOTS_DASHBOARD, 'my-orders/sold'),
    myOrdersPurchased: path(ROOTS_DASHBOARD, 'my-orders/purchased'),
    brokerAccounts: path(ROOTS_DASHBOARD, 'customers'),
    transactions: path(ROOTS_DASHBOARD, 'transactions'),
    book: path(ROOTS_DASHBOARD, 'book'),
    login: path(ROOTS_DASHBOARD, 'login'),
    forgotPassword: path(ROOTS_DASHBOARD, 'recuperar-senha'),
    resetPassword: path(ROOTS_DASHBOARD, 'nova-senha'),
  },
};
