import Scrollbar from '@animatrix/components/Scrollbar';
import { getEllipsisImageByPath } from '@login/logic/getEllipsisImageByPath';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useLocation } from 'react-router';
import { Container, Content, Ellipsis, Root } from './style';

const useStyles = makeStyles<Theme>((theme) => ({
  content: {
    marginTop: 24,
  },
  container: {
    marginTop: 70,
    [theme.breakpoints.down('md')]: {
      marginTop: 0,
    },
  },
}));

export const LoginLayout: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const { pathname } = useLocation();
  const ellipsisImg = getEllipsisImageByPath(pathname);
  const classes = useStyles();

  return (
    <Root>
      <Container className={classes.container}>
        <Scrollbar>
          <Content className={classes.content}>{children}</Content>
        </Scrollbar>
      </Container>
      <Ellipsis src={ellipsisImg} />
    </Root>
  );
};
