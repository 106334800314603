import { DisplayError } from '@animatrix/components/DisplayError';
import { formatDate } from '@common/date/formatters';
import {
  GridColDef,
  GridRenderCellParams,
  GridValueFormatterParams,
} from '@mui/x-data-grid-pro';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { formatIndexer } from '@common/logic/formatIndexer';
import { PATH_DASHBOARD } from '../../../../routes/paths';
import {
  PurchaseIntent,
  useMyPurchaseIntents,
} from '../../hooks/useMyPurchaseIntents';
import { PurchasedAndMaximum } from '../PurchasedAndMaximum';
import { MyPurchaseIntentsActionsMenu } from '../MyPurchaseIntentsActionsMenu';
import { SelectedPurchaseIntentContext } from '../../context/SelectedPurchaseIntentContext';
import { RemovePurchaseIntentModal } from '../RemovePurchaseIntentModal';
import { PageHeader } from '@common/components/PageHeader';
import { DataGrid } from '@animatrix/components/DataGrid';
import { IssuersList } from '../IssuersList/IssuersList';
import { renderYieldDescription } from '@common/logic/renderYieldDescription';

export const columns: GridColDef[] = [
  {
    field: 'createdAt',
    headerName: 'Data de criação',
    hide: true,
    valueFormatter: (params: GridValueFormatterParams<Date>) =>
      formatDate(params.value),
  },
  {
    field: 'accountNumber',
    headerName: 'Comprador',
    minWidth: 120,
  },
  {
    field: 'interestingProducts',
    headerName: 'Produtos',
    minWidth: 150,
  },
  {
    field: 'issuers',
    headerName: 'Emissores',
    minWidth: 160,
    flex: 1,
    renderCell: (params: GridRenderCellParams) => (
      <IssuersList issuers={params.value} />
    ),
  },
  {
    field: 'maturityDateMin',
    headerName: 'Venc. mínimo',
    minWidth: 120,
    valueFormatter: (params: GridValueFormatterParams<Date>) =>
      formatDate(params.value),
  },
  {
    field: 'maturityDateMax',
    headerName: 'Venc. máximo',
    minWidth: 120,
    valueFormatter: (params: GridValueFormatterParams<Date>) =>
      formatDate(params.value),
  },
  {
    field: 'indexer',
    headerName: 'Indexador',
    hide: true,
    valueFormatter: (params: GridValueFormatterParams) =>
      formatIndexer(params.value),
  },
  {
    field: 'yieldRateMin',
    headerName: 'Taxa mínima',
    minWidth: 120,
    type: 'number',
    renderCell: renderYieldDescription,
  },
  {
    field: 'amount',
    headerName: 'Valor',
    minWidth: 120,
    renderCell: (cellValue) => {
      return (
        <PurchasedAndMaximum
          purchased={cellValue.value.purchased}
          maximum={cellValue.value.maximum}
        />
      );
    },
  },
  {
    field: 'updatedAt',
    headerName: 'Última atualização',
    hide: true,
    valueFormatter: (params: GridValueFormatterParams<Date>) =>
      formatDate(params.value),
  },
  {
    field: 'actions',
    headerName: 'Ações',
    align: 'right',
    renderCell: (params: GridRenderCellParams) => (
      <MyPurchaseIntentsActionsMenu purchaseIntentId={params.row.id} />
    ),
  },
];

const purchaseIntentToRow = (purchaseIntent: PurchaseIntent) => ({
  ...purchaseIntent,
  issuers: purchaseIntent.interestingBanks?.map(({ name }) => name) ?? [],
  amount: {
    purchased: purchaseIntent.totalPurchased,
    maximum: purchaseIntent.limit,
  },
});

export const MyPurchaseIntentsDashboard: React.FC = () => {
  const { purchaseIntents, loading, error } = useMyPurchaseIntents();
  const navigate = useNavigate();

  const rows = useMemo(() => {
    return purchaseIntents?.map(purchaseIntentToRow) ?? [];
  }, [purchaseIntents]);

  if (error) {
    return <DisplayError error={error} />;
  }

  return (
    <SelectedPurchaseIntentContext.Provider>
      <RemovePurchaseIntentModal />
      <PageHeader
        title="Cadastre e gerencia suas intenções de compra."
        loading={loading}
        buttonProps={{
          label: 'Cadastrar Intenção de Compra',
          minWidth: 240,
          onClick: () => {
            navigate(PATH_DASHBOARD.app.createPurchaseIntent);
          },
        }}
      />

      <DataGrid
        rows={rows}
        columns={columns}
        disableSelectionOnClick
        autoHeight
        rowHeight={80}
        loading={loading}
      />
    </SelectedPurchaseIntentContext.Provider>
  );
};
