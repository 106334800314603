import { formatPercentage, formatReais } from '@invest-ai/common';
import BigNumber from 'bignumber.js';
import { isValid, startOfToday } from 'date-fns';
import { add } from 'date-fns/esm';
import {
  MINIMUM_APPLICATION_DATE_UNIT_PRICE,
  MINIMUM_QUANTITY,
} from './constants';

export const getValidationFunctions = (getValues: () => any) => ({
  quantity: (v: number) => {
    if (v < MINIMUM_QUANTITY) {
      return `Quantidade precisa ser igual ou maior que ${MINIMUM_QUANTITY}.`;
    }
    return true;
  },
  applicationDate: (applicationDate: Date) => {
    if (!isValid(applicationDate)) {
      return false;
    }
    // We had to do this because material-ui TextField is computing timezone twice in its Date when in DatePicker mode
    const applicationDateUTC = add(applicationDate, {
      minutes: new Date().getTimezoneOffset(),
    });
    const now = startOfToday();
    if (applicationDateUTC >= now) {
      return 'Data de aplicação não pode ser maior ou igual que a data atual.';
    }
    return true;
  },
  maturityDate: (maturityDate: Date) => {
    if (!isValid(maturityDate)) {
      return false;
    }
    // We had to do this because material-ui TextField is computing timezone twice in its Date when in DatePicker mode
    const maturityDateUTC = add(maturityDate, {
      minutes: new Date().getTimezoneOffset(),
    });
    const now = startOfToday();
    if (maturityDateUTC <= now) {
      return 'Data de vencimento não pode ser menor ou igual que a data atual.';
    }
    const { applicationDate } = getValues();
    if (maturityDate <= applicationDate) {
      return 'Data de vencimento deve ser maior que a data de aplicação.';
    }

    return true;
  },
  yieldRate: (v: number, minimumYieldRate: number) => {
    if (v < minimumYieldRate) {
      return `A taxa precisa ser igual ou maior que ${formatPercentage(
        minimumYieldRate,
      )}`;
    }
  },
  applicationDateUnitPrice: (v: number) => {
    if (v < MINIMUM_APPLICATION_DATE_UNIT_PRICE) {
      return `O PU precisa ser igual ou maior que ${formatReais(
        MINIMUM_APPLICATION_DATE_UNIT_PRICE,
      )}.`;
    }
    return true;
  },
  rebuyYieldRate: (v: number, spread: number, minimumYieldRate: number) => {
    const minimumYield = new BigNumber(minimumYieldRate)
      .plus(spread)
      .toNumber();
    if (v < minimumYield) {
      return `A taxa precisa ser igual ou maior que ${formatPercentage(
        minimumYield,
      )}.`;
    }
    return true;
  },
  resellYieldRate: (v: number, minimumYieldRate: number) => {
    if (v < minimumYieldRate) {
      return `A taxa precisa ser igual ou maior que ${formatPercentage(
        minimumYieldRate,
      )}.`;
    }
    return true;
  },
});
