import { ApolloError } from '@apollo/client';
import React, { CSSProperties } from 'react';
import { DisplayMessage } from '../DisplayMessage';
import { getErrorMessage } from './logic/getErrorMessage';

export const DisplayError: React.FC<{
  error?: ApolloError | Error | null;
  className?: string;
  style?: CSSProperties;
}> = ({ error, style, className }) => {
  if (!error) {
    return null;
  }

  const message = getErrorMessage(error);
  return (
    <DisplayMessage severity="error" style={style} className={className}>
      {message}
    </DisplayMessage>
  );
};
