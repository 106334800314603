// routes
// components
import { SvgIconStyle } from '@animatrix/components/SvgIconStyle';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import HandshakeIcon from '@mui/icons-material/Handshake';
import PersonIcon from '@mui/icons-material/Person';
import SellIcon from '@mui/icons-material/Sell';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { PATH_DASHBOARD } from '../../../routes/paths';
// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle
    src={`/static/icons/navbar/${name}.svg`}
    sx={{ width: '100%', height: '100%' }}
  />
);

const ICONS = {
  user: getIcon('ic_user'),
  logout: <ExitToAppIcon />,
  sell: <SellIcon />,
  buy: <ShoppingCartIcon />,
  orders: <HandshakeIcon />,
  customers: <PersonIcon />,
};

export const PAGE_TITLES = {
  sell: 'Vender ativos',
  purchase: 'Comprar ativos',
  myOrdersPurchased: 'Minhas ordens',
  customers: 'Meus clientes',
  addOffer: 'Cadastrar oferta',
  addOrder: 'Cadastrar intenção de compra',
  addCustomer: 'Cadastrar cliente',
};

export const appSidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'Investimentos',
    items: [
      {
        title: PAGE_TITLES.sell,
        path: PATH_DASHBOARD.app.sell,
        icon: ICONS.sell,
        exactPath: false,
      },
      {
        title: PAGE_TITLES.purchase,
        path: PATH_DASHBOARD.app.purchase,
        icon: ICONS.buy,
        exactPath: false,
      },

      {
        title: PAGE_TITLES.myOrdersPurchased,
        path: PATH_DASHBOARD.app.myOrders,
        icon: ICONS.orders,
        exactPath: false,
      },
      {
        title: PAGE_TITLES.customers,
        path: PATH_DASHBOARD.app.brokerAccounts,
        icon: ICONS.customers,
      },
    ],
  },
  {
    subheader: 'Conta',
    items: [
      {
        title: 'Sair',
        path: '/logout',
        icon: ICONS.logout,
      },
    ],
  },
];
