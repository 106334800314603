import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
interface IModalProps {
  open: boolean;
  title: string;
  onClose: () => void;
  children: React.ReactNode;
  Actions?: React.ComponentType;
}

const MODAL_WIDTH = 560;

export const Modal: React.FC<IModalProps> = ({
  open,
  title,
  onClose,
  children,
  Actions,
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle sx={{ py: 3, px: 2 }}>{title}</DialogTitle>
      <DialogContent sx={{ '&&': { p: 2 }, width: MODAL_WIDTH }}>
        {children}
      </DialogContent>
      {Actions && (
        <DialogActions
          sx={{ borderTop: '1px solid', borderTopColor: 'divider' }}
        >
          <Actions />
        </DialogActions>
      )}
    </Dialog>
  );
};
