import { InputAdornment, TextField } from '@mui/material';

interface IQuantityInputProps {
  label: string;
  value: number;
  onChange: (value?: number) => void;
  error?: boolean;
  helperText?: string;
}

export const QuantityInput: React.FC<IQuantityInputProps> = ({
  label,
  value,
  onChange,
  error,
  helperText,
}) => {
  const min = 0;

  return (
    <TextField
      label={label}
      value={value}
      variant="outlined"
      InputLabelProps={{ shrink: true }}
      onChange={(event) => {
        const newValue = parseInt(event.target.value);
        if (isNaN(newValue)) return onChange(min);
        onChange(newValue);
      }}
      error={error}
      helperText={helperText}
    />
  );
};
