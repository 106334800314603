import { gql, useQuery } from '@apollo/client';
import { AllBanksQuery } from '../../../../__generated__/types';

export const ALL_BANKS_QUERY = gql`
  query AllBanks {
    allBanks {
      id
      name
    }
  }
`;

export const useAllBanks = () => {
  const { data, loading, error } = useQuery<AllBanksQuery>(ALL_BANKS_QUERY);
  const banks = data?.allBanks ?? [];
  const sortBy = 'name';
  const sortedBanks = [...banks].sort((a, b) =>
    a[sortBy] > b[sortBy] ? 1 : b[sortBy] > a[sortBy] ? -1 : 0,
  );
  const allBanks = sortedBanks
    .filter(({ name }) => !!name)
    .map(({ id, name }) => {
      return { label: name, value: id };
    });

  return {
    allBanks,
    loading,
    error,
  };
};
