import { startOfTomorrow, startOfYesterday } from 'date-fns';
import { Indexers, ProductKind } from '../../../../__generated__/types';
import { ICreateOfferFormValues } from './interfaces/ICreateOfferFormValues';

export const MINIMUM_APPLICATION_DATE_UNIT_PRICE = 0.1;
export const MINIMUM_QUANTITY = 1;

export const DEFAULT_CREATE_OFFER_FORM_VALUES: Partial<ICreateOfferFormValues> =
  {
    yieldRate: 0,
    applicationDateUnitPrice: 0,
    rebuyYieldRate: 0,
    indexer: Indexers.PREFIXADO,
    productKind: ProductKind.CDB,
    applicationDate: startOfYesterday(),
    maturityDate: startOfTomorrow(),
  };
