import { AppEvents } from '@analytics/enum/AppEvents';
import { useTracker } from '@analytics/hooks/useTracker';
import { Button } from '@animatrix/components/Button';
import { DisplayError } from '@animatrix/components/DisplayError';
import { PasswordInput } from '@animatrix/components/PasswordInput';
import { TextField } from '@animatrix/components/TextField';
import { ApolloError } from '@apollo/client';
import { Link } from '@login/components/Link';
import { useEmailSignIn } from '@login/hooks/useEmailSignIn';
import { useTokens } from '@login/hooks/useTokens';
import { handleKnownErrors } from '@login/logic/handleKnownErrors';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FormEventHandler, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { SignInMethod, SignInMutation } from '../../../../__generated__/types';
import { PATH_DASHBOARD } from '../../../routes/paths';
import { Title } from '../Title';
import { classNames, Form } from './style';

export const useStyles = makeStyles<Theme>((theme: Theme) => ({
  title: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(1),
    },
  },
}));

export const SignIn: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const tracker = useTracker();
  const { setToken } = useTokens();
  const {
    emailSignIn,
    loading: loadingEmailSignIn,
    error: errorEmailSignIn,
  } = useEmailSignIn();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    tracker.trackEvent(AppEvents.ViewSignIn);
  }, []);

  const handleSignInResponse = (data: SignInMutation, method: SignInMethod) => {
    const id = data.signIn.account.id;
    const accessToken = data.signIn.tokens.accessToken;
    const { newUser } = data.signIn;

    tracker.setUser(id, email);
    tracker.trackEvent(
      newUser ? AppEvents.SignUpComplete : AppEvents.SignInComplete,
      { method },
    );
    setToken(accessToken);
    navigate(PATH_DASHBOARD.app.sell);
  };

  const performEmailSignIn: FormEventHandler = async (e) => {
    try {
      e.preventDefault();
      const { data } = await emailSignIn(email, password);

      if (data) {
        handleSignInResponse(data, SignInMethod.EMAIL);
      }
    } catch (err) {
      const isApolloError = err instanceof ApolloError;
      if (!isApolloError) {
        toast.error('Algo deu errado. Por favor, tente novamente mais tarde.');
      }

      throw err;
    }
  };

  const isValid = email && password;

  return (
    <Form onSubmit={performEmailSignIn}>
      <Title className={classes.title}>Acesse sua conta</Title>
      <TextField
        label="E-mail"
        value={email}
        className={classNames.email}
        onChange={(e) => setEmail(e.target.value)}
        InputProps={{
          type: 'email',
          autoCapitalize: 'none',
        }}
      />
      <PasswordInput
        value={password}
        onChange={setPassword}
        className={classNames.password}
      />
      <div className={classNames.forgotPassword}>
        <Link to={PATH_DASHBOARD.app.forgotPassword}>Esqueci minha senha</Link>
      </div>
      <DisplayError error={errorEmailSignIn} className={classNames.error} />
      <Button
        fullWidth
        variant="contained"
        disabled={loadingEmailSignIn || !isValid}
        size="large"
        type="submit"
      >
        Login
      </Button>
    </Form>
  );
};
