import { Indexers } from '../../../__generated__/types';

export const getIndexerParams = (indexer: Indexers) => {
  const suffix: Record<string, string> = {
    [Indexers.PREFIXADO]: '% a.a.',
    [Indexers.CDI]: '% CDI',
  };

  if (!suffix[indexer]) {
    throw new Error('Indexador inválido.');
  }

  return {
    yieldSuffix: suffix[indexer],
  };
};
