import { formatDate } from '@common/date/formatters';
import { Indexers, ProductKind } from '../../../__generated__/types';
import { ptBR } from 'date-fns/locale';
import { formatIndexer } from '@common/logic/formatIndexer';

interface IFormatOfferDescription {
  issuer: string;
  maturityDate: Date;
  productKind: ProductKind;
  indexer: Indexers;
}

export const formatOfferDescription = ({
  issuer,
  maturityDate,
  productKind,
  indexer,
}: IFormatOfferDescription): string => {
  const formattedMaturityDate = formatDate(
    maturityDate,
    'MMM/yyyy',
    ptBR,
  ).toUpperCase();

  const formattedIndexer = formatIndexer(indexer);

  return `${productKind} ${issuer} ${formattedIndexer} - ${formattedMaturityDate}`;
};
