import { SelectOption } from '@common/components/EasySelect';
import { ProductKind } from '../../../__generated__/types';

export const PRODUCT_OPTIONS: SelectOption<ProductKind>[] = [
  { label: 'CDB', value: ProductKind.CDB },
  { label: 'LCI', value: ProductKind.LCI },
  { label: 'LCA', value: ProductKind.LCA },
  { label: 'LF', value: ProductKind.LF },
  { label: 'LC', value: ProductKind.LC },
];
