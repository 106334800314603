import { Page } from '@animatrix/components/Page';
import { Container } from '@mui/material';
import { PurchaseDashboard } from '../../components/PurchaseDashboard';

export const PurchasePage: React.FC = () => {
  return (
    <Page title="InvestAI | Comprar Ativos">
      <Container maxWidth={'xl'}>
        <PurchaseDashboard />
      </Container>
    </Page>
  );
};
