import { Button } from '@animatrix/components/Button';
import { Modal } from '@animatrix/components/Modal';
import { MoneyInput } from '@animatrix/components/MoneyInput';
import { QuantityInput } from '@animatrix/components/QuantityInput';
import { PurchaseIntent } from '@common/types/PurchaseIntent';
import { useGetUnitPrice } from '@create-offer/hooks/useGetUnitPrice';
import { useSellToPurchaseIntent } from '@create-offer/hooks/useSellToPurchaseIntent';
import { formatReais } from '@invest-ai/common';
import { Box, Grid, Skeleton } from '@mui/material';
import { useEffect, useState } from 'react';
import { Controller, useForm, useFormContext } from 'react-hook-form';
import { ICreateOfferFormValues } from '../CreateOfferForm/interfaces/ICreateOfferFormValues';
import { PurchaseIntentSummary } from '../PurchaseIntentSummary';
import { getValidationFunctions } from './getValidationFunctions';
import { ISellToPurchaseIntentFormValues } from './interfaces/ISellToPurchaseIntentFormValues';
import { Indexers, ProductKind } from '../../../../__generated__/types';
import { startOfTomorrow } from 'date-fns';
import { getYieldRatePropsByIndexer } from '@create-offer/logic/getYieldRatePropsByIndexer';
import { toast } from 'react-toastify';
import { TextField } from '@animatrix/components/TextField';
import { Typography } from '@animatrix/components/Typography';

interface ISellToPurchaseIntentModalProps {
  onClose: () => void;
  purchaseIntent?: PurchaseIntent;
}

export const SellToPurchaseIntentModal: React.FC<
  ISellToPurchaseIntentModalProps
> = ({ purchaseIntent, onClose }) => {
  const {
    setValue: setCreateOfferFormValue,
    getValues: getCreateOfferFormValues,
  } = useFormContext<ICreateOfferFormValues>();

  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
    watch,
    resetField,
    trigger,
  } = useForm<ISellToPurchaseIntentFormValues>({
    mode: 'onChange',
  });

  const {
    brokerAccount,
    quantity: maxQuantity,
    productKind,
    issuer,
    cetipCode,
    indexer,
    applicationDate,
    applicationDateUnitPrice,
    maturityDate,
    yieldRate,
    rebuyYieldRate,
    resellYieldRate,
  } = getCreateOfferFormValues();

  const {
    referenceIndexerYieldRate,
    referenceYieldRate,
    newIndexerYieldRate: rebuyNewIndexerYieldRate,
    newYieldRate: rebuyNewYieldRate,
  } = getYieldRatePropsByIndexer(
    indexer as Indexers,
    yieldRate,
    rebuyYieldRate,
  );

  const {
    newIndexerYieldRate: resellNewIndexerYieldRate,
    newYieldRate: resellNewYieldRate,
  } = getYieldRatePropsByIndexer(
    indexer as Indexers,
    yieldRate,
    resellYieldRate,
  );

  const { sellToPurchaseIntent, loading: loadingSellToPurchaseIntent } =
    useSellToPurchaseIntent();
  const { unitPrice: rebuyUnitPrice, loading: loadingRebuyUnitPrice } =
    useGetUnitPrice({
      indexer: indexer as Indexers,
      referenceDate: applicationDate,
      referenceUnitPrice: applicationDateUnitPrice,
      referenceIndexerYieldRate,
      referenceYieldRate,
      maturityDate,
      newIndexerYieldRate: rebuyNewIndexerYieldRate,
      newYieldRate: rebuyNewYieldRate,
      newReferenceDate: startOfTomorrow(),
    });

  const { unitPrice: resellUnitPrice, loading: loadingResellUnitPrice } =
    useGetUnitPrice({
      indexer: indexer as Indexers,
      referenceDate: applicationDate,
      referenceUnitPrice: applicationDateUnitPrice,
      referenceIndexerYieldRate,
      referenceYieldRate,
      maturityDate,
      newIndexerYieldRate: resellNewIndexerYieldRate,
      newYieldRate: resellNewYieldRate,
      newReferenceDate: startOfTomorrow(),
    });

  const [quantityToSell] = watch(['quantityToSell']);
  const [amountToSell, setAmountToSell] = useState(0);

  const validationFunctions = getValidationFunctions(
    maxQuantity,
    resellUnitPrice,
    purchaseIntent?.availableLimit ?? 0,
  );

  const closeModal = () => {
    onClose();
    resetField('quantityToSell');
  };

  const onSubmit = async (data: ISellToPurchaseIntentFormValues) => {
    if (!purchaseIntent?.accountNumber || !brokerAccount?.number) return;
    try {
      await sellToPurchaseIntent({
        quantity: data.quantityToSell,
        productKind: productKind as ProductKind,
        issuer: issuer.value,
        cetipCode,
        indexer,
        applicationDate,
        applicationDateUnitPrice,
        maturityDate,
        yieldRate,
        rebuyYieldRate,
        sellerAccountNumber: brokerAccount?.number,
        purchaseIntentId: purchaseIntent.id,
      });

      setCreateOfferFormValue('quantity', maxQuantity - quantityToSell);
      toast.success('Venda realizada com sucesso!');
    } catch (err) {
      if (err instanceof Error) {
        toast.error(err.message);
      }

      throw err;
    } finally {
      closeModal();
    }
  };

  useEffect(() => {
    const invalid = isNaN(rebuyUnitPrice) || isNaN(quantityToSell);
    setAmountToSell(invalid ? 0 : rebuyUnitPrice * quantityToSell);

    trigger('quantityToSell');
  }, [rebuyUnitPrice, quantityToSell]);

  return (
    <Modal
      open={!!purchaseIntent}
      title="Vender para intenção de compra"
      onClose={closeModal}
      Actions={() => (
        <>
          <Button
            variant="text"
            sx={{ color: 'grey.800' }}
            onClick={closeModal}
            disabled={loadingSellToPurchaseIntent}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit(onSubmit)}
            disabled={
              !isValid ||
              loadingSellToPurchaseIntent ||
              loadingRebuyUnitPrice ||
              loadingResellUnitPrice
            }
            loading={loadingSellToPurchaseIntent}
          >
            Confirmar venda
          </Button>
        </>
      )}
    >
      <Grid container spacing={3} mb={3}>
        <Grid item xs={12}>
          <PurchaseIntentSummary purchaseIntent={purchaseIntent} />
        </Grid>
        <Grid item xs={4}>
          {loadingRebuyUnitPrice ? (
            <Skeleton
              variant="rectangular"
              sx={{ width: '100%', height: 56, borderRadius: 1 }}
            />
          ) : (
            <MoneyInput label="Valor do PU" value={rebuyUnitPrice} disabled />
          )}
        </Grid>
        <Grid item xs={4}>
          <TextField
            label="Unidades disponíveis"
            value={maxQuantity}
            disabled
          />
        </Grid>
        <Grid item xs={4}>
          <Controller
            name="quantityToSell"
            control={control}
            rules={{
              required: true,
              validate: validationFunctions.quantityToSell,
            }}
            render={({ field }) => (
              <QuantityInput
                label="Unidades"
                value={field.value}
                onChange={field.onChange}
                error={!!errors.quantityToSell}
                helperText={errors.quantityToSell?.message ?? ''}
              />
            )}
          />
        </Grid>
      </Grid>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h6">Total</Typography>
        <Typography variant="body1">{formatReais(amountToSell)}</Typography>
      </Box>
    </Modal>
  );
};
