import { InputAdornment } from '@mui/material';
import React from 'react';
import BigNumber from 'bignumber.js';
import { TextField } from '../TextField';

export const PercentageInput: React.FC<{
  label: string;
  value: number;
  precision: number;
  onChange?: (value: number) => void;
  fullWidth?: boolean;
  prefix?: string;
  suffix?: string;
  disabled?: boolean;
  helperText?: string;
  error?: boolean;
  loading?: boolean;
}> = ({
  label,
  value,
  precision,
  onChange,
  prefix,
  suffix,
  disabled,
  helperText,
  error,
  loading,
}) => {
  const valueInPercentage = new BigNumber(value || 0)
    .multipliedBy(100)
    .toNumber();

  const stringValue = valueInPercentage?.toLocaleString('pt-BR', {
    maximumFractionDigits: precision,
    minimumFractionDigits: precision,
  });

  return (
    <TextField
      value={stringValue}
      variant="outlined"
      InputProps={{
        disabled,
        startAdornment: prefix && (
          <InputAdornment position="start">{prefix}</InputAdornment>
        ),
        endAdornment: suffix && (
          <InputAdornment position="end">{suffix}</InputAdornment>
        ),
      }}
      label={label}
      InputLabelProps={{
        shrink: true,
      }}
      onChange={(event) => {
        if (!onChange) return;
        const value = event.target.value;
        const pureNumericValue = value.replace(/\D/g, '');
        const floatValue = parseFloat(pureNumericValue);
        if (isNaN(floatValue)) return onChange(0);
        const decimalValue = new BigNumber(parseFloat(pureNumericValue));
        const precisionCoefficient = new BigNumber(10)
          .pow(precision)
          .multipliedBy(100);
        const finalValue = decimalValue.div(precisionCoefficient).toNumber();
        onChange(finalValue);
      }}
      helperText={helperText}
      error={error}
      loading={loading}
    />
  );
};
