import { Indexers } from '../../../__generated__/types';

export const getYieldRatePropsByIndexer = (
  indexer: Indexers,
  referenceYieldRate: number,
  newYieldRate: number,
) => {
  if (indexer === Indexers.PREFIXADO) {
    return {
      referenceIndexerYieldRate: 0,
      referenceYieldRate,
      newIndexerYieldRate: 0,
      newYieldRate,
    };
  }

  if (indexer === Indexers.CDI) {
    return {
      referenceIndexerYieldRate: referenceYieldRate,
      referenceYieldRate: 0,
      newIndexerYieldRate: newYieldRate,
      newYieldRate: 0,
    };
  }

  throw new Error('Indexador não identificado');
};
