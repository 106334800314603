import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client';
import { authLink } from './authLink';
import { httpLink } from './httpLink';
import { errorHandlerLink } from './errorHandlerLink';

// import introspectionResult from './__generated__/graphql.schema.json';
// const schema = buildClientSchema(introspectionResult);
// const typesMap = {
//   Date: {
//     serialize: (parsed: unknown): string | null =>
//       parsed instanceof Date ? parsed.toString() : null,
//     parseValue: (raw: unknown): Date | null => {
//       if (!raw) return null; // if for some reason we want to treat empty string as null, for example
//       if (isString(raw)) {
//         return new Date(raw);
//       }
//       return null;
//     },
//   },
// };

const link = ApolloLink.from([
  // withScalars({ schema, typesMap }),
  authLink,
  errorHandlerLink,
  httpLink,
]);

export const apolloClient = new ApolloClient({
  link,
  cache: new InMemoryCache(),
  name: 'web',
  version: '0.78.0',
});
