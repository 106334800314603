import { Avatar, Box, Link, Skeleton, styled } from '@mui/material';
import { useUser } from '@user/hooks/useUser';
import { Link as RouterLink } from 'react-router-dom';
import { Typography } from '@animatrix/components/Typography';

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.grey[500_12],
}));

export const UserIdentityBox: React.FC<{
  isCollapse: boolean;
}> = ({ isCollapse }) => {
  const { user, loading } = useUser();

  if (isCollapse) {
    return (
      <Avatar
        alt="My Avatar"
        src={user?.name && `https://ui-avatars.com/api/?name=${user?.name}`}
        sx={{ mx: 'auto', mb: 2 }}
      />
    );
  }
  return (
    <Link underline="none" component={RouterLink} to="#">
      <AccountStyle>
        <Avatar
          alt="My Avatar"
          src={user?.name && `https://ui-avatars.com/api/?name=${user?.name}`}
        />
        <Box sx={{ ml: 2, width: '100%' }}>
          <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
            {loading ? <Skeleton animation="wave" /> : user?.name ?? ''}
          </Typography>
          <Typography
            variant="body2"
            sx={{ color: 'text.secondary' }}
            fontWeight={600}
          >
            Gestor
          </Typography>
        </Box>
      </AccountStyle>
    </Link>
  );
};
