import { GraphQLError } from 'graphql';

export function mapGraphQLErrorMessages(error: GraphQLError): string {
  if (error.message === 'Unauthorized') {
    return 'Não autorizado';
  }

  if (error.extensions.code === 'SAME_BUYER_AND_SELLER') {
    return 'O comprador não pode ser o mesmo que o vendedor';
  }

  if (error.extensions.subCode === 'ACCOUNT_ALREADY_EXISTS') {
    return 'Número da conta já existente';
  }

  return error.message;
}
