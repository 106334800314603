import { Page } from '@animatrix/components/Page';
import { Container } from '@mui/material';
import { MyOffersDashboard } from '../../components/MyOffersDashboard';

export const MyOffersPage: React.FC = () => {
  return (
    <Page title="InvestAI | Vender Ativos">
      <Container maxWidth={'xl'}>
        <MyOffersDashboard />
      </Container>
    </Page>
  );
};
