import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import {
  Indexers,
  OfferParametersQuery,
  OfferParametersQueryVariables,
} from '../../../__generated__/types';

const OFFER_PARAMETERS_QUERY = gql`
  query OfferParameters($indexer: Indexers!) {
    offerParameters(indexer: $indexer) {
      minimumYieldRate
      minimumResellYieldRate
      yieldRateSpread
    }
  }
`;

export const useOfferParameters = (indexer: Indexers) => {
  const { data, loading, error } = useQuery<
    OfferParametersQuery,
    OfferParametersQueryVariables
  >(OFFER_PARAMETERS_QUERY, {
    variables: { indexer },
  });

  return {
    offerParameters: data?.offerParameters,
    loading,
    error,
  };
};
