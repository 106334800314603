import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { MyBrokerAccountsQuery } from '../../../../__generated__/types';

export const MY_BROKER_ACCOUNTS_QUERY = gql`
  query MyBrokerAccounts {
    me {
      user {
        brokerAccounts {
          id
          customerName
          broker
          number
          balance
        }
      }
    }
  }
`;

export const useBrokerAccounts = () => {
  const { data, loading, error } = useQuery<MyBrokerAccountsQuery>(
    MY_BROKER_ACCOUNTS_QUERY,
  );

  return {
    loading,
    error,
    brokerAccounts: data?.me?.user?.brokerAccounts ?? [],
  };
};
