import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import * as FullStory from '@fullstory/browser';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { bugsnagErrorInterceptor } from './global/interceptors/bugsnagErrorInterceptor';

FullStory.init({
  orgId: process.env.REACT_APP_FULLSTORY_ORG_ID as string,
  devMode: process.env.REACT_APP_FULLSTORY_DEV_MODE === 'true',
});

Bugsnag.start({
  apiKey: process.env.REACT_APP_BUGSNAG_API_KEY as string,
  plugins: [new BugsnagPluginReact()],
  enabledReleaseStages: ['production', 'staging'],
  onError: bugsnagErrorInterceptor,
});

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY as string);

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const ErrorBoundary = Bugsnag.getPlugin('react')!.createErrorBoundary(React);

const container = document.getElementById('root');
// eslint-disable-next-line
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </React.StrictMode>,
);
