import { Grid } from '@mui/material';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { PAGE_TITLES } from '../../../layout/components/Dashboard/SidebarConfig';
import { CreateOfferForm } from '../CreateOfferForm';
import { DEFAULT_CREATE_OFFER_FORM_VALUES } from '../CreateOfferForm/constants';
import { ICreateOfferFormValues } from '../CreateOfferForm/interfaces/ICreateOfferFormValues';
import { PurchaseIntentsList } from '../PurchaseIntentsList';
import { Typography } from '@animatrix/components/Typography';

export const CreateOfferDashboard: React.FC = () => {
  const methods = useForm<ICreateOfferFormValues>({
    mode: 'onChange',
    defaultValues: DEFAULT_CREATE_OFFER_FORM_VALUES,
  });

  return (
    <FormProvider {...methods}>
      <Typography variant="h4" mb={5}>
        {PAGE_TITLES.addOffer}
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <CreateOfferForm />
        </Grid>
        <Grid item xs={12} md={6}>
          <PurchaseIntentsList />
        </Grid>
      </Grid>
    </FormProvider>
  );
};
