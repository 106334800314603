export const getValidationFunctions = (
  maxQuantity: number,
  unitPrice: number,
  purchaseLimit: number,
) => ({
  unitPrice: (v: number) => {
    return v > 0;
  },
  quantityToSell: (v: number) => {
    if (v * unitPrice > purchaseLimit) {
      return 'Valor além do limite de compra.';
    }
    if (v <= 0) {
      return 'Mínimo 1 unidade.';
    }
    if (v > maxQuantity) {
      return `Máximo ${maxQuantity} unidades.`;
    }
    return true;
  },
});
