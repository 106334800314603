export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
};

export type APIBot = {
  __typename?: 'APIBot';
  availabilityLevel?: Maybe<Scalars['String']>;
  balance?: Maybe<Scalars['Float']>;
  banks?: Maybe<Array<Bank>>;
  brokerAccount: BrokerAccount;
  brokerAccountId?: Maybe<Scalars['Float']>;
  chargeModelId: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  currentState: Scalars['String'];
  enabled: Scalars['Boolean'];
  feeMax: Scalars['Float'];
  feeMin: Scalars['Float'];
  fgcLimitPerBankEnabled?: Maybe<Scalars['Boolean']>;
  fgcLimitPerPersonEnabled?: Maybe<Scalars['Boolean']>;
  funFacts: BotFunFacts;
  hasAcceptedTerms?: Maybe<Scalars['Boolean']>;
  hasPassword: Scalars['Boolean'];
  id: Scalars['Float'];
  /** @deprecated use investmentStrategy */
  indexer: Scalars['String'];
  insufficientLimitThreshold: Scalars['Float'];
  interestingBanks?: Maybe<Array<Scalars['String']>>;
  interestingProducts?: Maybe<Array<Scalars['String']>>;
  investmentStrategy: InvestmentStrategy;
  isAbleToPurchaseOnSecondaryMarket: Scalars['Boolean'];
  isAuthenticated: Scalars['Boolean'];
  isFeeAboveLimit: Scalars['Boolean'];
  isLowLimit: Scalars['Boolean'];
  isNowWithinAuthenticationWindowToday: Scalars['Boolean'];
  /** @deprecated This field is not used anymore */
  kind?: Maybe<Scalars['String']>;
  lastAuthenticationDate?: Maybe<Scalars['DateTime']>;
  lowLimitThreshold: Scalars['Float'];
  maturityDateMax: Scalars['DateTime'];
  maturityDateMin: Scalars['DateTime'];
  maturityDaysMax: Scalars['Float'];
  maturityDaysMin: Scalars['Float'];
  maximumPurchasesTotalCost?: Maybe<Scalars['Float']>;
  messages: Array<BotMessage>;
  purchasePriority: Scalars['Float'];
  shouldLimitPurchasesTotalCost: Scalars['Boolean'];
  sortingIndex?: Maybe<Scalars['Float']>;
  status: PurchaseOrderStatus;
  updatedAt: Scalars['DateTime'];
  user: User;
  /** @deprecated This field is not used anymore since we now have a brokerAccountId FK */
  xpAccountNumber?: Maybe<Scalars['String']>;
};

export type AccountType = {
  __typename?: 'AccountType';
  email: Scalars['String'];
  id: Scalars['Float'];
  roles: Array<Scalars['String']>;
  showCouponCTA: Scalars['Boolean'];
  user?: Maybe<User>;
};

export type AddCreditFailedType = {
  __typename?: 'AddCreditFailedType';
  email: Scalars['String'];
  error: Scalars['String'];
};

export type AddCreditSucceedType = {
  __typename?: 'AddCreditSucceedType';
  currentCredits: Scalars['Float'];
  email: Scalars['String'];
};

export type AddCreditsToUsersResponse = {
  __typename?: 'AddCreditsToUsersResponse';
  failed: Array<AddCreditFailedType>;
  succeed: Array<AddCreditSucceedType>;
};

export type Advisor = {
  __typename?: 'Advisor';
  brokerOffice: BrokerOffice;
  brokerOfficeId?: Maybe<Scalars['Float']>;
  code: Scalars['String'];
  email: Scalars['String'];
  id: Scalars['Float'];
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['Float']>;
  typeString?: Maybe<Scalars['String']>;
  whatsapp?: Maybe<Scalars['String']>;
};

export enum AdvisorAuthenticationMethod {
  EMAIL = 'EMAIL',
  PASSWORD = 'PASSWORD'
}

export enum AdvisorContactPreference {
  FULL_CONTACT = 'FULL_CONTACT',
  NO_CONTACT = 'NO_CONTACT',
  ONLY_WELCOME = 'ONLY_WELCOME'
}

export enum AdvisorPurchaseMethod {
  EMAIL = 'EMAIL',
  HTTP = 'HTTP'
}

export type AssetCalculatorFields = {
  __typename?: 'AssetCalculatorFields';
  grossIncome: Scalars['Float'];
  iofAliquot: Scalars['Float'];
  iofTotalValue: Scalars['Float'];
  irAliquot: Scalars['Float'];
  irTotalValue: Scalars['Float'];
  netIncome: Scalars['Float'];
};

export type AssetPosition = {
  __typename?: 'AssetPosition';
  applicationDate: Scalars['DateTime'];
  bank: Bank;
  bankId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  fee: Scalars['Float'];
  futurePosition: Scalars['Float'];
  id: Scalars['ID'];
  indexer: Scalars['String'];
  maturityDate: Scalars['DateTime'];
  productKind: Scalars['String'];
  source: AssetPositionSource;
  totalApplied: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
};

export enum AssetPositionSource {
  BROKER = 'BROKER',
  PURCHASE = 'PURCHASE',
  USER = 'USER'
}

export type AttachCouponToMeInput = {
  couponCode: Scalars['String'];
};

export type AttachCouponToUserInput = {
  couponCode: Scalars['String'];
  email: Scalars['String'];
};

export type AttachCouponToUserPayload = {
  __typename?: 'AttachCouponToUserPayload';
  coupon: Coupon;
};

export enum AuthStatus {
  Authenticated = 'Authenticated',
  ManualUnauthenticated = 'ManualUnauthenticated',
  NeedsToken = 'NeedsToken',
  NotAdvisored = 'NotAdvisored',
  NotAuthenticated = 'NotAuthenticated',
  PendingAdvisorTransfer = 'PendingAdvisorTransfer',
  PendingBrokerOfficeContact = 'PendingBrokerOfficeContact'
}

export type AuthenticateButton = {
  __typename?: 'AuthenticateButton';
  disabled: Scalars['Boolean'];
  highlighted: Scalars['Boolean'];
  text: Scalars['String'];
  visible: Scalars['Boolean'];
};

export enum AuthenticationMode {
  CEIFADOR = 'CEIFADOR',
  KEYMAKER = 'KEYMAKER',
  MAINTENANCE = 'MAINTENANCE',
  OPEN_TECH = 'OPEN_TECH',
  TRAINMAN = 'TRAINMAN'
}

export type Bank = {
  __typename?: 'Bank';
  cnpj: Scalars['String'];
  iconImgSrc: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type BlockUserEmailPayload = {
  __typename?: 'BlockUserEmailPayload';
  id: Scalars['String'];
  reason: Scalars['String'];
  targetId: Scalars['String'];
  targetType: Scalars['String'];
};

export type BotFunFacts = {
  __typename?: 'BotFunFacts';
  averageFee?: Maybe<Scalars['Float']>;
  averageFeeIPCA?: Maybe<Scalars['Float']>;
  averageMaturityDate?: Maybe<Scalars['DateTime']>;
  averageMaturityDateIPCA?: Maybe<Scalars['DateTime']>;
  lastPurchase?: Maybe<Purchase>;
  numberOfPurchases: Scalars['Float'];
  totalInvestments: Scalars['Float'];
};

export type BotMessage = {
  __typename?: 'BotMessage';
  level: BotMessageLevel;
  message: Scalars['String'];
};

export enum BotMessageLevel {
  ERROR = 'ERROR',
  INFO = 'INFO',
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING'
}

export type BrokerAccount = {
  __typename?: 'BrokerAccount';
  advisor?: Maybe<Advisor>;
  /** @deprecated Use BrokerAccount.warnings.allPurchaseOrdersWithLowLimit */
  allPurchaseOrdersWithLowLimit: Scalars['Boolean'];
  /** @deprecated use purchaseOrders */
  apiBots: Array<APIBot>;
  authStatus: AuthStatus;
  authenticatedAt: Scalars['DateTime'];
  availableLimit: Scalars['Float'];
  balance?: Maybe<Scalars['Float']>;
  balancePrediction: Scalars['Float'];
  /** @deprecated use purchaseOrders */
  bots: Array<APIBot>;
  broker: Scalars['String'];
  cardDisplay: BrokerAccountCardDisplay;
  /** @deprecated Coupon is no longer attached to brokerAccount */
  coupon?: Maybe<Coupon>;
  createdAt: Scalars['DateTime'];
  customerName?: Maybe<Scalars['String']>;
  executedPurchaseOrders: Array<APIBot>;
  fgcLimitPerBankEnabledByDefault?: Maybe<Scalars['Boolean']>;
  fgcLimitPerPersonEnabledByDefault?: Maybe<Scalars['Boolean']>;
  financialInstitution: FinancialInstitution;
  hasAuthenticatedInThePast: Scalars['Boolean'];
  hasAuthenticatedInsideWindow: Scalars['Boolean'];
  id: Scalars['Float'];
  insufficientBalanceThreshold: Scalars['Float'];
  interestingBanks?: Maybe<Array<Scalars['String']>>;
  interestingProducts?: Maybe<Array<Scalars['String']>>;
  isAuthenticated: Scalars['Boolean'];
  isBlockedPassword: Scalars['Boolean'];
  /** @deprecated JFK promotion no longer exists */
  isEligibleForJFKPromotion: Scalars['Boolean'];
  isEnrolledToJFKPromotionOrPromotionRequest: Scalars['Boolean'];
  isInsideAuthenticationTimeWindow: Scalars['Boolean'];
  /** @deprecated Use BrokerAccount.warnings.isLowBalance */
  isLowBalance: Scalars['Boolean'];
  isPendingAccount: Scalars['Boolean'];
  isWrongPIN: Scalars['Boolean'];
  lastUserAuthenticationAt?: Maybe<Scalars['DateTime']>;
  lowBalanceThreshold: Scalars['Float'];
  needsAdvisor: Scalars['Boolean'];
  needsAuthentication: Scalars['Boolean'];
  needsPin: Scalars['Boolean'];
  number?: Maybe<Scalars['String']>;
  personType: PersonType;
  pin?: Maybe<Scalars['String']>;
  positionsByBank: Array<PositionsByBank>;
  purchaseOrders: Array<APIBot>;
  purchaseOrdersInsufficientLimitThreshold: Scalars['Float'];
  purchaseOrdersLowLimitThreshold: Scalars['Float'];
  sessionId: Scalars['String'];
  shouldDisplayAuthenticationCTA: Scalars['Boolean'];
  shouldDisplayAuthorizedPurchaseOrdersMessage: Scalars['Boolean'];
  shouldHightlightAuthenticationCTA: Scalars['Boolean'];
  shouldOfferJFKPromotionOrPromotionRequest: Scalars['Boolean'];
  status: Array<BrokerAccountStatus>;
  sumGeneratedCredits: Scalars['Float'];
  user: User;
  xSyncId: Scalars['String'];
};

export type BrokerAccountCardDisplay = {
  __typename?: 'BrokerAccountCardDisplay';
  authenticateButton: AuthenticateButton;
  bannerType: BrokerAccountCardDisplayBannerType;
  displayAuthenticationReminder: Scalars['Boolean'];
  displayBalance: Scalars['Boolean'];
  errors: BrokerAccountErrors;
  text: Scalars['String'];
  warnings: BrokerAccountWarnings;
};

export enum BrokerAccountCardDisplayBannerType {
  ASSETS_SOLD_OFF = 'ASSETS_SOLD_OFF',
  AUTHORIZED = 'AUTHORIZED',
  MAINTENANCE = 'MAINTENANCE',
  NONE = 'NONE',
  PENDENCIES = 'PENDENCIES'
}

export type BrokerAccountEdge = {
  __typename?: 'BrokerAccountEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<BrokerAccount>;
};

export type BrokerAccountErrors = {
  __typename?: 'BrokerAccountErrors';
  allPurchaseOrdersPaused: Scalars['Boolean'];
  blockedPassword: Scalars['Boolean'];
  insufficientBalance: Scalars['Boolean'];
  maintenanceMode: Scalars['Boolean'];
  noPINSet: Scalars['Boolean'];
  noPurchaseOrdersCreated: Scalars['Boolean'];
  pendingAccount: Scalars['Boolean'];
  wrongPIN: Scalars['Boolean'];
};

export type BrokerAccountPageInfo = {
  __typename?: 'BrokerAccountPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type BrokerAccountProfile = {
  __typename?: 'BrokerAccountProfile';
  name?: Maybe<Scalars['String']>;
};

export enum BrokerAccountStatus {
  ALL_PURCHASE_ORDERS_LOW_LIMIT = 'ALL_PURCHASE_ORDERS_LOW_LIMIT',
  ALL_PURCHASE_ORDERS_PAUSED = 'ALL_PURCHASE_ORDERS_PAUSED',
  BLOCKED_PASSWORD = 'BLOCKED_PASSWORD',
  INVALID_SIGNATURE = 'INVALID_SIGNATURE',
  LOW_BALANCE = 'LOW_BALANCE'
}

export type BrokerAccountWarnings = {
  __typename?: 'BrokerAccountWarnings';
  allPurchaseOrdersWithLowLimit: Scalars['Boolean'];
  hasSomePurchaseOrderAboveLimit: Scalars['Boolean'];
  lowBalance: Scalars['Boolean'];
};

export type BrokerAccountsFilter = {
  searchTerm?: InputMaybe<Scalars['String']>;
};

export type BrokerAccountsResponse = {
  __typename?: 'BrokerAccountsResponse';
  count?: Maybe<Scalars['Float']>;
  edges?: Maybe<Array<BrokerAccountEdge>>;
  pageInfo?: Maybe<BrokerAccountPageInfo>;
};

export type BrokerAccountsSort = {
  lastUserAuthenticationAt?: InputMaybe<SortDirection>;
};

export type BrokerAuthenticationInput = {
  authorizationToken?: InputMaybe<Scalars['String']>;
  brokerName: BrokerName;
  otp?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  platform?: InputMaybe<Scalars['String']>;
  username?: InputMaybe<Scalars['String']>;
  virtualKeyboardSolution?: InputMaybe<Array<Scalars['String']>>;
};

export type BrokerAuthenticationPayload = {
  __typename?: 'BrokerAuthenticationPayload';
  accessToken: Scalars['String'];
  accountOK: Scalars['Boolean'];
  otpOK: Scalars['Boolean'];
  passwordOK: Scalars['Boolean'];
  profile?: Maybe<BrokerAccountProfile>;
  read: Scalars['Boolean'];
  refreshToken: Scalars['String'];
  responseTime: Scalars['Float'];
  write: Scalars['Boolean'];
};

export enum BrokerName {
  BTG = 'BTG',
  BTG_DIGITAL = 'BTG_DIGITAL',
  Easynvest = 'Easynvest',
  GENIAL = 'GENIAL',
  NU_INVEST = 'NU_INVEST',
  XP = 'XP',
  XP2 = 'XP2'
}

export type BrokerOffice = {
  __typename?: 'BrokerOffice';
  financialInstitution: FinancialInstitution;
  id: Scalars['Float'];
  name: Scalars['String'];
};

export type CeifadorStatusPayload = {
  __typename?: 'CeifadorStatusPayload';
  appVersion: Scalars['String'];
  lastHeartbeatDate: Scalars['DateTime'];
  mobileUser: Scalars['String'];
  status: Scalars['String'];
};

export type Charge = {
  __typename?: 'Charge';
  creditsConsumed: Scalars['Float'];
  discount: Scalars['Float'];
  discountByCredits: Scalars['Float'];
  discountByPercentage: Scalars['Float'];
  generatedAt: Scalars['DateTime'];
  id: Scalars['Float'];
  purchase: Purchase;
  purchaseId: Scalars['Float'];
  status: Scalars['String'];
  total: Scalars['Float'];
  user: User;
  userId: Scalars['Float'];
  value: Scalars['Float'];
};

export type ChargeInvoice = {
  __typename?: 'ChargeInvoice';
  cancelledAt?: Maybe<Scalars['DateTime']>;
  charges: Array<Charge>;
  dueDate: Scalars['DateTime'];
  generatedAt: Scalars['DateTime'];
  id: Scalars['Float'];
  link?: Maybe<Scalars['String']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  pathname?: Maybe<Scalars['String']>;
  pix?: Maybe<Scalars['String']>;
  sentAt?: Maybe<Scalars['DateTime']>;
  status: Scalars['String'];
  total: Scalars['Float'];
  totalDiscount?: Maybe<Scalars['Float']>;
  transportStatus: Scalars['String'];
  user: User;
  value: Scalars['Float'];
};

export type ChargeModel = {
  __typename?: 'ChargeModel';
  description: Scalars['String'];
  id: Scalars['Float'];
  name: Scalars['String'];
  params: Scalars['JSON'];
};

export enum ChargeStatus {
  Cancelled = 'Cancelled',
  NotPaid = 'NotPaid',
  Paid = 'Paid'
}

export type Coupon = {
  __typename?: 'Coupon';
  /** @deprecated MGM no longer generates credits over time */
  chargeCreditsLimit: Scalars['Float'];
  code: Scalars['String'];
  couponUsage: Array<CouponUsage>;
  id: Scalars['ID'];
  kind: Scalars['String'];
  /** @deprecated Use referredUserCreditsPrize */
  newBrokerAccountPrize: Scalars['Float'];
  owner?: Maybe<User>;
  referralUserCreditsPrize: Scalars['Float'];
  referredUserChargeDiscountPercentage: Scalars['Float'];
  referredUserCreditsPrize: Scalars['Float'];
};

export enum CouponKind {
  INTERNAL = 'INTERNAL',
  MGM = 'MGM',
  PROMO = 'PROMO'
}

export type CouponUsage = {
  __typename?: 'CouponUsage';
  /** @deprecated This field is not used anymore, use the user field instead */
  brokerAccount?: Maybe<PublicBrokerAccount>;
  coupon: Coupon;
  createdAt: Scalars['DateTime'];
  id: Scalars['Float'];
  obtainedCreditsBalance: Scalars['Float'];
  user?: Maybe<PublicUser>;
};

export type CreateAssetPositionInput = {
  bankId: Scalars['ID'];
  futurePosition: Scalars['Float'];
  institutionId: Scalars['ID'];
  maturityDate: Scalars['DateTime'];
};

export type CreateBotInput = {
  availabilityLevel?: InputMaybe<Scalars['String']>;
  brokerAccountId?: InputMaybe<Scalars['Float']>;
  brokerName?: InputMaybe<Scalars['String']>;
  brokerOfficeId?: InputMaybe<Scalars['Float']>;
  couponCode?: InputMaybe<Scalars['String']>;
  customerName?: InputMaybe<Scalars['String']>;
  feeMax?: InputMaybe<Scalars['Float']>;
  feeMin: Scalars['Float'];
  fgcLimitPerBankEnabled?: InputMaybe<Scalars['Boolean']>;
  fgcLimitPerPersonEnabled?: InputMaybe<Scalars['Boolean']>;
  indexer?: InputMaybe<Scalars['String']>;
  initialBalance?: InputMaybe<Scalars['Float']>;
  interestingBanks?: InputMaybe<Array<Scalars['String']>>;
  interestingProducts?: InputMaybe<Array<ProductKind>>;
  investmentStrategy?: InputMaybe<InvestmentStrategy>;
  maturityDaysMax: Scalars['Float'];
  maturityDaysMin: Scalars['Float'];
  maximumPurchasesTotalCost?: InputMaybe<Scalars['Float']>;
  password?: InputMaybe<Scalars['String']>;
  shouldLimitPurchasesTotalCost: Scalars['Boolean'];
  xpAccountNumber?: InputMaybe<Scalars['String']>;
  xpAccountPIN?: InputMaybe<Scalars['String']>;
  xpAccountPassword?: InputMaybe<Scalars['String']>;
  xpCurrentAuthorizationToken?: InputMaybe<Scalars['String']>;
};

export type CreateBrokerAccountInput = {
  account: Scalars['String'];
  authorizationToken?: InputMaybe<Scalars['String']>;
  brokerAccountId?: InputMaybe<Scalars['Float']>;
  brokerOfficeId?: InputMaybe<Scalars['Float']>;
  couponCode?: InputMaybe<Scalars['String']>;
  customerName?: InputMaybe<Scalars['String']>;
  fgcLimitPerBankEnabledByDefault?: InputMaybe<Scalars['Boolean']>;
  fgcLimitPerPersonEnabledByDefault?: InputMaybe<Scalars['Boolean']>;
  initialBalance?: InputMaybe<Scalars['Float']>;
  institutionId: Scalars['String'];
  password?: InputMaybe<Scalars['String']>;
  pin?: InputMaybe<Scalars['String']>;
};

export type CreateCouponInput = {
  code: Scalars['String'];
  kind: CouponKind;
  referredUserChargeDiscountPercentage: Scalars['Float'];
  referredUserCreditsPrize: Scalars['Int'];
};

/** Deprecated. */
export type CreateCustomerOrderInput = {
  availabilityLevel?: InputMaybe<Scalars['String']>;
  brokerAccountId: Scalars['ID'];
  feeMin: Scalars['Float'];
  fgcLimitPerBankEnabled?: InputMaybe<Scalars['Boolean']>;
  fgcLimitPerPersonEnabled?: InputMaybe<Scalars['Boolean']>;
  indexer: InvestmentStrategy;
  interestingBanks?: InputMaybe<Array<Scalars['String']>>;
  interestingProducts?: InputMaybe<Array<ProductKind>>;
  maturityDaysMax: Scalars['Int'];
  maturityDaysMin: Scalars['Int'];
  value?: InputMaybe<Scalars['Float']>;
};

export type CreateCustomerOrderPayload = {
  __typename?: 'CreateCustomerOrderPayload';
  customerOrder: CustomerOrder;
};

export type CreateOfferInput = {
  accountNumber: Scalars['String'];
  applicationDate: Scalars['DateTime'];
  applicationDateUnitPrice: Scalars['Float'];
  cetipCode: Scalars['String'];
  indexer: Indexers;
  issuer: Scalars['String'];
  maturityDate: Scalars['DateTime'];
  productKind: ProductKind;
  quantity: Scalars['Float'];
  rebuyYieldRate: Scalars['Float'];
  resellYieldRate?: InputMaybe<Scalars['Float']>;
  yieldRate: Scalars['Float'];
};

export type CreateOfferPayload = {
  __typename?: 'CreateOfferPayload';
  offer: Offer;
};

export type CreatePurchaseIntentInput = {
  accountNumber: Scalars['String'];
  indexer: Indexers;
  interestingBanks?: InputMaybe<Array<Scalars['String']>>;
  interestingProducts: Array<ProductKind>;
  limit: Scalars['Float'];
  maturityDaysMax: Scalars['Int'];
  maturityDaysMin: Scalars['Int'];
  yieldRateMin: Scalars['Float'];
};

export type CreatePurchaseOrderInput = {
  amount: Scalars['Float'];
  availabilityLevel?: InputMaybe<Scalars['String']>;
  brokerAccountId: Scalars['ID'];
  feeMax?: InputMaybe<Scalars['Float']>;
  feeMin: Scalars['Float'];
  fgcLimitPerBankEnabled?: InputMaybe<Scalars['Boolean']>;
  fgcLimitPerPersonEnabled?: InputMaybe<Scalars['Boolean']>;
  interestingBanks?: InputMaybe<Array<Scalars['String']>>;
  interestingProducts?: InputMaybe<Array<ProductKind>>;
  investmentStrategy: InvestmentStrategy;
  maturityDaysMax: Scalars['Int'];
  maturityDaysMin: Scalars['Int'];
};

export type CustomerOrder = {
  __typename?: 'CustomerOrder';
  brokerAccountId: Scalars['ID'];
  feeMin: Scalars['Float'];
  id: Scalars['ID'];
  indexer: Scalars['String'];
  maturityDaysMax: Scalars['Int'];
  maturityDaysMin: Scalars['Int'];
  userId: Scalars['ID'];
};

export type DateRangeInput = {
  max?: InputMaybe<Scalars['DateTime']>;
  min?: InputMaybe<Scalars['DateTime']>;
};

export type DeviceToken = {
  __typename?: 'DeviceToken';
  id: Scalars['Float'];
  token: Scalars['String'];
};

export type FinancialInstitution = {
  __typename?: 'FinancialInstitution';
  advisorAuthenticationMethod?: Maybe<AdvisorAuthenticationMethod>;
  advisorIsAllowedToPurchase: Scalars['Boolean'];
  advisorPurchaseMethod?: Maybe<AdvisorPurchaseMethod>;
  androidAuthenticationMode: MobileAuthenticationMode;
  authenticationTimeLowerBoundary: Scalars['String'];
  authenticationTimeUpperBoundary: Scalars['String'];
  customerIsAllowedToPurchase: Scalars['Boolean'];
  customerNeedsPinToPurchase: Scalars['Boolean'];
  customerNeedsToAcceptTerms: Scalars['Boolean'];
  iconImgSrc: Scalars['String'];
  id: Scalars['ID'];
  insufficientLimitThreshold: Scalars['Float'];
  iosAuthenticationMode: MobileAuthenticationMode;
  isAuthenticationEnabled: Scalars['Boolean'];
  isBeta: Scalars['Boolean'];
  isInsideAuthenticationWindow: Scalars['Boolean'];
  isPublic: Scalars['Boolean'];
  isReady: Scalars['Boolean'];
  lowLimitThreshold: Scalars['Float'];
  name: Scalars['String'];
  order: Scalars['Float'];
  purchaseOrderBoundaries: Array<PurchaseOrderBoundaryType>;
  suggestedAuthenticationTimeMaxLimit: Scalars['String'];
  webAuthenticationMode: AuthenticationMode;
};


export type FinancialInstitutionpurchaseOrderBoundariesArgs = {
  investmentStrategy?: InputMaybe<InvestmentStrategy>;
  maturityDaysRange?: InputMaybe<Array<Scalars['Int']>>;
  products?: InputMaybe<Array<ProductKind>>;
};

export type FloatRange = {
  __typename?: 'FloatRange';
  max: Scalars['Float'];
  min: Scalars['Float'];
};

export type GenerateInvoicePayload = {
  __typename?: 'GenerateInvoicePayload';
  chargeInvoice: ChargeInvoice;
};

export type GetAvailablePurchaseOrdersFilters = {
  brokerOfficeId?: InputMaybe<Scalars['ID']>;
  financialInstitutionId: Scalars['ID'];
  indexer?: InputMaybe<InvestmentStrategy>;
  interestingBanks?: InputMaybe<Array<Scalars['String']>>;
  interestingProducts?: InputMaybe<Array<ProductKind>>;
  maturityDate?: InputMaybe<Scalars['DateTime']>;
};

export type GetBrokerAccountKeyboardInput = {
  authorizationToken: Scalars['String'];
  brokerName: BrokerName;
  platform?: InputMaybe<Scalars['String']>;
};

export type GetBrokerAccountKeyboardPayload = {
  __typename?: 'GetBrokerAccountKeyboardPayload';
  authorizationToken: Scalars['String'];
  virtualKeyboard: VirtualKeyboardType;
};

export type GetChargesInput = {
  generatedAt?: InputMaybe<RangeInput>;
  status?: InputMaybe<ChargeStatus>;
  userId?: InputMaybe<Scalars['Float']>;
};

export type GetInvoicesInput = {
  dueDate?: InputMaybe<DateRangeInput>;
  generatedAt?: InputMaybe<DateRangeInput>;
  status?: InputMaybe<ChargeStatus>;
  transportStatus?: InputMaybe<InvoiceTransportStatus>;
  userId?: InputMaybe<Scalars['Float']>;
};

export type GetPurchasesFilter = {
  date?: InputMaybe<RangeInput>;
  status?: InputMaybe<Array<PurchaseStatus>>;
  userId?: InputMaybe<Scalars['Float']>;
};

export type GetPurchasesPayload = {
  __typename?: 'GetPurchasesPayload';
  purchases: Array<Purchase>;
};

export type GetUnitPriceInput = {
  indexer: Indexers;
  maturityDate: Scalars['DateTime'];
  newIndexerYieldRate: Scalars['Float'];
  newReferenceDate: Scalars['DateTime'];
  newYieldRate: Scalars['Float'];
  referenceDate: Scalars['DateTime'];
  referenceIndexerYieldRate: Scalars['Float'];
  referenceUnitPrice: Scalars['Float'];
  referenceYieldRate: Scalars['Float'];
};

export enum Indexers {
  CDI = 'CDI',
  IGPM = 'IGPM',
  IPCA = 'IPCA',
  PREFIXADO = 'PREFIXADO',
  SELIC = 'SELIC',
  USD = 'USD'
}

export enum InvestmentStrategy {
  Alternatives = 'Alternatives',
  CheckingAccountXP = 'CheckingAccountXP',
  Inflacao = 'Inflacao',
  International = 'International',
  MultiMarket = 'MultiMarket',
  PosFixado = 'PosFixado',
  PreFixado = 'PreFixado',
  PreFixadoCDI = 'PreFixadoCDI',
  VariableIncome = 'VariableIncome'
}

export enum InvoiceTransportStatus {
  NotSent = 'NotSent',
  Sent = 'Sent'
}

export type LegacyGenerateInvoicePayload = {
  __typename?: 'LegacyGenerateInvoicePayload';
  sentInvoice: ChargeInvoice;
};

export type MarkChargeAsPaidPayload = {
  __typename?: 'MarkChargeAsPaidPayload';
  charges: Array<Charge>;
};

export enum MobileAuthenticationMode {
  NATIVE = 'NATIVE',
  REMOTE = 'REMOTE'
}

export type Mutation = {
  __typename?: 'Mutation';
  addCreditsToUsers: AddCreditsToUsersResponse;
  addSessionId: Scalars['Boolean'];
  attachCouponToMe: AttachCouponToUserPayload;
  attachCouponToUser: AttachCouponToUserPayload;
  authenticateBrokerAccount: BrokerAuthenticationPayload;
  blockUserEmail: BlockUserEmailPayload;
  cancelInvoice: ChargeInvoice;
  cancelPurchase: Purchase;
  changePIN: BrokerAccount;
  cleanCeifadorStatusCache: Scalars['Boolean'];
  createAssetPosition: AssetPosition;
  /** @deprecated use createPurchaseOrder */
  createBot: APIBot;
  createBrokerAccount: BrokerAccount;
  createCoupon: Coupon;
  /** @deprecated Use createOnePurchaseOrder instead. */
  createCustomerOrder: CreateCustomerOrderPayload;
  createOffer: CreateOfferPayload;
  createOnePurchaseOrder: APIBot;
  createPurchaseIntent: PurchaseIntent;
  /** @deprecated use createOnePurchaseOrder */
  createPurchaseOrder: APIBot;
  decreasePriority: APIBot;
  deletePurchaseIntent: Scalars['Int'];
  /** @deprecated use disablePurchaseOrder */
  disableBot: APIBot;
  disablePurchaseOrder: APIBot;
  emitNFForInvoice: NFSe;
  /** @deprecated use enablePurchaseOrder */
  enableBot: APIBot;
  enablePurchaseOrder: APIBot;
  /** @deprecated Promotions module no longer exists */
  enrollBrokerAccountToPromotion?: Maybe<PromotionEnrollPayload>;
  fixPendingAccount: BrokerAccount;
  generateBrokerAccountKeyboard: GetBrokerAccountKeyboardPayload;
  /** @deprecated use generateWeeklyInvoice */
  generateInvoice: LegacyGenerateInvoicePayload;
  generateMonthlyInvoice: GenerateInvoicePayload;
  generateWeeklyInvoice: GenerateInvoicePayload;
  increasePriority: APIBot;
  markAllAsSeen: Scalars['Int'];
  markChargeAsCancelled: MarkChargeAsPaidPayload;
  markInvoiceAsPaid: ChargeInvoice;
  markOrdersAsError: Array<Order>;
  markSessionIdAsInvalid: Scalars['Boolean'];
  notifyAuthenticationError: Scalars['Boolean'];
  notifyAuthenticationStarted: Scalars['Boolean'];
  pauseCeifador: Scalars['Boolean'];
  /** @deprecated Clients should not be able to directly place orders. */
  placeOrder: Order;
  registerFirebaseToken: DeviceToken;
  removeAssetPositionById: AssetPosition;
  removeBrokerAccountById: BrokerAccount;
  removeOffer: Order;
  resetPassword: Scalars['Boolean'];
  sellToPurchaseIntent: Order;
  sendInvoice: ChargeInvoice;
  sendResetPasswordEmail: Scalars['Boolean'];
  setAccountNumberToBrokerAccount: BrokerAccount;
  setBrokerAccountAuthorizationToken: BrokerAccount;
  setCustomerPriorities: Array<APIBot>;
  setMobileAuthenticationMode: FinancialInstitution;
  setUserRoles: User;
  setWebAuthenticationMode: FinancialInstitution;
  settleOrders: Array<Order>;
  settlePendingPurchases: Scalars['Int'];
  settlePurchase: Purchase;
  signInEmail: SignInEmailPayload;
  signUpEmail: SignUpEmailPayload;
  /** @deprecated JFK promotion no longer exists */
  snoozeJFKPromotion: User;
  /** @deprecated use softDeletePurchaseOrder */
  softDeleteAPIBot: APIBot;
  softDeletePurchaseOrder: APIBot;
  testPurchase: TestPurchaseResult;
  transformPurchaseOrderIntoWarrantyPurchaseOrder: APIBot;
  unauthenticate: BrokerAccount;
  unregisterFirebaseToken: DeviceToken;
  updateAdvisorContactPreference: User;
  updateAssetPositionById: AssetPosition;
  updateBrokerAccount: BrokerAccount;
  updateBrokerAccountBalance: BrokerAccount;
  updateBrokerAccountOTP: BrokerAccount;
  /** @deprecated use updatePurchaseOrder */
  updateFilters: APIBot;
  updateOffer: Order;
  updatePurchaseOrder: APIBot;
  updatePurchaseOrderBoundary: PurchaseOrderBoundaryType;
  updateUserChargeModel: UpdateUserChargeModelPayload;
  updateUserPreferences: User;
};


export type MutationaddCreditsToUsersArgs = {
  amount: Scalars['Float'];
  description?: InputMaybe<Scalars['String']>;
  emails: Array<Scalars['String']>;
};


export type MutationaddSessionIdArgs = {
  value: Scalars['String'];
};


export type MutationattachCouponToMeArgs = {
  input: AttachCouponToMeInput;
};


export type MutationattachCouponToUserArgs = {
  input: AttachCouponToUserInput;
};


export type MutationauthenticateBrokerAccountArgs = {
  input: BrokerAuthenticationInput;
};


export type MutationblockUserEmailArgs = {
  email: Scalars['String'];
  reason: Scalars['String'];
};


export type MutationcancelInvoiceArgs = {
  invoiceId: Scalars['ID'];
};


export type MutationcancelPurchaseArgs = {
  purchaseId: Scalars['ID'];
};


export type MutationchangePINArgs = {
  brokerAccountId: Scalars['ID'];
  pin: Scalars['String'];
  token?: InputMaybe<Scalars['String']>;
};


export type MutationcreateAssetPositionArgs = {
  input: CreateAssetPositionInput;
};


export type MutationcreateBotArgs = {
  input: CreateBotInput;
};


export type MutationcreateBrokerAccountArgs = {
  input: CreateBrokerAccountInput;
};


export type MutationcreateCouponArgs = {
  input: CreateCouponInput;
};


export type MutationcreateCustomerOrderArgs = {
  input: CreateCustomerOrderInput;
};


export type MutationcreateOfferArgs = {
  input: CreateOfferInput;
};


export type MutationcreateOnePurchaseOrderArgs = {
  input: CreatePurchaseOrderInput;
};


export type MutationcreatePurchaseIntentArgs = {
  input: CreatePurchaseIntentInput;
};


export type MutationcreatePurchaseOrderArgs = {
  input: CreateBotInput;
};


export type MutationdecreasePriorityArgs = {
  botId: Scalars['ID'];
};


export type MutationdeletePurchaseIntentArgs = {
  id: Scalars['Int'];
};


export type MutationdisableBotArgs = {
  id: Scalars['ID'];
};


export type MutationdisablePurchaseOrderArgs = {
  id: Scalars['ID'];
};


export type MutationemitNFForInvoiceArgs = {
  emitForUserId?: InputMaybe<Scalars['Float']>;
  invoiceId: Scalars['Float'];
};


export type MutationenableBotArgs = {
  id: Scalars['ID'];
};


export type MutationenablePurchaseOrderArgs = {
  id: Scalars['ID'];
};


export type MutationenrollBrokerAccountToPromotionArgs = {
  input: PromotionEnrollInput;
};


export type MutationfixPendingAccountArgs = {
  brokerAccountId: Scalars['Float'];
};


export type MutationgenerateBrokerAccountKeyboardArgs = {
  input: GetBrokerAccountKeyboardInput;
};


export type MutationgenerateInvoiceArgs = {
  userId: Scalars['ID'];
};


export type MutationgenerateMonthlyInvoiceArgs = {
  userId: Scalars['ID'];
  yearMonth: Scalars['String'];
};


export type MutationgenerateWeeklyInvoiceArgs = {
  userId: Scalars['ID'];
};


export type MutationincreasePriorityArgs = {
  botId: Scalars['ID'];
};


export type MutationmarkAllAsSeenArgs = {
  kind: UserEventKind;
};


export type MutationmarkChargeAsCancelledArgs = {
  chargeIds: Array<Scalars['ID']>;
};


export type MutationmarkInvoiceAsPaidArgs = {
  companyId: Scalars['Int'];
  invoiceId: Scalars['ID'];
};


export type MutationmarkOrdersAsErrorArgs = {
  input: UpdateOrdersInput;
};


export type MutationmarkSessionIdAsInvalidArgs = {
  sessionIdValue: Scalars['String'];
};


export type MutationnotifyAuthenticationErrorArgs = {
  brokerAccountNumber: Scalars['String'];
  brokerName: BrokerName;
  errorMessage: Scalars['String'];
  platform: Platform;
};


export type MutationnotifyAuthenticationStartedArgs = {
  brokerAccountNumber: Scalars['String'];
  brokerName: BrokerName;
  platform: Platform;
};


export type MutationpauseCeifadorArgs = {
  mobileUser: Scalars['String'];
};


export type MutationplaceOrderArgs = {
  input: PlaceOrderInput;
};


export type MutationregisterFirebaseTokenArgs = {
  firebaseToken: Scalars['String'];
};


export type MutationremoveAssetPositionByIdArgs = {
  assetPositionId: Scalars['ID'];
};


export type MutationremoveBrokerAccountByIdArgs = {
  brokerAccountId: Scalars['ID'];
};


export type MutationremoveOfferArgs = {
  offerId: Scalars['String'];
  offerVersion: Scalars['Int'];
};


export type MutationresetPasswordArgs = {
  newPassword: Scalars['String'];
  token: Scalars['String'];
};


export type MutationsellToPurchaseIntentArgs = {
  input: SellToPurchaseIntentInput;
};


export type MutationsendInvoiceArgs = {
  invoiceId: Scalars['ID'];
};


export type MutationsendResetPasswordEmailArgs = {
  email: Scalars['String'];
};


export type MutationsetAccountNumberToBrokerAccountArgs = {
  accountNumber: Scalars['String'];
  brokerAccountId: Scalars['ID'];
};


export type MutationsetBrokerAccountAuthorizationTokenArgs = {
  authorizationToken: Scalars['String'];
  balance?: InputMaybe<Scalars['Float']>;
  brokerAccountId: Scalars['Int'];
};


export type MutationsetCustomerPrioritiesArgs = {
  customerEmails: Array<Scalars['String']>;
};


export type MutationsetMobileAuthenticationModeArgs = {
  authenticationMode: MobileAuthenticationMode;
  financialInstitutionId: BrokerName;
  platform: Platform;
};


export type MutationsetUserRolesArgs = {
  email: Scalars['String'];
  roles: Array<Scalars['String']>;
};


export type MutationsetWebAuthenticationModeArgs = {
  authenticationMode: AuthenticationMode;
  financialInstitutionId: BrokerName;
};


export type MutationsettleOrdersArgs = {
  input: UpdateOrdersInput;
};


export type MutationsettlePendingPurchasesArgs = {
  brokerName: BrokerName;
  period: DateRangeInput;
};


export type MutationsettlePurchaseArgs = {
  purchaseId: Scalars['ID'];
};


export type MutationsignInEmailArgs = {
  input: SignInEmailInput;
};


export type MutationsignUpEmailArgs = {
  input: SignUpEmailInput;
};


export type MutationsnoozeJFKPromotionArgs = {
  snooze: Scalars['Boolean'];
};


export type MutationsoftDeleteAPIBotArgs = {
  botId: Scalars['ID'];
};


export type MutationsoftDeletePurchaseOrderArgs = {
  purchaseOrderId: Scalars['ID'];
};


export type MutationtestPurchaseArgs = {
  accountNumber?: InputMaybe<Scalars['String']>;
  brokerAccountId?: InputMaybe<Scalars['Int']>;
  brokerName?: InputMaybe<BrokerName>;
};


export type MutationtransformPurchaseOrderIntoWarrantyPurchaseOrderArgs = {
  input: TransformAPIBotIntoWarrantyAssetsAPIBotInput;
};


export type MutationunauthenticateArgs = {
  brokerAccountId: Scalars['Int'];
};


export type MutationunregisterFirebaseTokenArgs = {
  firebaseToken: Scalars['String'];
};


export type MutationupdateAdvisorContactPreferenceArgs = {
  advisorContactPreference: AdvisorContactPreference;
};


export type MutationupdateAssetPositionByIdArgs = {
  assetPositionId: Scalars['ID'];
  input: UpdateAssetPositionByIdInput;
};


export type MutationupdateBrokerAccountArgs = {
  brokerAccountNumber: Scalars['String'];
  financialInstitutionId: BrokerName;
  input: UpdateBrokerAccountInput;
};


export type MutationupdateBrokerAccountBalanceArgs = {
  input: UpdateBrokerAccountBalanceInput;
};


export type MutationupdateBrokerAccountOTPArgs = {
  brokerAccountId: Scalars['ID'];
  otp: Scalars['String'];
};


export type MutationupdateFiltersArgs = {
  botId: Scalars['ID'];
  input: UpdateAPIBotFiltersInput;
};


export type MutationupdateOfferArgs = {
  input: UpdateOfferInput;
};


export type MutationupdatePurchaseOrderArgs = {
  input: UpdateAPIBotFiltersInput;
  purchaseOrderId: Scalars['ID'];
};


export type MutationupdatePurchaseOrderBoundaryArgs = {
  financialInstitutionId: Scalars['ID'];
  input: UpdatePurchaseOrderBoundaryInput;
  investmentStrategy: InvestmentStrategy;
  product: ProductKind;
};


export type MutationupdateUserChargeModelArgs = {
  chargeModelId: Scalars['ID'];
  email: Scalars['String'];
};


export type MutationupdateUserPreferencesArgs = {
  input: UpdateUserPreferencesInput;
};

export type NFSe = {
  __typename?: 'NFSe';
  id: Scalars['Float'];
  numberRPS: Scalars['String'];
  partner: Scalars['String'];
  partnerRef: Scalars['String'];
  providersCnpj: Scalars['String'];
  seriesRPS: Scalars['String'];
  status: Scalars['String'];
  typeRPS: Scalars['String'];
};

export type Offer = {
  __typename?: 'Offer';
  applicationDate: Scalars['DateTime'];
  applicationDateUnitPrice: Scalars['Float'];
  availableQuantity: Scalars['Float'];
  cetipCode: Scalars['String'];
  createdAt: Scalars['DateTime'];
  createdByUserId: Scalars['Float'];
  currentPosition: Scalars['Float'];
  id: Scalars['String'];
  indexer: Indexers;
  issuer: Bank;
  maturityDate: Scalars['DateTime'];
  ownerAccountNumber: Scalars['String'];
  productKind: ProductKind;
  quantity: Scalars['Float'];
  rebuyPosition: Scalars['Float'];
  rebuyUnitPrice: Scalars['Float'];
  rebuyYieldRate: Scalars['Float'];
  resellPosition: Scalars['Float'];
  resellUnitPrice: Scalars['Float'];
  resellYieldRate: Scalars['Float'];
  suitabilityIssues: Array<SuitabilityIssues>;
  version: Scalars['Float'];
  yieldRate: Scalars['Float'];
};

export type OfferFilters = {
  brokerAccountBlacklist?: InputMaybe<Array<Scalars['String']>>;
  createdByUserId?: InputMaybe<Scalars['Float']>;
  indexer?: InputMaybe<Indexers>;
  issuers?: InputMaybe<Array<Scalars['String']>>;
  maturityDateMax?: InputMaybe<Scalars['DateTime']>;
  maturityDateMin?: InputMaybe<Scalars['DateTime']>;
  productKind?: InputMaybe<Array<ProductKind>>;
};

export type OfferParameters = {
  __typename?: 'OfferParameters';
  minimumResellYieldRate: Scalars['Float'];
  minimumYieldRate: Scalars['Float'];
  yieldRateSpread: Scalars['Float'];
};

export type Order = {
  __typename?: 'Order';
  assetNickname: Scalars['String'];
  boughtByUserId: Scalars['Float'];
  buyerAccountNumber: Scalars['String'];
  date: Scalars['DateTime'];
  id: Scalars['String'];
  indexer: Scalars['String'];
  offer: Offer;
  /** @deprecated Replaced by boughtByUserId. */
  placedByUserId: Scalars['String'];
  purchaseIntent?: Maybe<PurchaseIntent>;
  quantity: Scalars['Float'];
  rebuyUnitPrice: Scalars['Float'];
  rebuyYieldRate: Scalars['Float'];
  resellUnitPrice: Scalars['Float'];
  resellYieldRate: Scalars['Float'];
  sellerAccountNumber: Scalars['String'];
  soldByUserId: Scalars['Float'];
  status: OrderStatus;
};

export enum OrderStatus {
  CANCELLED = 'CANCELLED',
  DISPATCHED = 'DISPATCHED',
  ERROR = 'ERROR',
  SCHEDULED = 'SCHEDULED',
  SETTLED = 'SETTLED'
}

export enum OrderSubject {
  BUYER = 'BUYER',
  SELLER = 'SELLER'
}

export enum PersonType {
  PF = 'PF',
  PJ = 'PJ'
}

export type PlaceOrderInput = {
  buyerAccountNumber: Scalars['String'];
  offerId: Scalars['String'];
  offerVersion: Scalars['Float'];
  quantity: Scalars['Float'];
};

export enum Platform {
  ANDROID = 'ANDROID',
  IOS = 'IOS',
  WEB = 'WEB'
}

export type PositionsByBank = {
  __typename?: 'PositionsByBank';
  bankId: Scalars['String'];
  currentPrice: Scalars['Float'];
  fgcLimit: Scalars['Float'];
  futurePosition: Scalars['Float'];
  percentageOfFGC: Scalars['Float'];
  sources: Array<PositionsByBankSource>;
  totalApplied: Scalars['Float'];
  valueUntilFGCLimit: Scalars['Float'];
};

export type PositionsByBankSource = {
  __typename?: 'PositionsByBankSource';
  currentPrice: Scalars['Float'];
  futurePosition: Scalars['Float'];
  name: AssetPositionSource;
  percentageOfFGC: Scalars['Float'];
  totalApplied: Scalars['Float'];
};

export enum ProductKind {
  CDB = 'CDB',
  CRA = 'CRA',
  CRI = 'CRI',
  DEBENTURE = 'DEBENTURE',
  FIDC = 'FIDC',
  FND = 'FND',
  LC = 'LC',
  LCA = 'LCA',
  LCI = 'LCI',
  LF = 'LF',
  LFSN = 'LFSN',
  LH = 'LH',
  LTN = 'LTN',
  NC = 'NC',
  NTNB = 'NTNB',
  NTNF = 'NTNF',
  RDB = 'RDB'
}

export type ProductSuggestion = {
  __typename?: 'ProductSuggestion';
  investmentStrategy: InvestmentStrategy;
  productKind: Array<ProductKind>;
  yieldRate: Scalars['Float'];
};

export type PromotionEnrollInput = {
  brokerAccountId: Scalars['Float'];
  promotionId: Scalars['String'];
};

export type PromotionEnrollPayload = {
  __typename?: 'PromotionEnrollPayload';
  brokerAccount: BrokerAccount;
  promotionEnrollment: PromotionEnrollment;
};

/** Deprecated */
export type PromotionEnrollment = {
  __typename?: 'PromotionEnrollment';
  createdAt: Scalars['DateTime'];
  id: Scalars['Float'];
  status: PromotionEnrollmentStatus;
  updatedAt: Scalars['DateTime'];
};

/** Deprecated */
export enum PromotionEnrollmentStatus {
  ACTIVE = 'ACTIVE',
  EXPIRED = 'EXPIRED',
  WAITING = 'WAITING'
}

export type PublicBrokerAccount = {
  __typename?: 'PublicBrokerAccount';
  createdAt: Scalars['DateTime'];
  number?: Maybe<Scalars['String']>;
  user: PublicUser;
};

export type PublicOffer = {
  __typename?: 'PublicOffer';
  availableQuantity: Scalars['Float'];
  cetipCode: Scalars['String'];
  createdAt: Scalars['DateTime'];
  createdByUserId: Scalars['Float'];
  id: Scalars['String'];
  indexer: Indexers;
  issuer: Bank;
  maturityDate: Scalars['DateTime'];
  productKind: ProductKind;
  quantity: Scalars['Float'];
  resellYieldRate: Scalars['Float'];
  suitabilityIssues?: Maybe<Array<SuitabilityIssues>>;
  unitPrice: Scalars['Float'];
  version: Scalars['Float'];
};


export type PublicOffersuitabilityIssuesArgs = {
  boundaries?: InputMaybe<SuitabilityBoundaries>;
};

export type PublicUser = {
  __typename?: 'PublicUser';
  email: Scalars['String'];
  name: Scalars['String'];
};

export type Purchase = {
  __typename?: 'Purchase';
  assetCalculator: AssetCalculatorFields;
  assetCode: Scalars['String'];
  assetName: Scalars['String'];
  bankId?: Maybe<Scalars['String']>;
  /** @deprecated use purchaseOrder */
  bot: APIBot;
  /** @deprecated use purchaseId */
  botId: Scalars['Float'];
  brokerAccountNumber?: Maybe<Scalars['String']>;
  date: Scalars['DateTime'];
  detailsPageLink?: Maybe<Scalars['String']>;
  externalId: Scalars['String'];
  fee: Scalars['Float'];
  grossIncomeAtMaturityDate: Scalars['Float'];
  id: Scalars['Float'];
  indexer: Scalars['String'];
  iofAliquot: Scalars['Float'];
  iofTotalValue: Scalars['Float'];
  irAliquot: Scalars['Float'];
  irTotalValue: Scalars['Float'];
  isNew: Scalars['Boolean'];
  maturityDate: Scalars['DateTime'];
  monthEquivalentIncome: Scalars['Float'];
  monthRate: Scalars['Float'];
  netIncome: Scalars['Float'];
  purchaseOrder: APIBot;
  quantityBought: Scalars['Float'];
  seller: Scalars['String'];
  status?: Maybe<PurchaseStatus>;
  totalCost: Scalars['Float'];
};

export type PurchaseCreatedEventType = UserEventInterface & {
  __typename?: 'PurchaseCreatedEventType';
  createdAt: Scalars['DateTime'];
  id: Scalars['Float'];
  kind: UserEventKind;
  purchase: Purchase;
  seenByUser: Scalars['Boolean'];
};

export type PurchaseIntent = {
  __typename?: 'PurchaseIntent';
  accountNumber: Scalars['String'];
  availableLimit: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  indexer: Indexers;
  interestingBanks: Array<Bank>;
  interestingProducts: Array<ProductKind>;
  limit: Scalars['Float'];
  maturityDaysMax: Scalars['Int'];
  maturityDaysMin: Scalars['Int'];
  totalPurchased: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
  userId: Scalars['Int'];
  yieldRateMin: Scalars['Float'];
};

export type PurchaseIntentsFilters = {
  brokerAccountBlacklist?: InputMaybe<Array<Scalars['String']>>;
  includeLowLimit?: InputMaybe<Scalars['Boolean']>;
  indexer?: InputMaybe<Indexers>;
  issuer?: InputMaybe<Scalars['String']>;
  maturityDate?: InputMaybe<Scalars['DateTime']>;
  product?: InputMaybe<ProductKind>;
  yieldRateMin?: InputMaybe<Scalars['Float']>;
};

export enum PurchaseOrderAvailabilityLevel {
  BROKER = 'BROKER',
  BROKER_OFFICE = 'BROKER_OFFICE',
  PRIVATE = 'PRIVATE'
}

export type PurchaseOrderBoundaryType = {
  __typename?: 'PurchaseOrderBoundaryType';
  fee: PurchaseOrderFeeBoundaries;
  investmentStrategy: InvestmentStrategy;
  isDefault: Scalars['Boolean'];
  maturityDays: PurchaseOrderMaturityDaysBoundaries;
};

export type PurchaseOrderExecutionForecastInput = {
  amount: Scalars['Float'];
  fee: Array<Scalars['Float']>;
  fixedIncomeProductKinds: Array<ProductKind>;
  investmentStrategy: InvestmentStrategy;
  maturityDays: Array<Scalars['Float']>;
  sellerId: BrokerName;
};

export type PurchaseOrderExecutionForecastPayload = {
  __typename?: 'PurchaseOrderExecutionForecastPayload';
  daysToComplete: Scalars['Float'];
};

export type PurchaseOrderFeeBoundaries = {
  __typename?: 'PurchaseOrderFeeBoundaries';
  highValueThreshold: Scalars['Float'];
  range: FloatRange;
  step: Scalars['Float'];
  suggested: Scalars['Float'];
};

export type PurchaseOrderMaturityDaysBoundaries = {
  __typename?: 'PurchaseOrderMaturityDaysBoundaries';
  maxSuggested: Scalars['Float'];
  minSuggested: Scalars['Float'];
  range: FloatRange;
};

export enum PurchaseOrderStatus {
  ENABLED = 'ENABLED',
  FEE_ABOVE_LIMIT = 'FEE_ABOVE_LIMIT',
  LOW_LIMIT = 'LOW_LIMIT',
  PAUSED = 'PAUSED'
}

export enum PurchaseStatus {
  Cancelled = 'Cancelled',
  Pending = 'Pending',
  Settled = 'Settled'
}

export type PurchasesFunFacts = {
  __typename?: 'PurchasesFunFacts';
  averageFeeIPCA?: Maybe<Scalars['Float']>;
  averageFeePosFixado?: Maybe<Scalars['Float']>;
  averageFeePrefixado?: Maybe<Scalars['Float']>;
  averageMaturityDaysIPCA?: Maybe<Scalars['Float']>;
  averageMaturityDaysPosFixado?: Maybe<Scalars['Float']>;
  averageMaturityDaysPrefixado?: Maybe<Scalars['Float']>;
  grossIncomeToBeReceived: Scalars['Float'];
  grossIncomeTotal: Scalars['Float'];
  netIncomeToBeReceived: Scalars['Float'];
  netIncomeTotal: Scalars['Float'];
  totalApplied?: Maybe<Scalars['Float']>;
  totalAppliedNotMatured: Scalars['Float'];
};

export type Query = {
  __typename?: 'Query';
  allBanks: Array<Bank>;
  allFinancialInstitutions: Array<FinancialInstitution>;
  availablePurchaseIntents: Array<PurchaseIntent>;
  /** @deprecated use purchaseOrderById */
  botById: APIBot;
  /** @deprecated Use purchaseOrders */
  bots: Array<APIBot>;
  brokerAccountById?: Maybe<BrokerAccount>;
  brokerAccounts: BrokerAccountsResponse;
  brokerAccountsByBrokerOffice: Array<BrokerAccount>;
  checkIfEmailIsUsed: Scalars['Boolean'];
  creditsBalance: Scalars['Float'];
  firstBrokerAccount?: Maybe<BrokerAccount>;
  getAllCeifadores: Array<CeifadorStatusPayload>;
  getAssetPositionById: AssetPosition;
  getAvailableOffers: Array<PublicOffer>;
  getAvailablePurchaseOrders: Array<APIBot>;
  /** @deprecated Use the authenticateBrokerAccount mutation instead. */
  getBrokerAccountAuthentication: BrokerAuthenticationPayload;
  /** @deprecated Use the generateBrokerAccountKeyboard mutation instead. */
  getBrokerAccountKeyboard: GetBrokerAccountKeyboardPayload;
  getCharges: Array<Charge>;
  getEmailForRecoverPasswordToken: Scalars['String'];
  getFinancialInstitutionById?: Maybe<FinancialInstitution>;
  getInvoiceById: ChargeInvoice;
  getInvoices: Array<ChargeInvoice>;
  getMyOffers: Array<Offer>;
  getNFSeById: NFSe;
  getPurchases: GetPurchasesPayload;
  getResellYieldRate: Scalars['Float'];
  getUnitPrice: Scalars['Float'];
  getUserById?: Maybe<User>;
  getUsers: Array<User>;
  isCouponActive?: Maybe<Coupon>;
  me: AccountType;
  mgmCoupon: Coupon;
  /** @deprecated use myPurchaseOrders */
  myBots: Array<APIBot>;
  myCharges: Array<Charge>;
  myEvents: Array<UserEventInterface>;
  myInvoices: Array<ChargeInvoice>;
  myOrders: Array<Order>;
  myPurchaseIntents: Array<PurchaseIntent>;
  myPurchaseOrders: Array<APIBot>;
  myPurchases: Array<Purchase>;
  myPurchasesFunFacts: PurchasesFunFacts;
  nextInvoicePreview: ChargeInvoice;
  offerParameters?: Maybe<OfferParameters>;
  previewMonthlyInvoice: GenerateInvoicePayload;
  previewWeeklyInvoice: ChargeInvoice;
  productSuggestion?: Maybe<ProductSuggestion>;
  purchaseIntents: Array<PurchaseIntent>;
  purchaseOrderById: APIBot;
  purchaseOrderExecutionForecast: PurchaseOrderExecutionForecastPayload;
  purchaseOrders: Array<APIBot>;
  /** @deprecated use recentePurchaseOrders */
  recentBots: Array<APIBot>;
  recentPurchaseOrders: Array<APIBot>;
  simulate: SimulationPayload;
  simulatePublic: SimulationPayload;
  systemSummary: SystemSummaryType;
  throwError: Scalars['Boolean'];
  throwRequestError: Scalars['Boolean'];
  userEventSummary: UserEventSummary;
  /** @deprecated use previewWeeklyInvoice */
  userNextInvoicePreview: ChargeInvoice;
  validateBrokerAccountNumber: ValidateBrokerAccountPayload;
  validateCoupon?: Maybe<Coupon>;
};


export type QueryavailablePurchaseIntentsArgs = {
  filters?: InputMaybe<PurchaseIntentsFilters>;
};


export type QuerybotByIdArgs = {
  botId: Scalars['ID'];
};


export type QuerybrokerAccountByIdArgs = {
  brokerAccountId: Scalars['ID'];
};


export type QuerybrokerAccountsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<BrokerAccountsFilter>;
  first?: InputMaybe<Scalars['Float']>;
  last?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<BrokerAccountsSort>;
};


export type QuerybrokerAccountsByBrokerOfficeArgs = {
  brokerOfficeId: Scalars['ID'];
};


export type QuerycheckIfEmailIsUsedArgs = {
  email: Scalars['String'];
};


export type QuerygetAssetPositionByIdArgs = {
  assetPositionId: Scalars['ID'];
};


export type QuerygetAvailableOffersArgs = {
  filters?: InputMaybe<OfferFilters>;
};


export type QuerygetAvailablePurchaseOrdersArgs = {
  filters: GetAvailablePurchaseOrdersFilters;
};


export type QuerygetBrokerAccountAuthenticationArgs = {
  input: BrokerAuthenticationInput;
};


export type QuerygetBrokerAccountKeyboardArgs = {
  input: GetBrokerAccountKeyboardInput;
};


export type QuerygetChargesArgs = {
  input: GetChargesInput;
};


export type QuerygetEmailForRecoverPasswordTokenArgs = {
  token: Scalars['String'];
};


export type QuerygetFinancialInstitutionByIdArgs = {
  id: Scalars['ID'];
};


export type QuerygetInvoiceByIdArgs = {
  invoiceId: Scalars['ID'];
};


export type QuerygetInvoicesArgs = {
  input: GetInvoicesInput;
};


export type QuerygetNFSeByIdArgs = {
  id: Scalars['Float'];
};


export type QuerygetPurchasesArgs = {
  filters: GetPurchasesFilter;
};


export type QuerygetResellYieldRateArgs = {
  indexer: Indexers;
  rebuyYieldRate: Scalars['Float'];
};


export type QuerygetUnitPriceArgs = {
  input: GetUnitPriceInput;
};


export type QuerygetUserByIdArgs = {
  id: Scalars['ID'];
};


export type QueryisCouponActiveArgs = {
  couponCode: Scalars['String'];
};


export type QuerymyBotsArgs = {
  enabled?: InputMaybe<Scalars['Boolean']>;
};


export type QuerymyChargesArgs = {
  hasDiscount: Scalars['Boolean'];
};


export type QuerymyEventsArgs = {
  seenByUser: Scalars['Boolean'];
};


export type QuerymyOrdersArgs = {
  subject: OrderSubject;
};


export type QuerymyPurchaseOrdersArgs = {
  availabilityLevel?: InputMaybe<PurchaseOrderAvailabilityLevel>;
  enabled?: InputMaybe<Scalars['Boolean']>;
};


export type QueryofferParametersArgs = {
  indexer: Indexers;
};


export type QuerypreviewMonthlyInvoiceArgs = {
  userId: Scalars['ID'];
  yearMonth: Scalars['String'];
};


export type QuerypreviewWeeklyInvoiceArgs = {
  userId: Scalars['ID'];
};


export type QueryproductSuggestionArgs = {
  brokerAccountId: Scalars['Int'];
  financialInstitutionId: Scalars['ID'];
  investmentStrategy: InvestmentStrategy;
  maturityDaysRange: Array<Scalars['Int']>;
  productKind: ProductKind;
  yieldRate: Scalars['Float'];
};


export type QuerypurchaseIntentsArgs = {
  filters?: InputMaybe<PurchaseIntentsFilters>;
};


export type QuerypurchaseOrderByIdArgs = {
  purchaseOrderId: Scalars['ID'];
};


export type QuerypurchaseOrderExecutionForecastArgs = {
  input: PurchaseOrderExecutionForecastInput;
};


export type QuerysimulateArgs = {
  input: SimulationInput;
};


export type QuerysimulatePublicArgs = {
  input: SimulationInput;
};


export type QuerysystemSummaryArgs = {
  broker?: InputMaybe<BrokerName>;
};


export type QueryuserNextInvoicePreviewArgs = {
  userId: Scalars['ID'];
};


export type QueryvalidateBrokerAccountNumberArgs = {
  accountNumber: Scalars['String'];
  broker: BrokerName;
};


export type QueryvalidateCouponArgs = {
  couponCode: Scalars['String'];
};

export type RangeInput = {
  max?: InputMaybe<Scalars['DateTime']>;
  min?: InputMaybe<Scalars['DateTime']>;
};

export type SellToPurchaseIntentInput = {
  applicationDate: Scalars['DateTime'];
  applicationDateUnitPrice: Scalars['Float'];
  cetipCode: Scalars['String'];
  indexer: Scalars['String'];
  issuer: Scalars['String'];
  maturityDate: Scalars['DateTime'];
  productKind: ProductKind;
  purchaseIntentId: Scalars['Int'];
  quantity: Scalars['Int'];
  rebuyYieldRate: Scalars['Float'];
  sellerAccountNumber: Scalars['String'];
  yieldRate: Scalars['Float'];
};

export type SignInEmailInput = {
  appleId?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  fullName?: InputMaybe<Scalars['String']>;
  method?: InputMaybe<SignInMethod>;
  password?: InputMaybe<Scalars['String']>;
  prefilledCoupon?: InputMaybe<Scalars['String']>;
  socialToken?: InputMaybe<Scalars['String']>;
};

export type SignInEmailPayload = {
  __typename?: 'SignInEmailPayload';
  account: AccountType;
  /** Indicates whether the user was created by this mutation or not. */
  newUser: Scalars['Boolean'];
  tokens: TokenPairType;
};

export enum SignInMethod {
  APPLE = 'APPLE',
  EMAIL = 'EMAIL',
  GOOGLE = 'GOOGLE'
}

export type SignUpEmailInput = {
  email: Scalars['String'];
  name: Scalars['String'];
  password: Scalars['String'];
  phone: Scalars['String'];
  prefilledCoupon?: InputMaybe<Scalars['String']>;
  webAttribution?: InputMaybe<WebAttribution>;
};

export type SignUpEmailPayload = {
  __typename?: 'SignUpEmailPayload';
  account: AccountType;
  tokens: TokenPairType;
};

export type SimulationInput = {
  applicationDate: Scalars['DateTime'];
  fee: Scalars['Float'];
  indexer: InvestmentStrategy;
  maturityDate: Scalars['DateTime'];
  totalApplied: Scalars['Float'];
};

export type SimulationPayload = {
  __typename?: 'SimulationPayload';
  businessDaysInPeriod: Scalars['Float'];
  feeDescription: Scalars['String'];
  grossIncome: Scalars['Float'];
  iofAliquot: Scalars['Float'];
  iofTotal: Scalars['Float'];
  irAliquot: Scalars['Float'];
  irTotal: Scalars['Float'];
  netIncome: Scalars['Float'];
  serviceAliquot: Scalars['Float'];
  serviceTotal: Scalars['Float'];
  totalDaysInPeriod: Scalars['Float'];
};

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC'
}

export type SuitabilityBoundaries = {
  issuers?: InputMaybe<Array<Scalars['String']>>;
  maxMaturityDate?: InputMaybe<Scalars['DateTime']>;
  minMaturityDate?: InputMaybe<Scalars['DateTime']>;
  minYieldRate?: InputMaybe<Scalars['Float']>;
  productKind?: InputMaybe<Array<ProductKind>>;
};

export enum SuitabilityIssues {
  Issuer = 'Issuer',
  MaturityDate = 'MaturityDate',
  ProductKind = 'ProductKind',
  YieldRate = 'YieldRate'
}

export type SystemSummaryType = {
  __typename?: 'SystemSummaryType';
  availableBalance: Scalars['Float'];
  numberOfPurchases: Scalars['Int'];
  pendingPurchaseChargePreview: Scalars['Float'];
  sumNotPaidCharges: Scalars['Float'];
  sumPaidAndNotPaidDiscounts: Scalars['Float'];
  sumPaidCharges: Scalars['Float'];
  sumPurchaseTotalCost: Scalars['Float'];
  totalAuthenticatedBrokerAccounts: Scalars['Int'];
  totalUsers: Scalars['Int'];
};

export enum TestPurchaseResult {
  InsufficientBalance = 'InsufficientBalance',
  InvalidBrokerAccountToken = 'InvalidBrokerAccountToken',
  InvalidDigitalSignature = 'InvalidDigitalSignature',
  PendingAccount = 'PendingAccount',
  PurchaseExecuted = 'PurchaseExecuted',
  Success = 'Success'
}

export type TokenPairType = {
  __typename?: 'TokenPairType';
  accessToken: Scalars['String'];
  refreshToken: Scalars['String'];
};

export type TransformAPIBotIntoWarrantyAssetsAPIBotInput = {
  botId: Scalars['ID'];
};

export type UpdateAPIBotFiltersInput = {
  feeMax?: InputMaybe<Scalars['Float']>;
  feeMin?: InputMaybe<Scalars['Float']>;
  indexer?: InputMaybe<Scalars['String']>;
  interestingBanks?: InputMaybe<Array<Scalars['String']>>;
  interestingProducts?: InputMaybe<Array<ProductKind>>;
  investmentStrategy?: InputMaybe<InvestmentStrategy>;
  maturityDaysMax: Scalars['Float'];
  maturityDaysMin: Scalars['Float'];
  maximumPurchasesTotalCost?: InputMaybe<Scalars['Float']>;
  shouldLimitPurchasesTotalCost: Scalars['Boolean'];
};

export type UpdateAssetPositionByIdInput = {
  bankId?: InputMaybe<Scalars['ID']>;
  futurePosition: Scalars['Float'];
  maturityDate?: InputMaybe<Scalars['DateTime']>;
};

export type UpdateBrokerAccountBalanceInput = {
  brokerAccountId: Scalars['Float'];
  newBalance: Scalars['Float'];
};

export type UpdateBrokerAccountInput = {
  advisorId?: InputMaybe<Scalars['Int']>;
  fgcLimitPerBankEnabledByDefault?: InputMaybe<Scalars['Boolean']>;
  fgcLimitPerPersonEnabledByDefault?: InputMaybe<Scalars['Boolean']>;
  number?: InputMaybe<Scalars['String']>;
};

export type UpdateOfferInput = {
  id: Scalars['String'];
  rebuyYieldRate: Scalars['Float'];
  version: Scalars['Float'];
};

export type UpdateOrdersInput = {
  orderIds: Array<Scalars['String']>;
};

export type UpdatePurchaseOrderBoundaryInput = {
  feeHighValueThreshold?: InputMaybe<Scalars['Float']>;
  feeMinLowerBoundary?: InputMaybe<Scalars['Float']>;
  feeMinSuggested?: InputMaybe<Scalars['Float']>;
  feeMinUpperBoundary?: InputMaybe<Scalars['Float']>;
};

export type UpdateUserChargeModelPayload = {
  __typename?: 'UpdateUserChargeModelPayload';
  purchaseOrders: Array<APIBot>;
  user: User;
};

export type UpdateUserPreferencesInput = {
  botAuthEmailReminder?: InputMaybe<Scalars['Boolean']>;
  botAuthPushNotificationReminder?: InputMaybe<Scalars['Boolean']>;
  botAuthWhatsAppReminder?: InputMaybe<Scalars['Boolean']>;
};

export type User = {
  __typename?: 'User';
  advisorContactPreference?: Maybe<AdvisorContactPreference>;
  appliedCoupons: Array<CouponUsage>;
  assetPositions: Array<AssetPosition>;
  averageFee?: Maybe<Scalars['Float']>;
  balance?: Maybe<Scalars['Float']>;
  botAuthEmailReminder: Scalars['Boolean'];
  botAuthPushNotificationReminder: Scalars['Boolean'];
  botAuthWhatsAppReminder: Scalars['Boolean'];
  brokerAccounts: Array<BrokerAccount>;
  brokerOffice?: Maybe<BrokerOffice>;
  chargeModel: ChargeModel;
  charges: Array<Charge>;
  email: Scalars['String'];
  /** @deprecated use hasPurchaseOrder */
  hasBot: Scalars['Boolean'];
  hasPurchaseOrder: Scalars['Boolean'];
  id: Scalars['Float'];
  invoices: Array<ChargeInvoice>;
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  positionsByBank: Array<PositionsByBank>;
  prefilledCoupon?: Maybe<Scalars['String']>;
  snoozeJFKPromotion?: Maybe<Scalars['Boolean']>;
  snoozeJFKPromotionDate?: Maybe<Scalars['DateTime']>;
  totalInvestments: Scalars['Float'];
};


export type UserassetPositionsArgs = {
  source?: InputMaybe<AssetPositionSource>;
};

export type UserEventInterface = {
  createdAt: Scalars['DateTime'];
  id: Scalars['Float'];
  kind: UserEventKind;
  seenByUser: Scalars['Boolean'];
};

export enum UserEventKind {
  ChargeCreated = 'ChargeCreated',
  InvoiceSent = 'InvoiceSent',
  MGMBrokerAccountLedgerEntryCreated = 'MGMBrokerAccountLedgerEntryCreated',
  MGMBrokerCouponUsageCreated = 'MGMBrokerCouponUsageCreated',
  PurchaseCancelled = 'PurchaseCancelled',
  PurchaseCreated = 'PurchaseCreated',
  PurchaseSettled = 'PurchaseSettled'
}

export type UserEventSummary = {
  __typename?: 'UserEventSummary';
  unseenInvoiceSentCount: Scalars['Float'];
  unseenMGMCouponUsageCount: Scalars['Float'];
  unseenMGMLedgerEntryCount: Scalars['Float'];
  unseenPurchaseCancelledCount: Scalars['Float'];
  unseenPurchaseCreatedCount: Scalars['Float'];
  unseenPurchaseSettledCount: Scalars['Float'];
};

export type ValidateBrokerAccountPayload = {
  __typename?: 'ValidateBrokerAccountPayload';
  isAvailable: Scalars['Boolean'];
};

export type VirtualKeyboardKey = {
  __typename?: 'VirtualKeyboardKey';
  id: Scalars['String'];
  options: Array<Scalars['Int']>;
};

export type VirtualKeyboardType = {
  __typename?: 'VirtualKeyboardType';
  keys: Array<VirtualKeyboardKey>;
};

export type WebAttribution = {
  campaign?: InputMaybe<Scalars['String']>;
  channel?: InputMaybe<Scalars['String']>;
  medium?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['String']>;
};

export type AvailableOffersQueryVariables = Exact<{
  filters?: InputMaybe<OfferFilters>;
  boundaries?: InputMaybe<SuitabilityBoundaries>;
}>;


export type AvailableOffersQuery = { __typename?: 'Query', getAvailableOffers: Array<{ __typename: 'PublicOffer', id: string, version: number, cetipCode: string, createdByUserId: number, createdAt: any, indexer: Indexers, maturityDate: any, productKind: ProductKind, quantity: number, resellYieldRate: number, availableQuantity: number, suitabilityIssues?: Array<SuitabilityIssues> | null, unitPrice: number, issuer: { __typename?: 'Bank', id: string, name: string } }> };

export type CreateBrokerAccountMutationVariables = Exact<{
  input: CreateBrokerAccountInput;
}>;


export type CreateBrokerAccountMutation = { __typename?: 'Mutation', createBrokerAccount: { __typename?: 'BrokerAccount', id: number, number?: string | null, customerName?: string | null, broker: string, balance?: number | null } };

export type MyBrokerAccountsQueryVariables = Exact<{ [key: string]: never; }>;


export type MyBrokerAccountsQuery = { __typename?: 'Query', me: { __typename?: 'AccountType', user?: { __typename?: 'User', brokerAccounts: Array<{ __typename?: 'BrokerAccount', id: number, customerName?: string | null, broker: string, number?: string | null, balance?: number | null }> } | null } };

export type AvailablePurchaseIntentsQueryVariables = Exact<{
  filters?: InputMaybe<PurchaseIntentsFilters>;
}>;


export type AvailablePurchaseIntentsQuery = { __typename?: 'Query', availablePurchaseIntents: Array<{ __typename?: 'PurchaseIntent', id: number, indexer: Indexers, interestingProducts: Array<ProductKind>, maturityDaysMin: number, maturityDaysMax: number, yieldRateMin: number, limit: number, accountNumber: string, totalPurchased: number, availableLimit: number, createdAt: any, updatedAt: any, interestingBanks: Array<{ __typename?: 'Bank', id: string, name: string }> }> };

export type CreateOfferMutationVariables = Exact<{
  input: CreateOfferInput;
}>;


export type CreateOfferMutation = { __typename?: 'Mutation', createOffer: { __typename?: 'CreateOfferPayload', offer: { __typename: 'Offer', id: string } } };

export type GetResellYieldRateQueryVariables = Exact<{
  rebuyYieldRate: Scalars['Float'];
  indexer: Indexers;
}>;


export type GetResellYieldRateQuery = { __typename?: 'Query', getResellYieldRate: number };

export type GetUnitPriceQueryVariables = Exact<{
  input: GetUnitPriceInput;
}>;


export type GetUnitPriceQuery = { __typename?: 'Query', getUnitPrice: number };

export type OfferParametersQueryVariables = Exact<{
  indexer: Indexers;
}>;


export type OfferParametersQuery = { __typename?: 'Query', offerParameters?: { __typename?: 'OfferParameters', minimumYieldRate: number, minimumResellYieldRate: number, yieldRateSpread: number } | null };

export type SellToPurchaseIntentMutationVariables = Exact<{
  input: SellToPurchaseIntentInput;
}>;


export type SellToPurchaseIntentMutation = { __typename?: 'Mutation', sellToPurchaseIntent: { __typename?: 'Order', id: string } };

export type GetEmailForRecoverPasswordTokenQueryVariables = Exact<{
  token: Scalars['String'];
}>;


export type GetEmailForRecoverPasswordTokenQuery = { __typename?: 'Query', getEmailForRecoverPasswordToken: string };

export type ResetPasswordMutationVariables = Exact<{
  newPassword: Scalars['String'];
  token: Scalars['String'];
}>;


export type ResetPasswordMutation = { __typename?: 'Mutation', resetPassword: boolean };

export type SendResetPasswordMutationMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type SendResetPasswordMutationMutation = { __typename?: 'Mutation', sendResetPasswordEmail: boolean };

export type IsCouponActiveQueryVariables = Exact<{
  couponCode: Scalars['String'];
}>;


export type IsCouponActiveQuery = { __typename?: 'Query', isCouponActive?: { __typename?: 'Coupon', code: string, newBrokerAccountPrize: number } | null };

export type CheckIfEmailIsUsedQueryVariables = Exact<{
  email: Scalars['String'];
}>;


export type CheckIfEmailIsUsedQuery = { __typename?: 'Query', checkIfEmailIsUsed: boolean };

export type SignInMutationVariables = Exact<{
  input: SignInEmailInput;
}>;


export type SignInMutation = { __typename?: 'Mutation', signIn: { __typename?: 'SignInEmailPayload', newUser: boolean, account: { __typename?: 'AccountType', id: number, email: string, user?: { __typename: 'User', id: number, hasBot: boolean } | null }, tokens: { __typename?: 'TokenPairType', accessToken: string, refreshToken: string } } };

export type SignUpMutationVariables = Exact<{
  input: SignUpEmailInput;
}>;


export type SignUpMutation = { __typename?: 'Mutation', signUpEmail: { __typename?: 'SignUpEmailPayload', account: { __typename?: 'AccountType', id: number, email: string }, tokens: { __typename?: 'TokenPairType', accessToken: string, refreshToken: string } } };

export type GetMyOffersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMyOffersQuery = { __typename?: 'Query', getMyOffers: Array<{ __typename: 'Offer', id: string, version: number, ownerAccountNumber: string, applicationDate: any, applicationDateUnitPrice: number, resellUnitPrice: number, rebuyUnitPrice: number, cetipCode: string, createdByUserId: number, indexer: Indexers, maturityDate: any, productKind: ProductKind, quantity: number, availableQuantity: number, rebuyYieldRate: number, resellYieldRate: number, yieldRate: number, createdAt: any, currentPosition: number, rebuyPosition: number, resellPosition: number, issuer: { __typename?: 'Bank', id: string, name: string } }> };

export type RemoveOfferMutationVariables = Exact<{
  offerId: Scalars['String'];
  offerVersion: Scalars['Int'];
}>;


export type RemoveOfferMutation = { __typename?: 'Mutation', removeOffer: { __typename?: 'Order', id: string } };

export type UpdateOfferMutationVariables = Exact<{
  input: UpdateOfferInput;
}>;


export type UpdateOfferMutation = { __typename?: 'Mutation', updateOffer: { __typename?: 'Order', id: string, rebuyYieldRate: number, resellYieldRate: number } };

export type MyOrdersQueryVariables = Exact<{
  subject: OrderSubject;
}>;


export type MyOrdersQuery = { __typename?: 'Query', myOrders: Array<{ __typename: 'Order', id: string, boughtByUserId: number, quantity: number, buyerAccountNumber: string, status: OrderStatus, resellYieldRate: number, rebuyYieldRate: number, assetNickname: string, resellUnitPrice: number, rebuyUnitPrice: number, date: any, indexer: string, purchaseIntent?: { __typename?: 'PurchaseIntent', id: number } | null, offer: { __typename?: 'Offer', id: string, maturityDate: any, issuer: { __typename?: 'Bank', name: string } } }> };

export type PlaceOrderMutationVariables = Exact<{
  input: PlaceOrderInput;
}>;


export type PlaceOrderMutation = { __typename?: 'Mutation', placeOrder: { __typename?: 'Order', id: string, boughtByUserId: number, buyerAccountNumber: string, quantity: number, offer: { __typename: 'Offer', id: string, version: number, availableQuantity: number } } };

export type AllBanksQueryVariables = Exact<{ [key: string]: never; }>;


export type AllBanksQuery = { __typename?: 'Query', allBanks: Array<{ __typename?: 'Bank', id: string, name: string }> };

export type CreatePurchaseIntentMutationVariables = Exact<{
  input: CreatePurchaseIntentInput;
}>;


export type CreatePurchaseIntentMutation = { __typename?: 'Mutation', createPurchaseIntent: { __typename?: 'PurchaseIntent', id: number, yieldRateMin: number, maturityDaysMin: number, maturityDaysMax: number } };

export type DeletePurchaseIntentMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeletePurchaseIntentMutation = { __typename?: 'Mutation', deletePurchaseIntent: number };

export type MyPurchaseIntentsQueryVariables = Exact<{ [key: string]: never; }>;


export type MyPurchaseIntentsQuery = { __typename?: 'Query', myPurchaseIntents: Array<{ __typename?: 'PurchaseIntent', id: number, yieldRateMin: number, interestingProducts: Array<ProductKind>, indexer: Indexers, maturityDaysMin: number, maturityDaysMax: number, accountNumber: string, limit: number, totalPurchased: number, createdAt: any, updatedAt: any, interestingBanks: Array<{ __typename?: 'Bank', id: string, name: string }> }> };

export type UserQueryVariables = Exact<{ [key: string]: never; }>;


export type UserQuery = { __typename?: 'Query', creditsBalance: number, me: { __typename?: 'AccountType', user?: { __typename: 'User', id: number, email: string, balance?: number | null, totalInvestments: number, averageFee?: number | null, name: string, phone?: string | null, botAuthEmailReminder: boolean, botAuthWhatsAppReminder: boolean, botAuthPushNotificationReminder: boolean } | null } };
