import { BRAZILIAN_HOLIDAYS } from '@invest-ai/common-investment';
import { parseISO } from 'date-fns';
import moment from 'moment-business-days';

moment.locale('pt-br');
moment.updateLocale('pt-br', {
  holidayFormat: 'DD/MM/YYYY',
  holidays: BRAZILIAN_HOLIDAYS,
});

export const isBusinessDay = (date: Date) => moment(date).isBusinessDay();

export const getNextBusinessDay = (date = new Date(), days = 1): Date => {
  return moment(date).businessAdd(days).toDate();
};

export const parseServerDate = (str: string) => parseISO(str);

export const differenceInBusinessDays = (
  finalDate: Date,
  initialDate: Date,
) => {
  return moment(finalDate).businessDiff(moment(initialDate));
};

export const isDateBetween = (
  date: Date | undefined,
  [min, max]: [Date, Date],
) => {
  if (!date) return false;
  return date >= min && date <= max;
};
