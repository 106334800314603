import { gql, useQuery } from '@apollo/client';
import { parseServerDate } from '@common/date/parsers';
import { PublicOffer } from '@common/types/PublicOffer';
import { isNil, reject } from 'ramda';
import {
  AvailableOffersQuery,
  AvailableOffersQueryVariables,
  Indexers,
  ProductKind,
} from '../../../../__generated__/types';

export const AVAILABLE_OFFERS = gql`
  query AvailableOffers(
    $filters: OfferFilters
    $boundaries: SuitabilityBoundaries
  ) {
    getAvailableOffers(filters: $filters) {
      __typename
      id
      version
      cetipCode
      createdByUserId
      createdAt
      indexer
      issuer {
        id
        name
      }
      maturityDate
      productKind
      quantity
      resellYieldRate
      availableQuantity
      suitabilityIssues(boundaries: $boundaries)
      unitPrice
      version
    }
  }
`;

export const useAvailableOffers = (
  filters: {
    indexer?: Indexers;
    maturityDateMin?: Date;
    maturityDateMax?: Date;
    issuers?: string[];
    productKind?: string[];
    brokerAccountBlacklist?: string[];
  } = {},
  boundaries: {
    issuers?: string[];
    productKind?: ProductKind[];
    minMaturityDate?: Date;
    maxMaturityDate?: Date;
  } = {},
) => {
  const { data, loading, error } = useQuery<
    AvailableOffersQuery,
    AvailableOffersQueryVariables
  >(AVAILABLE_OFFERS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      filters: reject(isNil, filters),
      boundaries: reject(isNil, boundaries),
    },
  });

  return {
    offers:
      data?.getAvailableOffers.map((offer: PublicOffer) => ({
        ...offer,
        createdAt: parseServerDate(offer.createdAt),
        maturityDate: parseServerDate(offer.maturityDate),
      })) ?? [],
    loading,
    error,
  };
};
