import { SelectedOfferContext } from '@book/list-offers/context/SelectedOfferContext';
import { Action, ActionsMenu } from '@common/components/ActionsMenu';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { UpdateOfferContext } from '../../context/UpdateOfferContext';
import { Offer } from '@common/types/Offer';

interface IMyOffersActionsMenu {
  offer: Offer;
}

export const MyOffersActionsMenu: React.FC<IMyOffersActionsMenu> = ({
  offer,
}) => {
  const { setSelectedOfferId, setSelectedOfferVersion } =
    SelectedOfferContext.useContainer();
  const { setOfferToUpdate } = UpdateOfferContext.useContainer();

  const actions: Action[] = [
    {
      label: 'Editar taxa',
      icon: <EditIcon />,
      onClick: () => setOfferToUpdate(offer),
    },
    {
      label: 'Apagar',
      icon: <DeleteIcon />,
      onClick: () => {
        setSelectedOfferId(offer.id);
        setSelectedOfferVersion(offer.version);
      },
    },
  ];

  return <ActionsMenu actions={actions} />;
};
