import { gql, useMutation } from '@apollo/client';
import {
  SellToPurchaseIntentInput,
  SellToPurchaseIntentMutation,
  SellToPurchaseIntentMutationVariables,
} from '../../../__generated__/types';

const SELL_TO_PURCHASE_INTENT_MUTATION = gql`
  mutation SellToPurchaseIntent($input: SellToPurchaseIntentInput!) {
    sellToPurchaseIntent(input: $input) {
      id
    }
  }
`;

export const useSellToPurchaseIntent = () => {
  const [_sellToPurchaseIntent, { loading, error }] = useMutation<
    SellToPurchaseIntentMutation,
    SellToPurchaseIntentMutationVariables
  >(SELL_TO_PURCHASE_INTENT_MUTATION);

  const sellToPurchaseIntent = (input: SellToPurchaseIntentInput) => {
    return _sellToPurchaseIntent({
      variables: {
        input,
      },
      update: (cache) => {
        cache.evict({ fieldName: 'purchaseIntents', broadcast: true });
        cache.gc();
      },
    });
  };

  return {
    sellToPurchaseIntent,
    loading,
    error,
  };
};
