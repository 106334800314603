import { AmplitudeEventTracker } from '@analytics/AmplitudeEventTracker';
import { BroadcasterEventTracker } from '@analytics/BroadcasterEventTracker';
import { EventTrackerProvider } from '@analytics/components/EventTrackerProvider';
import { SetExperimentData } from '@analytics/components/SetExperimentData';
import { TrackPixelPageView } from '@analytics/components/TrackPixelPageView';
import { DebuggerTracker } from '@analytics/DebuggerTracker';
import { FacebookPixelEventTracker } from '@analytics/FacebookPixelEventTracker';
import { ApolloProvider } from '@apollo/client';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { useEffect, useRef } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import 'simplebar/src/simplebar.css';
import { FeatureManager } from './FeatureManager';
import { apolloClient } from './global/apollo/client';
import { ThemeConfig } from './global/theme';
import GlobalStyles from './global/theme/globalStyles';
import { CollapseDrawerProvider } from './modules/layout/contexts/CollapseDrawerContext';
import { AppRouter } from './modules/routes/components/AppRouter';

function App() {
  useEffect(() => {
    // TagManager.initialize({
    //   gtmId: 'GTM-WLSKHRZ',
    // });
  }, []);

  const trackerRef = useRef(
    new BroadcasterEventTracker([
      new DebuggerTracker(
        new AmplitudeEventTracker(
          process.env.REACT_APP_AMPLITUDE_API_KEY as string,
        ),
        FeatureManager.debuggerTrackerEnabled,
      ),
      new DebuggerTracker(
        new FacebookPixelEventTracker(
          process.env.REACT_APP_FACEBOOK_PIXEL_ID as string,
        ),
        FeatureManager.debuggerTrackerEnabled,
      ),
    ]),
  );

  return (
    <HelmetProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <ApolloProvider client={apolloClient}>
          <EventTrackerProvider tracker={trackerRef.current}>
            <SetExperimentData
              experimentName={process.env.REACT_APP_EXPERIMENT_NAME as string}
            />
            <TrackPixelPageView />
            <CollapseDrawerProvider>
              <BrowserRouter>
                <ThemeConfig>
                  <GlobalStyles />
                  <AppRouter />
                  <ToastContainer hideProgressBar />
                </ThemeConfig>
              </BrowserRouter>
            </CollapseDrawerProvider>
          </EventTrackerProvider>
        </ApolloProvider>
      </LocalizationProvider>
    </HelmetProvider>
  );
}

export default App;
