import { InfoBox } from '@common/components/InfoBox';
import { Summary } from '@common/components/Summary';
import { formatDate } from '@common/date/formatters';
import { Offer } from '@common/types/Offer';
import { formatPercentage, formatReais } from '@invest-ai/common';

interface IOfferSummaryProps {
  offer: Offer;
}

export const OfferSummary: React.FC<IOfferSummaryProps> = ({ offer }) => {
  return (
    <Summary
      primaryTitle={`${offer.productKind} ${offer.issuer}`}
      secondaryTitle={`${offer.quantityProgress} Unidades`}
    >
      <InfoBox title="Indexador" value={offer.indexer} flexGrow={1} />
      <InfoBox
        title="Rentabilidade"
        value={formatPercentage(offer.yieldRate)}
        flexGrow={1}
      />
      <InfoBox
        title="PU Revenda"
        value={formatReais(offer.resellUnitPrice)}
        flexGrow={1}
      />
      <InfoBox title="Vencimento" value={formatDate(offer.maturityDate)} />
    </Summary>
  );
};
