import { useState } from 'react';
import { createContainer } from 'unstated-next';

export const SelectedPurchaseIntentContext = createContainer(() => {
  const [selectedPurchaseIntentId, setSelectedPurchaseIntentId] = useState<
    null | number
  >(null);
  return {
    selectedPurchaseIntentId,
    setSelectedPurchaseIntentId,
  };
});
