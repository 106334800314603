import { Button, ListItem, ListItemText } from '@mui/material';
import { IBookItem, ItemButtonText } from './interface';

interface IBookItemProps {
  item: IBookItem;
  onClick?: (item: IBookItem) => void;
  buttonText?: ItemButtonText;
  disabledButton?: boolean;
}

export const BookItem: React.FC<IBookItemProps> = ({
  item,
  onClick,
  buttonText,
  disabledButton,
}) => {
  const { title, subtitle, description, secondaryDescription } = item;
  const showButton = onClick && buttonText;

  return (
    <ListItem disableGutters>
      <ListItemText primary={title} secondary={subtitle} />
      <ListItemText
        primary={description}
        secondary={secondaryDescription}
        sx={{ textAlign: 'right' }}
        secondaryTypographyProps={{ color: 'error' }}
      />
      {showButton && (
        <Button
          variant="contained"
          onClick={() => onClick(item)}
          sx={{ ml: 2 }}
          disabled={disabledButton || !!secondaryDescription}
        >
          {buttonText}
        </Button>
      )}
    </ListItem>
  );
};
