import { SelectedPurchaseIntentContext } from '../../context/SelectedPurchaseIntentContext';
import { ActionsMenu, Action } from '@common/components/ActionsMenu';
import DeleteIcon from '@mui/icons-material/Delete';

interface IMyPurchaseIntentsActionsMenu {
  purchaseIntentId: number;
}

export const MyPurchaseIntentsActionsMenu: React.FC<
  IMyPurchaseIntentsActionsMenu
> = ({ purchaseIntentId }) => {
  const { setSelectedPurchaseIntentId } =
    SelectedPurchaseIntentContext.useContainer();

  const actions: Action[] = [
    {
      label: 'Apagar',
      icon: <DeleteIcon />,
      onClick: () => setSelectedPurchaseIntentId(purchaseIntentId),
    },
  ];

  return <ActionsMenu actions={actions} />;
};
