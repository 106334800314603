import { Button } from '@animatrix/components/Button';
import { Grid, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { BrokerName } from '../../../../../__generated__/types';
import { useCreateBrokerAccount } from '../../hooks/useCreateBrokerAccount';
import { ICreateBrokerAccountFormValues } from '../../interfaces/ICreateBrokerAccountFormValues';

export const CreateBrokerAccountForm: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<ICreateBrokerAccountFormValues>({
    mode: 'onChange',
  });
  const navigate = useNavigate();
  const { createBrokerAccount, loading } = useCreateBrokerAccount();

  const goBack = () => navigate(-1);

  const onSubmit = async (data: ICreateBrokerAccountFormValues) => {
    try {
      await createBrokerAccount({
        account: data.accountNumber,
        customerName: data.customerName,
        institutionId: BrokerName.GENIAL,
      });
      toast.success('Cliente cadastrado com sucesso!');
      navigate('/customers');
    } catch (err) {
      if (err instanceof Error) {
        toast.error(err.message);
      }

      throw err;
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item md={6} xs={12}>
          <TextField
            {...register('customerName', {
              required: 'O Nome do Cliente é mandatório.',
            })}
            label="Nome do Cliente"
            error={!!errors.customerName}
            helperText={errors.customerName?.message ?? ''}
          />
        </Grid>

        <Grid item md={6} xs={12}>
          <TextField
            {...register('accountNumber', {
              required: 'O Número da Conta é mandatório.',
            })}
            label="Número da Conta"
            error={!!errors.accountNumber}
            helperText={errors.accountNumber?.message ?? ''}
          />
        </Grid>

        <Grid item xs={12}>
          <Button
            variant="contained"
            type="submit"
            size="large"
            disabled={loading || !isValid}
            loading={loading}
            sx={{ mr: 4 }}
          >
            Cadastrar Cliente
          </Button>
          <Button
            size="large"
            sx={{ color: 'grey.800' }}
            disabled={loading}
            onClick={goBack}
          >
            Cancelar
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
