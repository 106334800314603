import { Grid } from '@mui/material';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { PAGE_TITLES } from '../../../../layout/components/Dashboard/SidebarConfig';
import { CreatePurchaseIntentForm } from '../CreatePurchaseIntentForm';
import { DEFAULT_CREATE_PURCHASE_INTENT_FORM_VALUES } from '../CreatePurchaseIntentForm/constants';
import { ICreatePurchaseIntentFormValues } from '../CreatePurchaseIntentForm/interfaces/ICreatePurchaseIntentFormValues';
import { OffersList } from '../OffersList';
import { Typography } from '@animatrix/components/Typography';

export const CreatePurchaseIntentDashboard: React.FC = () => {
  const methods = useForm<ICreatePurchaseIntentFormValues>({
    mode: 'onChange',
    defaultValues: DEFAULT_CREATE_PURCHASE_INTENT_FORM_VALUES,
  });

  return (
    <FormProvider {...methods}>
      <Typography variant="h4" mb={5}>
        {PAGE_TITLES.addOrder}
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={7}>
          <CreatePurchaseIntentForm />
        </Grid>
        <Grid item xs={12} md={5}>
          <OffersList />
        </Grid>
      </Grid>
    </FormProvider>
  );
};
