import { Page } from '@animatrix/components/Page';

import { Container } from '@mui/material';
import React from 'react';
import { CreatePurchaseIntentDashboard } from '../../components/CreatePurchaseIntentDashboard';

export const CreatePurchaseIntentPage: React.FC = () => {
  return (
    <Page title="InvestAI | Criar Intenção de Compra">
      <Container maxWidth={'xl'}>
        <CreatePurchaseIntentDashboard />
      </Container>
    </Page>
  );
};
