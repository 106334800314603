import { Tab } from '@mui/material';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { PAGE_TITLES } from '../../../layout/components/Dashboard/SidebarConfig';
import { AvailablePurchaseIntentsList } from '../../../purchase-intent/available-purchase-intents/AvailablePurchaseIntentsList';
import { PATH_DASHBOARD } from '../../../routes/paths';
import { MyOffersList } from '../MyOffersList';
import { Typography } from '@animatrix/components/Typography';
import { useMyOffers } from '../../hooks/useMyOffers';
import { Tabs } from '@animatrix/components/Tabs';

export const MyOffersDashboard: React.FC = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { loading } = useMyOffers();

  const handleTabChange = (_: any, value: string) =>
    navigate({
      pathname: value,
    });

  return (
    <>
      <Typography variant="h4" mb={5} loading={loading}>
        {PAGE_TITLES.sell}
      </Typography>
      <Tabs
        value={pathname}
        onChange={handleTabChange}
        loading={loading}
        sx={{ marginBottom: 2 }}
      >
        <Tab label="Minhas ofertas" value={PATH_DASHBOARD.app.myOffers} />
        <Tab
          label="Intenções de compra"
          value={PATH_DASHBOARD.app.purchaseIntents}
        />
      </Tabs>
      <Routes>
        <Route path="/my-offers" element={<MyOffersList />} />
        <Route
          path="/purchase-intents"
          element={<AvailablePurchaseIntentsList />}
        />
      </Routes>
    </>
  );
};
