import { ErrorResponse, onError } from '@apollo/client/link/error';
import { GraphQLError } from 'graphql';
import { logout, mapGraphQLErrorMessages } from './logic';

export const errorHandlerLink = onError(
  ({ graphQLErrors, response }: ErrorResponse) => {
    if (!graphQLErrors || !response?.errors) {
      return;
    }

    graphQLErrors.forEach((error: GraphQLError) => {
      const errorResponse = error.extensions?.response as any;

      if (
        errorResponse?.statusCode === 401 ||
        errorResponse?.message === 'Unauthorized' ||
        error.extensions.code === 'UNAUTHENTICATED'
      ) {
        logout();
      }
    });

    response.errors.forEach((error) => {
      error.message = mapGraphQLErrorMessages(error);
    });
  },
);
