import { Typography } from '@animatrix/components/Typography';
import { PAGE_TITLES } from '../../../../layout/components/Dashboard/SidebarConfig';
import { CreateBrokerAccountForm } from '../CreateBrokerAccountForm';

export const CreateBrokerAccountDashboard: React.FC = () => {
  return (
    <>
      <Typography variant="h4" mb={5}>
        {PAGE_TITLES.addCustomer}
      </Typography>
      <CreateBrokerAccountForm />
    </>
  );
};
