import { gql, useQuery } from '@apollo/client';
import { parseServerDate } from '@common/date/parsers';
import { Unpacked } from '@common/types/helper-types';
import {
  GetMyOffersQuery,
  GetMyOffersQueryVariables,
} from '../../../__generated__/types';

export type OfferDTO = Unpacked<GetMyOffersQuery['getMyOffers']>;

export interface ParsedOffer extends OfferDTO {
  maturityDate: Date;
  applicationDate: Date;
  createdAt: Date;
}

export const GET_MY_OFFERS_QUERY = gql`
  query GetMyOffers {
    getMyOffers {
      __typename
      id
      version
      ownerAccountNumber
      applicationDate
      applicationDateUnitPrice
      resellUnitPrice
      rebuyUnitPrice
      cetipCode
      createdByUserId
      indexer
      issuer {
        id
        name
      }
      maturityDate
      productKind
      quantity
      availableQuantity
      rebuyYieldRate
      resellYieldRate
      yieldRate
      createdAt
      currentPosition
      rebuyPosition
      resellPosition
    }
  }
`;
export const useMyOffers = () => {
  const { data, loading, error } = useQuery<
    GetMyOffersQuery,
    GetMyOffersQueryVariables
  >(GET_MY_OFFERS_QUERY);

  return {
    offers:
      data?.getMyOffers.map(
        (offer: OfferDTO): ParsedOffer => ({
          ...offer,
          maturityDate: parseServerDate(offer.maturityDate),
          applicationDate: parseServerDate(offer.applicationDate),
          createdAt: parseServerDate(offer.createdAt),
        }),
      ) ?? [],
    loading,
    error,
  };
};
