export const useLoading = (queries: boolean[], mutations: boolean[] = []) => {
  const loadingQueries = queries.some((query) => query);
  const loadingMutations = mutations.some((mutation) => mutation);

  return {
    loadingQueries,
    loadingMutations,
    loading: loadingQueries || loadingMutations,
  };
};
