const maxVisibleCharacters = 20;
const separatorChar = '|';
const minimumVisibleItems = 1;

export function getVisibleItemsCount(items: string[]) {
  if (!items.length) return 0;

  if (items.length <= 2) return items.length;

  const itemsText = items.join(separatorChar);
  let visibleItemsCount = 0;

  for (let i = 0; i <= maxVisibleCharacters; i++) {
    if (itemsText[i] === separatorChar) {
      visibleItemsCount++;
    }
  }

  return visibleItemsCount || minimumVisibleItems;
}
