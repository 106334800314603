import { Page } from '@animatrix/components/Page';
import { Container } from '@mui/material';
import { CreateBrokerAccountDashboard } from '../../components/CreateBrokerAccountDashboard';

export const CreateBrokerAccountPage: React.FC = () => {
  return (
    <Page title="InvestAI | Cadastrar Clientes">
      <Container maxWidth={'xl'}>
        <CreateBrokerAccountDashboard />
      </Container>
    </Page>
  );
};
