import { Skeleton, Tabs as MUITabs, TabsProps } from '@mui/material';

interface ITabsProps extends TabsProps {
  loading?: boolean;
}

export const Tabs: React.FC<ITabsProps> = ({
  loading,
  sx,
  children,
  ...props
}) => {
  if (loading) {
    return (
      <Skeleton variant="rectangular" sx={{ ...sx, borderRadius: 1 }}>
        <MUITabs {...props}>{children}</MUITabs>
      </Skeleton>
    );
  }
  return (
    <MUITabs sx={sx} {...props}>
      {children}
    </MUITabs>
  );
};
