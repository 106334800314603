import { Skeleton, TextFieldProps } from '@mui/material';
import MUITextField from '@mui/material/TextField';

interface ITextFieldProps {
  loading?: boolean;
}

export const TextField: React.FC<ITextFieldProps & TextFieldProps> = ({
  loading,
  sx,
  ...props
}) => {
  if (loading) {
    return (
      <Skeleton
        variant="rectangular"
        width="100%"
        sx={{ ...sx, borderRadius: 1 }}
      >
        <MUITextField />
      </Skeleton>
    );
  }
  return <MUITextField variant="outlined" fullWidth sx={sx} {...props} />;
};
