import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { UserQuery } from '../../../__generated__/types';

export const USER_QUERY = gql`
  query User {
    creditsBalance
    me {
      user {
        __typename
        id
        email
        balance
        totalInvestments
        averageFee
        email
        name
        phone
        botAuthEmailReminder
        botAuthWhatsAppReminder
        botAuthPushNotificationReminder
      }
    }
  }
`;

export const useUser = () => {
  const { loading, error, data, refetch } = useQuery<UserQuery>(USER_QUERY);

  return {
    refetch,
    user: data?.me?.user,
    creditsBalance: data?.creditsBalance,
    loading,
    error,
  };
};
