import { gql, useMutation } from '@apollo/client';
import {
  CreateBrokerAccountInput,
  CreateBrokerAccountMutation,
  CreateBrokerAccountMutationVariables,
  MyBrokerAccountsQuery,
} from '../../../../__generated__/types';
import { MY_BROKER_ACCOUNTS_QUERY } from '../../list-broker-accounts/hooks/useBrokerAccounts';

const CREATE_BROKER_ACCOUNT_MUTATION = gql`
  mutation CreateBrokerAccount($input: CreateBrokerAccountInput!) {
    createBrokerAccount(input: $input) {
      id
      number
      customerName
      broker
      balance
    }
  }
`;

export const useCreateBrokerAccount = () => {
  const [_createBrokerAccount, { data, loading, error }] = useMutation<
    CreateBrokerAccountMutation,
    CreateBrokerAccountMutationVariables
  >(CREATE_BROKER_ACCOUNT_MUTATION);
  const createBrokerAccount = async (input: CreateBrokerAccountInput) => {
    return _createBrokerAccount({
      variables: {
        input,
      },

      update: (cache, { data }) => {
        const loadedData = cache.readQuery<MyBrokerAccountsQuery>({
          query: MY_BROKER_ACCOUNTS_QUERY,
        });
        if (!loadedData) return;
        const newData = {
          me: {
            ...loadedData.me,
            user: {
              ...loadedData.me.user,
              brokerAccounts: [
                ...(loadedData.me.user?.brokerAccounts || []),
                data?.createBrokerAccount,
              ],
            },
          },
        };

        cache.writeQuery({
          query: MY_BROKER_ACCOUNTS_QUERY,
          data: newData,
        });
      },
    });
  };

  return {
    createBrokerAccount,
    brokerAccount: data?.createBrokerAccount,
    loading,
    error,
  };
};
