import { Button } from '@animatrix/components/Button';
import { Modal } from '@animatrix/components/Modal';
import { useAvailableOffers } from '@book/list-offers/hooks/useAvailableOffers';
import { formatOfferDescription } from '@common/logic/formatOfferDescription';
import { useMemo } from 'react';
import { toast } from 'react-toastify';
import { ProductKind } from '../../../../../__generated__/types';
import { PlaceOrderForm } from '../../../../place-order/components/PlaceOrderForm';
import { PlaceOrderFormContext } from '../../../../place-order/components/PlaceOrderForm/PlaceOrderContext';
import { usePlaceOrder } from '../../../../place-order/hooks/usePlaceOrder';
import { SelectedOfferContext } from '../../context/SelectedOfferContext';

export const PlaceOrderModal: React.FC = () => {
  // TODO: refactor this logic of getting the offer from an array of offers
  const { offers } = useAvailableOffers();
  const { placeOrder, loading: loadingPlaceOrder } = usePlaceOrder();

  const {
    selectedOfferId,
    setSelectedOfferId,
    selectedOfferVersion,
    setSelectedOfferVersion,
  } = SelectedOfferContext.useContainer();
  const { quantity, customerBrokerAccount } =
    PlaceOrderFormContext.useContainer();

  const selectedOffer = useMemo(() => {
    if (!selectedOfferId) {
      return null;
    }
    const offer = offers.find((offer) => offer.id === selectedOfferId);
    return offer ?? null;
  }, [selectedOfferId, offers]);

  const validQuantity =
    typeof quantity === 'number' &&
    quantity <= (selectedOffer?.quantity ?? 0) &&
    quantity > 0;

  const validInput = validQuantity && customerBrokerAccount?.number;

  const offerDescription = selectedOffer
    ? formatOfferDescription({
        indexer: selectedOffer?.indexer,
        issuer: selectedOffer?.issuer.name,
        maturityDate: selectedOffer?.maturityDate,
        productKind: selectedOffer?.productKind as ProductKind,
      })
    : '';

  const handleClose = () => {
    setSelectedOfferId(null);
    setSelectedOfferVersion(null);
  };

  const handleBuy = async () => {
    if (
      !selectedOfferId ||
      typeof selectedOfferVersion !== 'number' ||
      !customerBrokerAccount?.number
    ) {
      return;
    }

    try {
      await placeOrder({
        buyerAccountNumber: customerBrokerAccount.number,
        offerId: selectedOfferId,
        offerVersion: selectedOfferVersion,
        quantity,
      });

      setSelectedOfferId(null);
      toast.success('Ordem de compra criada com sucesso!');
    } catch (err) {
      if (err instanceof Error) {
        toast.error(err.message);
      }

      throw err;
    }
  };

  if (!selectedOfferId) {
    return null;
  }

  return (
    <Modal
      open={!!selectedOfferId}
      title={`Comprar ${offerDescription}`}
      onClose={handleClose}
      Actions={() => (
        <>
          <Button
            variant="contained"
            color="primary"
            onClick={handleBuy}
            disabled={!validInput || loadingPlaceOrder}
          >
            Comprar
          </Button>
        </>
      )}
    >
      {selectedOfferId && (
        <PlaceOrderForm
          unitPrice={selectedOffer?.unitPrice ?? 0}
          availableQuantity={selectedOffer?.availableQuantity ?? 0}
        />
      )}
    </Modal>
  );
};
