import { Page } from '@animatrix/components/Page';
import { LoginLayout } from '@login/components/LoginLayout';
import { Outlet } from 'react-router-dom';

export const LoginPage: React.FC = () => {
  return (
    <Page title="InvestAI | Entrar">
      <LoginLayout>
        <Outlet />
      </LoginLayout>
    </Page>
  );
};
