import { styled } from '@mui/material/styles';

export const Root = styled('div')(() => ({
  height: '100vh',
  display: 'flex',
  overflow: 'hidden',
}));

export const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  minWidth: 400,
  padding: '0 0 32px 80px',
  [theme.breakpoints.down('lg')]: {
    paddingLeft: 40,
    paddingRight: 40,
  },
  [theme.breakpoints.down('md')]: {
    padding: '0 20px 20px',
    minWidth: '100%',
  },
}));

export const Content = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: 320,
  [theme.breakpoints.down('md')]: {
    maxWidth: 550,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  [theme.breakpoints.down('sm')]: {
    maxWidth: '100%',
  },
}));

export const Ellipsis = styled('img')(({ theme }) => ({
  height: '100vh',
  clipPath: 'circle(190% at 207% 50%)',
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

export const LogoWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexGrow: 1,
  flexDirection: 'column',
  justifyContent: 'flex-end',
  marginTop: 10,
  alignSelf: 'flex-start',
  [theme.breakpoints.down('md')]: {
    alignItems: 'center',
    alignSelf: 'center',
  },
}));

export const Logo = styled('img')(({ theme }) => ({
  maxWidth: 120,
  [theme.breakpoints.down('sm')]: {
    maxWidth: 97,
  },
}));

export const Back = styled('div')(({ theme }) => ({
  color: theme.palette.grey[600],
  display: 'flex',
  alignItems: 'center',
  gap: 12,
  height: 54,
  marginTop: 15,
  textDecoration: 'none',
  alignSelf: 'flex-start',
  cursor: 'pointer',
}));
