import { gql, useQuery } from '@apollo/client';
import {
  OrderSubject,
  MyOrdersQuery,
  MyOrdersQueryVariables,
} from '../../../__generated__/types';

export const MY_ORDERS_QUERY = gql`
  query MyOrders($subject: OrderSubject!) {
    myOrders(subject: $subject) {
      __typename
      id
      boughtByUserId
      quantity
      buyerAccountNumber
      status
      resellYieldRate
      rebuyYieldRate
      assetNickname
      resellUnitPrice
      rebuyUnitPrice
      date
      indexer
      purchaseIntent {
        id
      }
      offer {
        id
        maturityDate
        issuer {
          name
        }
      }
    }
  }
`;

export const useMyOrders = (subject: OrderSubject) => {
  const { data, loading, error } = useQuery<
    MyOrdersQuery,
    MyOrdersQueryVariables
  >(MY_ORDERS_QUERY, {
    variables: {
      subject,
    },
  });

  return {
    orders: data?.myOrders ?? [],
    loading,
    error,
  };
};
