import { isApolloError } from '@apollo/client';
import { OnErrorCallback } from '@bugsnag/js';

export const bugsnagErrorInterceptor: OnErrorCallback = (
  event,
  notifyError,
) => {
  const shouldSend = !isApolloError(event.originalError);

  if (shouldSend) {
    notifyError(event.originalError);
  }
};
