import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import {
  ResetPasswordMutation,
  ResetPasswordMutationVariables,
} from '../../../__generated__/types';

export const RESET_PASSWORD_MUTATION = gql`
  mutation ResetPassword($newPassword: String!, $token: String!) {
    resetPassword(newPassword: $newPassword, token: $token)
  }
`;

export const useResetPassword = () => {
  const [resetPassword, { data, loading, error }] = useMutation<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
  >(RESET_PASSWORD_MUTATION);

  return {
    resetPassword,
    data,
    loading,
    error,
  };
};
