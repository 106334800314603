import reactPixel from 'react-facebook-pixel';
import { FacebookStandardEvents } from '@analytics/enum/FacebookStandardEvents';
import { FilteredEventStrategy } from '@analytics/event-filter/FilteredEventStrategy';
import { MappedEventStrategy } from '@analytics/event-mapping/MappedEventStrategy';
import { IEventTracker } from '@analytics/interfaces/IEventTracker';
import { IReactPixel } from '@analytics/interfaces/IReactPixel';

export interface IFacebookPixelEventTrackerOptions {
  filterEvents?: string[];
  eventMapper?: Record<string, string>;
}

const DEFAULT_OPTIONS: IFacebookPixelEventTrackerOptions = {
  filterEvents: undefined,
  eventMapper: undefined,
};

export class FacebookPixelEventTracker implements IEventTracker {
  private reactPixel: IReactPixel | undefined;
  private readonly filteredEvent = new FilteredEventStrategy(
    this.options.filterEvents,
  );
  private readonly mappedEvent = new MappedEventStrategy(
    this.options.eventMapper,
  );

  constructor(
    pixelId: string,
    private readonly options: IFacebookPixelEventTrackerOptions = DEFAULT_OPTIONS,
  ) {
    this.reactPixel = reactPixel;
    this.reactPixel.init(pixelId);
  }

  public setUser(id: number, email: string): void {
    // noop
  }

  public setExperiment(experimentName: string): void {
    //  noop
  }

  private isStandardEvent(eventName: FacebookStandardEvents) {
    return !!FacebookStandardEvents[eventName];
  }
  public trackEvent = (eventName: string, data: Record<string, any>): void => {
    if (this.filteredEvent.shouldTrack(eventName)) {
      const mappedEventName = this.mappedEvent.getEventName(eventName);
      if (this.isStandardEvent(mappedEventName as FacebookStandardEvents)) {
        this.reactPixel?.track(mappedEventName, data);
        return;
      }
      this.reactPixel?.trackCustom(mappedEventName, data);
    }
  };

  public pageView(pageId: string) {
    this.reactPixel?.pageView();
  }
}
