import signUpBg from '@assets/signup_bg.png';
import signInBg from '@assets/signin_bg.png';
import forgotPasswordBg from '@assets/forgot_password_bg.png';

export const getEllipsisImageByPath = (path: string) => {
  if (/login/.test(path)) {
    return signInBg;
  } else if (/recuperar-senha|forgot-password/.test(path)) {
    return forgotPasswordBg;
  } else {
    return signUpBg;
  }
};
