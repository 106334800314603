import { InfoBox } from '@common/components/InfoBox';
import { Summary } from '@common/components/Summary';
import { formatDate } from '@common/date/formatters';
import { formatIndexer } from '@common/logic/formatIndexer';
import { PurchaseIntent } from '@common/types/PurchaseIntent';
import { formatPercentage, formatReais } from '@invest-ai/common';
import { ptBR } from 'date-fns/locale';

export interface IPurchaseIntentSummaryProps {
  purchaseIntent?: PurchaseIntent;
}

export const PurchaseIntentSummary: React.FC<IPurchaseIntentSummaryProps> = ({
  purchaseIntent,
}) => {
  if (!purchaseIntent) return null;

  const maturityDateMin = formatDate(
    purchaseIntent.maturityDateMin,
    'dd/MM/yyyy',
    ptBR,
  ).toUpperCase();

  const maturityDateMax = formatDate(
    purchaseIntent.maturityDateMax,
    'dd/MM/yyyy',
    ptBR,
  ).toUpperCase();

  const interestingProducts = purchaseIntent.interestingProducts?.join('/');
  const indexer = formatIndexer(purchaseIntent.indexer);
  const yieldRateMin = formatPercentage(purchaseIntent.yieldRateMin);
  const totalCost = formatReais(purchaseIntent.availableLimit);

  return (
    <Summary
      primaryTitle={`${interestingProducts} ${indexer} ${yieldRateMin}`}
      secondaryTitle={totalCost}
    >
      <InfoBox title="Indexador" value={indexer} flexGrow={1} />
      <InfoBox title="Rentabilidade mínima" value={yieldRateMin} flexGrow={1} />
      <InfoBox
        title="Intervalo de Vencimento"
        value={`${maturityDateMin} — ${maturityDateMax}`}
      />
    </Summary>
  );
};
