import { Page } from '@animatrix/components/Page';
import { OffersDashboard } from '@book/list-offers/components/OffersDashboard';
import { Container } from '@mui/material';

export const BookPage: React.FC = () => {
  return (
    <Page title="InvestAI | Book">
      <Container maxWidth={'xl'}>
        <OffersDashboard />
      </Container>
    </Page>
  );
};
