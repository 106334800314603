import { formatReais } from '@invest-ai/common';
import { Typography, TypographyProps } from '@mui/material';

interface IAgioColumnProps {
  agio: number;
}

export const AgioColumn: React.FC<IAgioColumnProps> = ({ agio }) => {
  const color: TypographyProps['color'] = agio >= 0 ? 'primary' : 'error';

  return (
    <Typography fontSize="inherit" color={color}>
      {formatReais(agio)}
    </Typography>
  );
};
