import { ConfirmationModal } from '@animatrix/components/ConfirmationModal';
import { SelectedOfferContext } from '@book/list-offers/context/SelectedOfferContext';
import { Typography } from '@animatrix/components/Typography';
import { toast } from 'react-toastify';
import { useRemoveOffer } from '../../hooks/useRemoveOffer';

export const RemoveOfferModal: React.FC = () => {
  const {
    selectedOfferId,
    setSelectedOfferId,
    selectedOfferVersion,
    setSelectedOfferVersion,
  } = SelectedOfferContext.useContainer();
  const { removeOffer, loading, error } = useRemoveOffer();

  const handleClose = () => {
    setSelectedOfferId(null);
    setSelectedOfferVersion(null);
  };

  const handleRemove = async () => {
    if (!selectedOfferId || typeof selectedOfferVersion !== 'number') {
      return;
    }
    try {
      await removeOffer(selectedOfferId, selectedOfferVersion);
      toast.success('Oferta excluída com sucesso!');
    } catch (err) {
      if (err instanceof Error) {
        toast.error(err.message);
      }
      throw err;
    } finally {
      handleClose();
    }
  };

  return (
    <ConfirmationModal
      open={!!selectedOfferId}
      onClose={handleClose}
      onCancel={handleClose}
      onConfirm={handleRemove}
      loading={loading}
    >
      <Typography variant="body1">
        Você tem certeza que deseja excluir essa oferta?
      </Typography>
    </ConfirmationModal>
  );
};
