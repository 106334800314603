import { gql, useQuery } from '@apollo/client';
import { PurchaseIntent } from '@common/types/PurchaseIntent';
import { addDays } from 'date-fns';
import { isNil, reject } from 'ramda';
import { useMemo } from 'react';
import {
  AvailablePurchaseIntentsQuery,
  AvailablePurchaseIntentsQueryVariables,
  Indexers,
  ProductKind,
} from '../../../__generated__/types';

export const AVAILABLE_PURCHASE_INTENTS_QUERY = gql`
  query AvailablePurchaseIntents($filters: PurchaseIntentsFilters) {
    availablePurchaseIntents(filters: $filters) {
      id
      indexer
      interestingProducts
      interestingBanks {
        id
        name
      }
      maturityDaysMin
      maturityDaysMax
      yieldRateMin
      limit
      accountNumber
      totalPurchased
      availableLimit
      createdAt
      updatedAt
    }
  }
`;

export const useAvailablePurchaseIntents = (
  filters: {
    indexer?: string;
    maturityDate?: Date;
    issuer?: string;
    productKind?: string;
    brokerAccountBlacklist?: string[];
  } = {},
) => {
  const { indexer, maturityDate, issuer, productKind, brokerAccountBlacklist } =
    filters;

  const { data, loading, error } = useQuery<
    AvailablePurchaseIntentsQuery,
    AvailablePurchaseIntentsQueryVariables
  >(AVAILABLE_PURCHASE_INTENTS_QUERY, {
    variables: {
      filters: reject(isNil, {
        indexer: indexer as Indexers,
        product: productKind as ProductKind,
        issuer,
        maturityDate,
        brokerAccountBlacklist,
      }),
    },
  });

  const purchaseIntents: PurchaseIntent[] = useMemo(() => {
    return (
      data?.availablePurchaseIntents?.map((purchaseIntent) => ({
        ...purchaseIntent,
        maturityDateMin: addDays(new Date(), purchaseIntent.maturityDaysMin),
        maturityDateMax: addDays(new Date(), purchaseIntent.maturityDaysMax),
      })) ?? []
    );
  }, [data]);

  return {
    purchaseIntents,
    loading,
    error,
  };
};
