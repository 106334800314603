import React, { useEffect, useState } from 'react';
import { BookContainer } from '@common/components/BookContainer';
import { purchaseIntentToItem } from './purchaseIntentToItem';
import { useAvailablePurchaseIntents } from '@create-offer/hooks/useAvailablePurchaseIntents';
import { useFormContext } from 'react-hook-form';
import { getPurchaseIntentSuitability } from '@create-offer/logic/getPurchaseIntentSuitability';
import { ICreateOfferFormValues } from '../CreateOfferForm/interfaces/ICreateOfferFormValues';
import { PurchaseIntent } from '@common/types/PurchaseIntent';
import { SellToPurchaseIntentModal } from '../SellToPurchaseIntentModal';
import { IBookItem } from '@common/components/BookContainer/components/BookItem/interface';
import { useGetUnitPrice } from '@create-offer/hooks/useGetUnitPrice';
import { getYieldRatePropsByIndexer } from '@create-offer/logic/getYieldRatePropsByIndexer';
import { Indexers } from '../../../../__generated__/types';
import { startOfTomorrow } from 'date-fns';
import { isNil, reject } from 'ramda';

export const PurchaseIntentsList: React.FC = () => {
  const {
    watch,
    formState: { isValid },
  } = useFormContext<ICreateOfferFormValues>();
  // TODO: Add CreateOfferForm fields to enum
  const [
    brokerAccount,
    issuer,
    indexer,
    productKind,
    maturityDate,
    resellYieldRate,
    yieldRate,
    applicationDate,
    applicationDateUnitPrice,
  ] = watch([
    'brokerAccount',
    'issuer',
    'indexer',
    'productKind',
    'maturityDate',
    'resellYieldRate',
    'yieldRate',
    'applicationDate',
    'applicationDateUnitPrice',
  ]);
  const [isFiltered, setIsFiltered] = useState(false);
  const [purchaseIntentsList, setPurchaseIntentsList] = useState<IBookItem[]>(
    [],
  );
  const [selectedPurchaseIntent, setSelectedPurchaseIntent] = useState<
    PurchaseIntent | undefined
  >(undefined);

  const defaultFilters = reject(isNil, {
    indexer,
    ...(brokerAccount?.number && {
      brokerAccountBlacklist: [brokerAccount.number],
    }),
  });

  const { purchaseIntents, loading } = useAvailablePurchaseIntents(
    isFiltered
      ? {
          ...defaultFilters,
          maturityDate,
          productKind,
          issuer: issuer?.value,
        }
      : defaultFilters,
  );

  const {
    referenceIndexerYieldRate,
    referenceYieldRate,
    newIndexerYieldRate,
    newYieldRate,
  } = getYieldRatePropsByIndexer(
    indexer as Indexers,
    yieldRate,
    resellYieldRate,
  );

  const { unitPrice: resellUnitPrice } = useGetUnitPrice({
    indexer: indexer as Indexers,
    referenceDate: applicationDate,
    referenceUnitPrice: applicationDateUnitPrice,
    referenceIndexerYieldRate,
    referenceYieldRate,
    maturityDate,
    newIndexerYieldRate,
    newYieldRate,
    newReferenceDate: startOfTomorrow(),
  });

  useEffect(() => {
    const enhancedIntents = purchaseIntents.map((pi) => {
      const item = purchaseIntentToItem(pi);
      const secondaryDescription = getPurchaseIntentSuitability(pi, {
        issuer,
        productKind,
        maturityDate,
        resellYieldRate,
        resellUnitPrice,
      });

      return { ...item, secondaryDescription };
    });

    setPurchaseIntentsList(enhancedIntents);
  }, [
    purchaseIntents,
    issuer,
    indexer,
    productKind,
    maturityDate,
    resellYieldRate,
    resellUnitPrice,
  ]);

  return (
    <>
      <SellToPurchaseIntentModal
        purchaseIntent={selectedPurchaseIntent}
        onClose={() => setSelectedPurchaseIntent(undefined)}
      />
      <BookContainer
        title="Intenções de compra no mercado"
        subtitle="Preencha todos os campos de uma nova oferta para poder vender a uma intenção de compra."
        loading={loading}
        items={purchaseIntentsList}
        emptyStateMessage="Não existem intenções de compra compatíveis com a sua oferta"
        filter={{ isFiltered, setIsFiltered }}
        onItemClick={({ id }) =>
          setSelectedPurchaseIntent(
            purchaseIntents.find((pi) => pi.id === parseInt(id)),
          )
        }
        itemButtonText="Vender"
        disabledButton={!isValid}
      />
    </>
  );
};
