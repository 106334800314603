// material
import { Box, BoxProps } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ILogoProps } from './interface';

// ----------------------------------------------------------------------

export const Logo: React.FC<ILogoProps & BoxProps> = ({
  sx,
  className,
  width = 80,
}) => {
  const theme = useTheme();

  return (
    <Box sx={{ width, height: 26.45, ...sx }} className={className}>
      <img src="/static/brand/logo.png" />
    </Box>
  );
};
