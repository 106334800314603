import amplitude, { AmplitudeClient, LogReturn } from 'amplitude-js';
import { IEventTracker } from '@analytics/interfaces/IEventTracker';

export class AmplitudeEventTracker implements IEventTracker {
  private amplitude: AmplitudeClient;

  constructor(apiKey: string) {
    this.amplitude = amplitude.getInstance();
    this.amplitude.init(apiKey, undefined, {
      includeReferrer: true,
      includeUtm: true,
      includeFbclid: true,
      includeGclid: true,
    });
  }

  public setExperiment(experimentName: string): void {
    return this.amplitude.setUserProperties({
      experiment: experimentName,
    });
  }

  public trackEvent(eventName: string, data?: Record<string, any>): LogReturn {
    return this.amplitude.logEvent(eventName, data);
  }

  public setUser(id: number, email: string) {
    this.amplitude.setUserId(id.toString());
    return this.amplitude.setUserProperties({ email: email });
  }

  public pageView(pageId: string) {
    this.amplitude.logEvent(`page_${pageId}`);
  }
}
