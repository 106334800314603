export const getValidationFunctions = (maxQuantity: number) => ({
  quantityToBuy: (v: number) => {
    if (v <= 0) {
      return 'Mínimo 1 unidade.';
    }
    if (v > maxQuantity) {
      return `Máximo ${maxQuantity} unidades.`;
    }
    return true;
  },
});
