import { DisplayError } from '@animatrix/components/DisplayError';
import { TextField } from '@animatrix/components/TextField';
import { BrokerAccount } from '@common/types/BrokerAccount';
import { Autocomplete, Box } from '@mui/material';
import { CSSProperties } from 'react';
import { useBrokerAccounts } from '../../../list-broker-accounts/hooks/useBrokerAccounts';
import { Typography } from '@animatrix/components/Typography';

interface IPickBrokerAccountProps {
  value: BrokerAccount | null;
  onChange?: (value: BrokerAccount | null) => void;
  helperText?: string;
  error?: boolean;
  style?: CSSProperties;
  loading?: boolean;
}

export function PickBrokerAccount({
  onChange,
  value,
  helperText,
  error,
  style,
  loading,
}: IPickBrokerAccountProps) {
  const {
    brokerAccounts,
    loading: loadingBrokerAccounts,
    error: brokerAccountsError,
  } = useBrokerAccounts();

  const brokerAccountsOptions = brokerAccounts.filter(
    (brokerAccount) => !!brokerAccount.customerName && !!brokerAccount.number,
  );

  if (brokerAccountsError) {
    return <DisplayError error={brokerAccountsError} />;
  }

  const v =
    brokerAccountsOptions.find(
      (brokerAccount) => brokerAccount.id === value?.id,
    ) ?? null;

  return (
    <Autocomplete
      data-testid="pick-broker-account-autocomplete"
      style={style}
      value={v}
      fullWidth
      onChange={(event, newValue) => onChange?.(newValue)}
      options={brokerAccountsOptions}
      getOptionLabel={(option) =>
        `${option.number} - ${option?.customerName}` ?? 'Selecione um cliente'
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label="Cliente"
          variant="outlined"
          error={error}
          helperText={helperText}
          loading={loading || loadingBrokerAccounts}
        />
      )}
      renderOption={(props, option) => (
        <Box component="li" sx={{ '&&': { display: 'block' } }} {...props}>
          <Typography variant="subtitle2">{option.number}</Typography>
          <Typography variant="body2" sx={{ color: 'grey.600' }}>
            {option.customerName}
          </Typography>
        </Box>
      )}
    />
  );
}
