import { StyledLink } from './style';

interface ILinkProps {
  to: string;
  className?: string;
  children?: React.ReactNode;
}

export const Link: React.FC<ILinkProps> = ({ className, to, children }) => {
  return (
    <StyledLink className={className} to={to}>
      {children}
    </StyledLink>
  );
};
