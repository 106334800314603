import { DataGrid } from '@animatrix/components/DataGrid';
import { DisplayError } from '@animatrix/components/DisplayError';
import { GridColDef } from '@mui/x-data-grid-pro';
import { useMemo } from 'react';
import { useBrokerAccounts } from '../../hooks/useBrokerAccounts';

export const columns: GridColDef[] = [
  {
    field: 'broker',
    headerName: 'Corretora',
  },
  {
    field: 'number',
    headerName: 'Conta',
  },
  {
    field: 'customerName',
    headerName: 'Nome',
    flex: 1,
  },
];

export const brokerAccountToRow = (brokerAccount: any): any => ({
  ...brokerAccount,
});

export const BrokerAccountsList: React.FC = () => {
  const { brokerAccounts, error, loading } = useBrokerAccounts();

  const rows = useMemo(() => {
    return brokerAccounts?.map(brokerAccountToRow) ?? [];
  }, [brokerAccounts]);

  if (error) {
    return <DisplayError error={error} />;
  }

  return (
    <DataGrid
      rows={rows}
      columns={columns}
      disableSelectionOnClick
      autoHeight
      loading={loading}
    />
  );
};
