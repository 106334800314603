import { formatPercentage } from '@invest-ai/common';
import BigNumber from 'bignumber.js';
import { Indexers } from '../../../__generated__/types';

export const formatYieldDescription = (
  indexer: Indexers,
  yieldRate: number,
) => {
  if (indexer === Indexers.PREFIXADO) {
    return `${formatPercentage(yieldRate)} a.a`;
  } else if (indexer === Indexers.CDI) {
    return `${new BigNumber(yieldRate).multipliedBy(100)}% CDI`;
  }
  return '-';
};
