import { SignInMethod } from '../../../__generated__/types';
import { useSignIn } from './useSignIn';

export const useEmailSignIn = () => {
  const { signIn, data, loading, error } = useSignIn();

  const emailSignIn = (email: string, password: string): Promise<any> => {
    return signIn({
      method: SignInMethod.EMAIL,
      email,
      password,
    });
  };

  return {
    emailSignIn,
    data,
    loading,
    error,
  };
};
