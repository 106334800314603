import { IBookItem } from '@common/components/BookContainer/components/BookItem/interface';
import { formatDate } from '@common/date/formatters';
import { formatIndexer } from '@common/logic/formatIndexer';
import { formatYieldDescription } from '@common/logic/formatYieldDescription';
import { PublicOffer } from '@common/types/PublicOffer';
import { formatReais } from '@invest-ai/common';
import { ptBR } from 'date-fns/locale';
import { Indexers } from '../../../../__generated__/types';
import { getOfferSuitability } from './getOfferSuitability';

export const offerToItem = (offer: PublicOffer): IBookItem => {
  const amountAvailable = formatReais(
    offer.availableQuantity * offer.unitPrice,
  );

  const yieldDescription = formatYieldDescription(
    offer.indexer as Indexers,
    offer.resellYieldRate,
  );

  const indexer = formatIndexer(offer.indexer as Indexers);

  const maturityDate = formatDate(
    offer.maturityDate,
    'MMM yyyy',
    ptBR,
  ).toUpperCase();

  const secondaryDescription = getOfferSuitability(offer);

  return {
    id: offer.id,
    title: `${offer.productKind} ${offer.issuer.name} - ${maturityDate}`,
    subtitle: `${indexer} ${yieldDescription}`,
    description: amountAvailable,
    secondaryDescription,
  };
};
