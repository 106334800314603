import { Button } from '@animatrix/components/Button';
import { DisplayError } from '@animatrix/components/DisplayError';
import { PasswordInput } from '@animatrix/components/PasswordInput';
import { useGetEmailForRecoverPasswordToken } from '@forgot-password/hooks/useGetEmailForRecoverPasswordToken';
import { useResetPassword } from '@forgot-password/hooks/useResetPassword';
import { Color } from '@global/theme/Color';
import { Title } from '@login/components/Title';
import Alert from '@mui/lab/Alert';
import { CircularProgress, Link } from '@mui/material';
import { FormEventHandler, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { PATH_DASHBOARD } from '../../../routes/paths';
import { Typography } from '@animatrix/components/Typography';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export const ResetPassword: React.FC = () => {
  const navigate = useNavigate();
  const { resetPassword, data, loading, error } = useResetPassword();
  const [password, setPassword] = useState('');

  const query = useQuery();
  const token = query.get('t') ?? '';

  const valid = !!token && !!password;

  const {
    isValid,
    email,
    loading: loadingGetEmail,
  } = useGetEmailForRecoverPasswordToken(token as string);

  const performResetPassword: FormEventHandler = async (e) => {
    e.preventDefault();
    await resetPassword({
      variables: {
        newPassword: password,
        token: token as string,
      },
    });
    setPassword('');
  };

  useEffect(() => {
    if (!isValid && !loadingGetEmail) {
      navigate(PATH_DASHBOARD.app.login);
    }
  }, [isValid, loadingGetEmail]);

  return (
    <form onSubmit={performResetPassword}>
      <Title>Confirmar nova senha</Title>
      <Typography
        align="center"
        style={{ marginBottom: 20, color: Color.PRIMARY }}
      >
        {email}
      </Typography>
      <PasswordInput
        label="Nova Senha"
        onChange={setPassword}
        value={password}
        style={{ marginBottom: 16 }}
      />
      {data && (
        <Alert severity="success" style={{ marginBottom: 32 }}>
          Sua senha foi alterada. Clique{' '}
          <Link href={PATH_DASHBOARD.app.login}>aqui</Link> para fazer login
        </Alert>
      )}
      <DisplayError error={error} />
      <Button
        variant="contained"
        color="primary"
        fullWidth
        disabled={loading || !valid}
        size="large"
        type="submit"
      >
        {loading ? <CircularProgress size={28} /> : 'Confirmar'}
      </Button>
    </form>
  );
};
